import { IAdapterData } from '../../types';
import { IAlacarte, IModule, IPriceSummary } from '../PolicyListSlice/types';

export interface IModularEnrolmentSlice {
    policyId: string;
    currentStep: ModularEnrolmentSteps; // to track user state i.e page number
    selectedBasePlan: string;
    selectedAlaCarte: string[];
    family: IAdapterData<IModularFamily[]>;
    basePlans: IAdapterData<IModule[]>; // from api
    alaCarteBenefits: IAdapterData<IAlacarte[]>;
    balance?: number; // for wallet
    personalEmail?: string;
    crudFamily: IAdapterData<ICRUDResponse>;
    summary: IAdapterData<IModularEnrolmentSummary>;
}

// benefitId --> extended family (outside base plan)
export interface ISelectedAlaCarteBenefit {
    cost: number;
    extraDependants: IModularFamily[];
}

export interface IModularEnrolmentSummary {
    modular: IModule;
    alacarte: IAlacarte[];
    priceSummary: IPriceSummary;
}

export enum ModularEnrolmentSteps {
    BASE_PLAN = 0,
    DEPENDENTS = 1,
    HEALTHCARE_BENEFITS = 2,
    SUMMARY = 3
}

export interface IFetchModularPlansPayload {
    selectedPlanId?: string;
    plans: IModule[];
}

export interface IFetchBenefitsByTypeResponse {
    benefits: {
        modular: IModule[];
        alacarte: IAlacarte[];
    };
    email: string;
}

export interface IFetchModularSummaryResponse {
    modular: IModule[];
    alacarte: IAlacarte[];
    priceSummary: IPriceSummary;
}

export interface IModularFamily {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    relationship: string;
    email: string;
    mobile: string;
    employer: string;
    employeeId: string;
    gender: IGender;
}

export interface IFetchModularFamilyResponse {
    family: IModularFamily[];
}
export interface IFetchModularAlaCartePayload {
    benefits: IAlacarte[];
    selectedBenefits: string[];
    personalEmail: string;
}

export type IGender = 'male' | 'female';

export interface ISaveMemberPayload {
    firstName: string;
    lastName: string;
    dob: string;
    relationship: string;
    mobile?: string;
    employer: string;
    employeeId: string;
    gender: IGender;
}

export interface ICRUDResponse {
    message?: string;
    userId?: string;
}
