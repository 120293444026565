import styled from 'styled-components';
import theme from '../../../../../theme';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0px 100px;
    padding-bottom: 120px;
    box-sizing: border-box;
    background: linear-gradient(1deg, ${theme.colors.white} 25.77%, ${theme.colors.gradientGreen} 145.35%),
        ${theme.colors.white};
`;

export const StyledHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    padding: 36px 0px;
    border-bottom: 1px solid ${theme.colors.mutedTransparent};
`;

export const StyledScanContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: ${theme.colors.white};
`;

export const StyledScanDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const StyledQR = styled.img`
    width: 90px;
    height: 90px;
`;

export const StyledIconContainer = styled.div`
    display: flex;
    gap: 8px;
`;

export const StyledCardContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    background-color: ${theme.colors.white};
    box-shadow: 0px 3px 10px 0px rgba(55, 65, 81, 0.09);
    width: 400px;
    padding: 32px 24px;
`;

export const StyledCardTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledButtonContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 38px;
`;

export const StyledImageContainer = styled.img`
    align-self: center;
    width: 199px;
`;

export const StyledFooter = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -2px 6px 0px #0000000a;
    background-color: ${(p) => p.theme.colors.white};
    padding: 16px 72px;
    box-sizing: border-box;
`;

export const StyledButton = styled.div`
    width: 314px;
`;
