import styled from 'styled-components';

export const StyledModularEnrolmentFooter = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px -2px 6px 0px #0000000a;
    background-color: ${(p) => p.theme.colors.white};
    padding: 16px 72px;
    box-sizing: border-box;
`;

export const StyledModularFooterCTA = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
`;

export const StyledModularFooterCTAButton = styled.div`
    width: 240px;
`;

export const StyledModularPremiumAmount = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    border-left: 1px solid ${(p) => p.theme.colors.seafoamGray};
    padding-left: 24px;
    height: 100%;
`;

export const StyledFooterInfoIcon = styled.img`
    width: 20px;
    aspect-ratio: 1;
    cursor: pointer;
`;

export const StyledModularFooterLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

export const StyledFooterBackButtonWrapper = styled.div`
    border-right: 1px solid ${(p) => p.theme.colors.seafoamGray};
    padding-right: 24px;
    height: 100%;
`;

export const StyledSubmitConfirmationModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 500px;
`;

export const StyledSubmitConfirmationModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 0px 32px;
`;

export const StyledSubmitConfirmationModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.surface0};
`;

export const StyledSubmitConfirmationModalFooter = styled.div`
    border-top: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
    padding: 16px 32px;
`;

export const StyledPlanChangeModal = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 780px;
`;

export const StyledPlanChangeModalContent = styled.div`
    padding: 24px 24px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledPlanChangeModalFooter = styled.div`
    padding: 16px 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
    & > * {
        width: fit-content;
    }
`;

export const StyledPlanChangeModalListWrapper = styled.div`
    display: flex;
    align-items: start;
    gap: 24px;
    justify-content: center;
`;

export const StyledPlanChangeModalList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 310px;
    border-radius: 8px;
    padding: 12px 16px;
    background-color: ${(p) => p.theme.colors.surface0};
`;

export const StyledPlanChangeModalItem = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

export const StyledPlanChangeModalItemText = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledPlanChangeModalContentHeader = styled.div`
    text-align: center;
`;

export const StyledConfirmationModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 500px;
`;

export const StyledConfirmationModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 0px 32px;
`;

export const StyledConfirmationModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.surface0};
`;

export const StyledConfirmationModalFooter = styled.div`
    display: flex;
    gap: 24px;
    border-top: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
    padding: 16px 32px;
`;
