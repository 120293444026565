import { CardIcon, LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { ShruggingGraphic } from '../../../assets/img';
import theme from '../../../theme';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
    StyledPlanChangeModal,
    StyledPlanChangeModalContent,
    StyledPlanChangeModalContentHeader,
    StyledPlanChangeModalFooter,
    StyledPlanChangeModalItem,
    StyledPlanChangeModalItemText,
    StyledPlanChangeModalList,
    StyledPlanChangeModalListWrapper
} from './styles';
import { IPlanChangeModal } from './types';
import { capitalizeFirstLetter, capitalizeFirstLetterEach, getIconMapping } from '../../../utils/common';
import ProfileIcon from '../../atoms/ProfileIcon';
import { IGender } from '../../../redux/slices/ModularEnrolmentSlice/types';

const PlanChangeModal: React.FC<IPlanChangeModal> = ({ isVisible, onClose, onConfirm, deletions, isUpdating }) => {
    const handleClose = () => {
        if (!isUpdating) onClose();
    };
    return (
        <Modal isVisible={isVisible} setIsVisible={handleClose} disablePadding>
            <WithThemeProvider>
                <StyledPlanChangeModal>
                    <img src={ShruggingGraphic} alt="shrugging graphic" />
                    <StyledPlanChangeModalContent>
                        <StyledPlanChangeModalContentHeader>
                            <Typography variant="title2" weight="medium">
                                Changing to {deletions?.planName} will remove some dependants and add-ons
                            </Typography>
                        </StyledPlanChangeModalContentHeader>
                        <StyledPlanChangeModalListWrapper>
                            {deletions?.family?.length ? (
                                <StyledPlanChangeModalList>
                                    <Typography variant="small" weight="medium">
                                        Dependants getting removed
                                    </Typography>
                                    {deletions?.family.map((f) => (
                                        <StyledPlanChangeModalItem key={f.id}>
                                            <ProfileIcon
                                                icon={getIconMapping(f.relationship, f.gender as IGender)}
                                                bgColor={theme.colors.coverYellow}
                                            />
                                            <StyledPlanChangeModalItemText>
                                                <Typography variant="medium" weight="semiBold">
                                                    {capitalizeFirstLetterEach(f.name)}
                                                </Typography>
                                                <Typography variant="extraSmall" weight="semiBold" color="secondary">
                                                    {capitalizeFirstLetter(f.relationship)}
                                                </Typography>
                                            </StyledPlanChangeModalItemText>
                                        </StyledPlanChangeModalItem>
                                    ))}
                                </StyledPlanChangeModalList>
                            ) : null}

                            {deletions?.benefits?.length ? (
                                <StyledPlanChangeModalList>
                                    <Typography variant="small" weight="medium">
                                        Add-ons getting removed
                                    </Typography>
                                    {deletions?.benefits.map((b) => (
                                        <StyledPlanChangeModalItem key={b.name}>
                                            <CardIcon imageUrl={b.icon} />
                                            <Typography variant="medium" weight="semiBold">
                                                {b.name}
                                            </Typography>
                                        </StyledPlanChangeModalItem>
                                    ))}
                                </StyledPlanChangeModalList>
                            ) : null}
                        </StyledPlanChangeModalListWrapper>
                    </StyledPlanChangeModalContent>
                    <StyledPlanChangeModalFooter>
                        <LoopButton variant="secondary" onClick={handleClose}>
                            Never Mind
                        </LoopButton>
                        <LoopButton variant="filled" onClick={onConfirm} isLoading={isUpdating}>
                            Yes, Change Plan
                        </LoopButton>
                    </StyledPlanChangeModalFooter>
                </StyledPlanChangeModal>
            </WithThemeProvider>
        </Modal>
    );
};

export default PlanChangeModal;
