import React from 'react';
import { Alerts, Form, Typography } from '@loophealth/loop-ui-web-library';
import { AddMemberCard, MemberCard } from '..';
import { colorLibrary } from '../../../theme';
import { EnrollMembersContainer, StyledWarning, StyledSpacing } from './styles';
import { IAddDependents, IRadioItem, oneOfParentsOrInLawsOptions, parentOptions } from './types';
import { formatDate } from '../../../utils/common';
import { WarningIcon } from '../../../assets/img';
import { IModularFamily } from '../../../redux/slices/ModularEnrolmentSlice/types';
import { getAddMemberCardCount, getRelationshipUtil } from './utils';

const DisplayDependents: React.FC<IAddDependents> = ({
    title,
    currentMapping,
    targetMapping,
    membersToDisplay,
    relation,
    onAddMemberClick,
    onMemberEditClick,
    onMemberDeleteClick,
    setSelectedParentOption
}) => {
    const inferredParentId = parentOptions.map(({ id }) => id).includes(membersToDisplay[0]?.relationship)
        ? membersToDisplay[0]?.relationship
        : parentOptions[0].id;
    const [selectedParentId, setSelectedParentId] = React.useState(inferredParentId);
    const [selectedOption, setSelectedOption] = React.useState<null | IRadioItem>(
        parentOptions?.find((itm) => itm.id === inferredParentId) || null
    );
    const [switchedOption, setSwitchedOption] = React.useState<boolean>(false);
    const addMemberCardCount = getAddMemberCardCount(relation, currentMapping, targetMapping, selectedParentId);

    React.useEffect(() => {
        setSelectedParentOption && selectedOption && setSelectedParentOption(selectedOption.value);
    }, [selectedOption]);

    React.useEffect(() => {
        setSelectedParentId(inferredParentId);
        setSelectedOption(parentOptions?.find((itm) => itm.id === inferredParentId) || null);
    }, [inferredParentId]);

    React.useEffect(() => {
        if (switchedOption) setSwitchedOption(false);
    }, [membersToDisplay]);

    const onRadioClick = (itm: IRadioItem) => {
        if (!membersToDisplay.length) {
            setSelectedParentId(itm.id);
            setSelectedOption(itm);
        }
        setSwitchedOption(true);
    };

    const WarningDescription = () => {
        return (
            <Typography variant="small" color="error">
                {`Please remove ${selectedOption?.value} details to insure
      ${parentOptions?.find((itm) => itm.id !== inferredParentId)?.value}. The policy permits insuring either
                parents or parents-in-law, not both.`}
            </Typography>
        );
    };

    return (
        <>
            {relation !== 'Parents-in-law' ? (
                <Typography variant="small" weight="semiBold">
                    {title}
                </Typography>
            ) : null}
            {oneOfParentsOrInLawsOptions.includes(relation) && (
                <>
                    <StyledSpacing>
                        <Form.RadioButton
                            radioItems={parentOptions}
                            selectedItem={selectedOption}
                            onClick={onRadioClick}
                        />
                    </StyledSpacing>
                    {membersToDisplay.length && switchedOption ? (
                        <StyledWarning>
                            <Alerts.StatusAlert
                                text={<WarningDescription />}
                                variant="over"
                                iconSrc={WarningIcon}
                            ></Alerts.StatusAlert>
                        </StyledWarning>
                    ) : null}
                </>
            )}
            <EnrollMembersContainer>
                {membersToDisplay &&
                    membersToDisplay.map((dep: IModularFamily, index: number) => {
                        return (
                            <MemberCard
                                key={dep.id}
                                firstName={dep.firstName ? dep.firstName : ''}
                                lastName={dep.lastName ? dep.lastName : ''}
                                relationship={dep.relationship ? dep.relationship : 'N/A'}
                                doB={dep.dob ? formatDate(dep.dob) : 'N/A'}
                                gender={dep.gender ? dep.gender : 'N/A'}
                                mobile={dep.mobile ? dep.mobile : 'N/A'}
                                onMemberDelete={() => onMemberDeleteClick(dep.id)}
                                onMemberEdit={() => onMemberEditClick(dep)}
                                color={colorLibrary[index % colorLibrary.length]}
                                isModularFlow
                            />
                        );
                    })}
                {Array.from(Array(addMemberCardCount), (_element, index) => {
                    return (
                        <AddMemberCard
                            key={index}
                            onClick={onAddMemberClick}
                            relationship={
                                !oneOfParentsOrInLawsOptions.includes(relation)
                                    ? getRelationshipUtil(relation)
                                    : selectedParentId
                            }
                        />
                    );
                })}
            </EnrollMembersContainer>
        </>
    );
};

export default DisplayDependents;
