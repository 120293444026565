import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    padding: 14px;
    box-sizing: border-box;
    background: ${(p) => p.theme.colors.background};
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

export const StyledDetails = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
    gap: 8px;
    position: absolute;
    width: 596px;
    bottom: 20%;
    left: 8%;
    @media (max-width: 768px) {
        bottom: 10%;
        left: 15%;
    }
`;

export const StyledBackground = styled.img`
    width: 100%;
    height: 100%;
    flex: 1;
`;

export const StyledLogoContainer = styled.div`
    display: flex;
    position: absolute;
    left: 40px;
    top: 32px;
`;

export const StyledLogo = styled.img``;

export const StyledName = styled.div`
    text-transform: capitalize;
`;

export const StyledPendingDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StyledButtonConatiner = styled.div`
    position: absolute;
    display: flex;
    right: 38px;
    top: 38px;
    cursor: pointer;
`;

export const StyledRegistrationButton = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
    height: fit-content;
`;

export const StyledCompleteRegistration = styled.div`
    width: fit-content;
`;

export const StyledDivider = styled.div`
    width: 1px;
    height: 48px;
    background-color: ${(p) => p.theme.colors.borderLine};
`;
