import styled from 'styled-components';

export const StyledContainer = styled.div`
    padding: 70px 100px;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 28px;
    background-color: ${(p) => p.theme.colors.background};
    @media (max-width: 1024px) {
        padding: 45px;
    }
    @media (max-width: 768px) {
        padding: 25px 0px;
    }
`;
export const StyledInsuranceContainer = styled.div``;

export const StyledNoPolicyText = styled.div(
    (p) => `
    text-align:center;
    font-weight:bold;
    margin-top: 8px;
    font-size: ${p.theme.fontSizes.mediumFont};
    color: ${p.theme.colors.mutedBG};
    @media (max-width: 768px) {
        padding: 10px 20px;
        line-height: 20px;
    }
`
);

export const StyledEnrolmentCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
`;

export const StyledWarningWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyleAlertWarning = styled.div`
    @media (max-width: 768px) {
        padding: 0px 20px;
    }
`;
