import { IModularFamily } from '../../../redux/slices/ModularEnrolmentSlice/types';
import { IMappings } from '../../../utils/mapping';

export interface IFamilyToDisplay {
    selfSpouse?: IModularFamily[];
    children?: IModularFamily[];
    parents?: IModularFamily[];
    parentsInLaw?: IModularFamily[];
}

export interface IAddDependents {
    title: string;
    currentMapping: null | IMappings;
    targetMapping: null | IMappings;
    membersToDisplay: [] | IModularFamily[];
    relation: string;
    onAddMemberClick(): void;
    onMemberEditClick(dep: IModularFamily): void;
    onMemberDeleteClick(id: string): void;
    setSelectedParentOption?(value: string): void;
}

export type IRelation = 'Self' | 'Spouse' | 'Child' | 'Parent' | 'Parent-in-law' | '';

export type IParentOptions = {
    id: string;
    name: string;
    value: IRelation;
};

export type IGetDependentCountReturnType = {
    relation: string;
    number: number;
};

export type IGetRelationshipReturnType =
    | ''
    | 'Spouse'
    | 'child'
    | 'parent'
    | 'parent/parent-in-law'
    | 'parent|parent-in-law'
    | 'parent-in-law';

export interface IRadioItem {
    id: string;
    value: string;
    disabled?: boolean;
}

export const oneOfParentsOrInLawsOptions = ['Parents OR parents-in-law', 'Parents OR parent-in-laws'];

export const parentOptions: IParentOptions[] = [
    {
        id: 'parent',
        name: 'Insure Parents',
        value: 'Parent'
    },
    {
        id: 'parent-in-law',
        name: 'Insure Parents-In-Law',
        value: 'Parent-in-law'
    }
];
