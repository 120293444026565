import styled from 'styled-components';

export const StyledCostPremiumModal = styled.div`
    display: flex;
    flex-direction: column;
    width: 750px;
    padding: 0 24px;
    box-sizing: border-box;
`;
export const StyledCostPremiumModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 0;
    box-sizing: border-box;
    max-height: 450px;
    overflow-y: auto;
`;

export const StyledCostPremiumModalFooter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0;
    width: 100%;
    box-sizing: border-box;
`;

export const StyledCostPremiumModalPremium = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledCostPremiumItem = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
`;

export const StyledCostPremiumIconWrapper = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

export const StyledCostPremiumText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledCostPremiumIcon = styled.img`
    width: 40px;
    height: 40px;
    aspect-ratio: 1;
`;
