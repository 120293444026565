import styled from 'styled-components';

export const StyledContainer = styled.div<{
    $bgColor: string;
    $height: string;
    $width: string;
}>(
    (p) => `
    display: flex;
    width: ${p.$width}px;
    height: ${p.$height}px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding: 12px 8px;
    box-sizing: border-box;
    background-color: ${p.$bgColor};
`
);
