import { combineReducers } from 'redux';
import { PolicyListSlice, EnrolmentSlice, StepperSlice, ApplicationStateSlice, ModularEnrolmentSlice } from '../slices';
import { UserSlice } from '../../components/pages/LoginContainer/redux/slices';
import {
    ApplicationStateSlice as TopupApplicationStateSlice,
    TopUpLandingPageSlice,
    PolicyListSlice as TopUpPolicyListSlice
} from '../../topup-src/redux/slices';

const rootReducer = combineReducers({
    user: UserSlice,
    policyList: PolicyListSlice,
    stepperData: StepperSlice,
    enrolment: EnrolmentSlice,
    applicationState: ApplicationStateSlice,
    modular: ModularEnrolmentSlice,
    TopUp_applicationState: TopupApplicationStateSlice,
    TopUp_user: UserSlice,
    TopUp_policyData: TopUpPolicyListSlice,
    TopUp_LandingPage: TopUpLandingPageSlice
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
