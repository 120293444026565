import React from 'react';
import { ImageView } from '../../atoms/ImageView';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledCardContainer,
    StyledColumnContainer,
    StyledFlexContainer,
    StyledPolicyDetails,
    StyledPolicyInfoContainer
} from './styles';
import { IThresholdContainerProps } from './types';
import { StyledButtonContainer } from '../../pages/SPAContainer/pages/ThresholdPage/styles';
import theme from '../../../theme';

const ThresholdContainer: React.FunctionComponent<IThresholdContainerProps> = ({
    title,
    image,
    handleBack,
    handleNext,
    policyImage,
    policyCategory,
    policyName
}) => {
    return (
        <StyledCardContainer>
            <StyledColumnContainer>
                <Typography variant="title2" weight="semiBold">
                    {title}
                </Typography>
                <Typography variant={'small'}>
                    {'Begin your seamless registration journey with Loop.'}
                </Typography>
                <StyledPolicyDetails>
                    <Typography variant={'small'}>{'Registering for'}</Typography>
                    <StyledPolicyInfoContainer>
                        <ImageView
                            src={policyImage}
                            width={'40px'}
                        />
                        <StyledFlexContainer gap={'2px'} flexDirection={'column'}>
                            <Typography variant="small" weight="semiBold">
                                {policyName}
                            </Typography>
                            <Typography variant="extraSmall" color="secondary">
                                {policyCategory}
                            </Typography>
                        </StyledFlexContainer>
                    </StyledPolicyInfoContainer>
                </StyledPolicyDetails>
                <StyledButtonContainer>
                    <LoopButton
                        borderColor={theme.colors.loopEmerald}
                        variant="outline"
                        onClick={handleBack}
                    >
                        {'Back to Home'}
                    </LoopButton>
                    <LoopButton variant="filled" onClick={handleNext}>
                        {'Get Started'}
                    </LoopButton>
                </StyledButtonContainer>
            </StyledColumnContainer>
            <StyledColumnContainer>
                <ImageView src={image} width={'360px'} />
            </StyledColumnContainer>
        </StyledCardContainer>
    );
};

export default ThresholdContainer;
