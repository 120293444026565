import React from 'react';
import {
    StyledContainer,
    StyledContent,
    StyledDescription,
    StyledButtonContainer,
    StyledIcon
} from './styles';
import { IDeleteModularMemberModal } from './types';
import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { DeleteCircleIcon } from '../../../assets/img';

const DeleteModularMemberModal: React.FunctionComponent<IDeleteModularMemberModal> = ({
    isVisible,
    setIsVisible,
    confirmDelete,
    loading
}) => {
    const handleSubmit = () => {
        if (!loading) {
            confirmDelete();
        }
    };
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
            <StyledContainer>
                <StyledContent>
                    <StyledIcon src={DeleteCircleIcon} />
                    <Typography variant="large" weight="semiBold">
                        Delete Dependant
                    </Typography>
                </StyledContent>
                <StyledDescription>
                    <Typography variant="small">
                        Are you sure you want to delete this member from the policy?
                    </Typography>
                </StyledDescription>
                <StyledButtonContainer>
                    <LoopButton variant="error" onClick={handleSubmit}>
                        Delete member
                    </LoopButton>
                    <LoopButton variant="outline" onClick={() => setIsVisible(false)} isLoading={loading}>
                        Cancel
                    </LoopButton>
                </StyledButtonContainer>
            </StyledContainer>
        </Modal>
    );
};

export default DeleteModularMemberModal;
