import styled from 'styled-components';
import { IStyledStepperStep } from './types';

export const StyledPageStepper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledStepsContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: nowrap;
`;

export const StyledStepperStep = styled.div<IStyledStepperStep>`
    width: 40px;
    height: 6px;
    border-radius: 20px;
    background-color: ${(p) => p.theme.colors.surfaceFillEmerald};
    position: relative;
    overflow: hidden;
    &::after {
        content: 'a';
        color: transparent;
        width: 40px;
        height: 6px;
        border-radius: 20px;
        background-color: ${(p) => p.theme.colors.loopEmerald};
        position: absolute;
        right: ${(p) => (p.$completed ? '0' : p.$current ? '45%' : '100%')};
        top: 0;
    }
`;

export const StyledStepperTextContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledStepperTextDot = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 20px;
    background-color: ${(p) => p.theme.colors.secondaryText};
`;
