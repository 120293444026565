import React from 'react';
import {
    StyledContainer,
    StyledButtonContainer,
    StyledButtonWrapper,
    StyleLoopTagWrapper,
    StyledEnrolButtonWrapper,
    StyledEnrolledMemberContainer,
    StyledIcon,
    StyledArrowIcon,
    StyledEnrolledMemberWrapper,
    StyledWrapper,
    StyledTopUpContainer,
    StyledTopUpIcon,
    StyledCardDefaultDivider,
    StyledAddTopUpBtn,
    StyledStripWrapper,
    StyledDetailsContainer,
    StyledPremiumContainer,
    StyledBreakdownContainer,
    StyledChevron
} from './styles';
import {
    ArrowDownEmerald,
    ArrowRightEmerald,
    ArrowRightGreen,
    CompletedIcon,
    DoneCheckIcon,
    EditIconGreen,
    PendingIcon,
    PersonIcon,
    TopUpIcon
} from '../../../assets/img';
import { InsuranceIdentity } from '../../atoms';
import { IEnrolUserProps, IMembersDataProps } from './types';
import { LoopButton, LoopTag, Typography } from '@loophealth/loop-ui-web-library';
import theme from '../../../theme';
import useResponsive from '../PolicyInclusionsModal/hooks/useResponsive';
import EnrolledMemberModal from './EnrolledMemberModal';
import EnrolledMemberTable from './EnrolledMemberTable';
import { calculateAPString, showTaxStripUtil } from './utils';
import { ReduxState } from '../../../redux';
import { useSelector } from 'react-redux';
import { EnrolmentStatus, isDummyPolicy } from '../../pages/SPAContainer/pages/DashboardPage/utils';
import { PARENTAL_POLICY, PolicyTypeConstants } from '../../../utils/constants';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { isDummyStepathonPolicyHidden, isReEditEnabled } from '../../../utils/featureFlags';
import { familyDefinition } from '../../../utils/mapping';
import PolicyStatusTag from '../Dashboard/PolicyStatusTag';
import { PolicyStatus } from '../Dashboard/PolicyStatusTag/types';
import PolicyDetails from './PolicyDetailsSection';
import { useModular } from './hooks/useModular';
import PolicyInclusionsModal from '../PolicyInclusionsModal';
import CostPremiumModal from '../PremiumBreakdownModal';
import PolicyBenefitsModal from '../PolicyBenefitsModal';

const EnrolMembersCard: React.FunctionComponent<IEnrolUserProps> = ({
    dependents,
    onClick,
    addTopUpClick,
    policyId,
    SI,
    enrolmentStatus,
    superTopupStatus,
    currentStep,
    insurerName,
    annualPremium,
    nickName,
    isModular,
    premiumToBeDeducted,
    benefits,
    ...insuranceProps
}) => {
    const trackClick = useSegment('click');
    const [policyInclusionModalVisible, setPolicyInclusionModalVisible] = React.useState<boolean>(false);
    const [showDependentModal, setShowDependentModal] = React.useState<boolean>(false);
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const isDesktop = useResponsive('(min-width: 768px)');
    const companyId = useSelector((state: ReduxState) => state.user.userData.data?.employer);
    const isEnrolmentPending = enrolmentStatus?.toLowerCase() === EnrolmentStatus.PENDING;
    const isEnrolmentCompleted = enrolmentStatus?.toLowerCase() === EnrolmentStatus.ENROLED;
    const isTopUpPolicy = insuranceProps.insuranceCategory === PolicyTypeConstants.SUPER_TOP_UP;
    const isDummyPolicyCard = isDummyPolicy(nickName);
    const [breakdownModalVisible, setBreakdownModalVisible] = React.useState<boolean>(false);
    const [benefitsModalVisible, setBenefitsModalVisible] = React.useState<boolean>(false);
    const { getSelectedAlacarte, getSILabel, getSI } = useModular({
        benefits,
        sumInsured: insuranceProps.sumInsured,
        isEnrolmentComplete: isEnrolmentCompleted,
        isModular
    });
    const isEnrolmentLapsed =
        insuranceProps.daysLeft === 'OVERDUE' ||
        insuranceProps.daysLeft === 'N/A' ||
        insuranceProps.daysLeft === 'N/A Days Left';

    const processedProps: IMembersDataProps = {
        dependents,
        isCollapsed: isCollapsed,
        dependentsInsured: isModular ? insuranceProps.familyDefinition || '' : insuranceProps.dependentsInsured,
        isTopUpPolicy: isTopUpPolicy
    };

    const handleIconClick = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.REGISTERED_MEMBERS;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                policy_id: (segmentAction.properties?.policy_id as (policyId: string) => string)(policyId) || '',
                page_title: (segmentAction.properties?.page_title as (title: string) => string)('Dashboard') || '',
                action_type:
                    (segmentAction.properties?.action_type as (action: string) => string)(
                        !isCollapsed ? 'VIEW' : 'HIDE'
                    ) || '',
                login_status: segmentAction.properties?.login_status,
                registered_members:
                    (segmentAction.properties?.registered_members as (registeredMembers: number) => number)(
                        dependents.length
                    ) || '',
                total_members:
                    (segmentAction.properties?.total_members as (totalMembers: number) => number)(
                        (familyDefinition[insuranceProps.dependentsInsured]?.totalDependents || 0) +
                            (PARENTAL_POLICY.includes(insuranceProps.dependentsInsured) ? 0 : 1)
                    ) || ''
            }
        });
        setIsCollapsed(!isCollapsed);
        if (!isDesktop) {
            setShowDependentModal(true);
            setIsCollapsed(false);
        }
    };
    const showTopUpStrip = showTaxStripUtil(
        insuranceProps,
        SI,
        enrolmentStatus,
        superTopupStatus,
        companyId,
        dependents
    );
    const handleViewPolicyBenefitsClick = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.DASHBOARD_PAGE_POLICY_BENEFITS;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_url: (segmentAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (segmentAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                policy_id: (segmentAction.properties?.policy_id as (policyId: string) => string)(policyId) || '',
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status
            }
        });
        if (isModular) setBenefitsModalVisible(true);
        else setPolicyInclusionModalVisible(true);
    };
    return (
        <StyledWrapper>
            {showTopUpStrip && (
                <StyledTopUpContainer $purchased={!isEnrolmentCompleted}>
                    <StyledStripWrapper>
                        <StyledTopUpIcon src={TopUpIcon} />
                        <Typography variant="small" color={!isEnrolmentCompleted ? 'emerald' : 'tertiary'}>
                            Top-Up available for your medical policy to get additional coverage and tax benefits.
                        </Typography>
                    </StyledStripWrapper>
                    {isEnrolmentCompleted ? (
                        <StyledAddTopUpBtn>
                            <LoopButton
                                variant={isDesktop ? 'filled' : 'text'}
                                onClick={addTopUpClick}
                                iconOrder="right"
                                iconSrc={isDesktop ? ArrowRightEmerald : ArrowRightGreen}
                                textColor="green"
                            >
                                Add Top-up
                            </LoopButton>
                        </StyledAddTopUpBtn>
                    ) : null}
                </StyledTopUpContainer>
            )}
            <StyledContainer $divBorderRadius={showTopUpStrip}>
                <StyleLoopTagWrapper>
                    {isDummyStepathonPolicyHidden && isDummyPolicyCard ? (
                        <>
                            {isEnrolmentPending ? (
                                <LoopTag variant="error">FOR ENROLMENT ONLY</LoopTag>
                            ) : (
                                <LoopTag variant="default" iconSrc={DoneCheckIcon} iconOrder={'right'}>
                                    ENROLMENT COMPLETED
                                </LoopTag>
                            )}
                        </>
                    ) : (
                        <PolicyStatusTag
                            step={currentStep || 0}
                            icon={isEnrolmentPending ? PendingIcon : CompletedIcon}
                            status={isEnrolmentPending ? PolicyStatus.PENDING : PolicyStatus.COMPLETED}
                        />
                    )}
                </StyleLoopTagWrapper>
                <InsuranceIdentity
                    policyImage={insuranceProps.policyImage}
                    policyName={insurerName ?? insuranceProps.insuranceName}
                    policyCategory={insuranceProps.insuranceCategory}
                    familyStructure={insuranceProps.dependentsInsured}
                />
                <StyledButtonContainer $displayBorder={!isTopUpPolicy}>
                    {!isTopUpPolicy && ((isEnrolmentCompleted && isModular) || !isModular) ? (
                        <StyledButtonWrapper>
                            <LoopButton
                                onClick={handleViewPolicyBenefitsClick}
                                variant="outline"
                                size={isDesktop ? 'medium' : 'small'}
                            >
                                View Policy Benefits
                            </LoopButton>
                        </StyledButtonWrapper>
                    ) : null}
                    {isEnrolmentPending && !isEnrolmentLapsed ? (
                        <StyledEnrolButtonWrapper>
                            <LoopButton
                                onClick={onClick}
                                variant="filled"
                                iconOrder="right"
                                iconSrc={ArrowRightEmerald}
                                size={isDesktop ? 'medium' : 'small'}
                            >
                                {isTopUpPolicy ? 'Add Top-Up' : 'Register Now'}
                            </LoopButton>
                        </StyledEnrolButtonWrapper>
                    ) : null}
                    {isReEditEnabled &&
                    isEnrolmentCompleted &&
                    !isEnrolmentLapsed &&
                    !insuranceProps.isSuperTopupPurchased ? (
                            <StyledEnrolButtonWrapper>
                                <LoopButton
                                    onClick={onClick}
                                    variant="outline"
                                    iconOrder="right"
                                    iconSrc={EditIconGreen}
                                    size={isDesktop ? 'medium' : 'small'}
                                    textColor="emerald"
                                    borderColor={isDesktop ? '' : theme.colors.loopEmerald}
                                >
                                Re-Do Registration
                                </LoopButton>
                            </StyledEnrolButtonWrapper>
                        ) : null}
                </StyledButtonContainer>
                <StyledCardDefaultDivider $display={isEnrolmentPending} />
                <PolicyDetails
                    sumInsured={getSI().sumInsured}
                    enrolmentDueDate={insuranceProps.enrolmentDueDate}
                    policyEndDate={insuranceProps.policyEndDate}
                    annualPremium={calculateAPString(annualPremium ?? 0)}
                    dependentsInsured={insuranceProps.dependentsInsured}
                    isTopUpPolicy={isTopUpPolicy}
                    selectedBenefits={(isModular && isEnrolmentCompleted && benefits && getSelectedAlacarte()) || []}
                    SILabel={getSILabel()}
                    planName={getSI().planName}
                />
                {isEnrolmentCompleted ? (
                    <StyledEnrolledMemberContainer>
                        {isDesktop ? (
                            <EnrolledMemberTable data={processedProps} />
                        ) : (
                            <EnrolledMemberModal
                                dependents={dependents}
                                isCollapsed={isCollapsed}
                                isVisible={showDependentModal}
                                setIsVisible={setShowDependentModal}
                                dependentsInsured={insuranceProps.dependentsInsured}
                                isTopUpPolicy={isTopUpPolicy}
                            />
                        )}
                        <StyledDetailsContainer>
                            <StyledEnrolledMemberWrapper onClick={handleIconClick}>
                                <StyledIcon src={PersonIcon} />
                                <Typography variant="small" weight="medium" color="emerald">
                                    {isCollapsed ? 'Hide Registered Members' : 'View Registered Members'}
                                </Typography>
                                <StyledArrowIcon $isCollapsed={isCollapsed} src={ArrowDownEmerald} />
                            </StyledEnrolledMemberWrapper>
                            {isModular && (
                                <StyledPremiumContainer>
                                    <Typography variant="small">Premium to be deducted</Typography>
                                    <Typography variant="large" weight="semiBold">
                                        {premiumToBeDeducted}
                                    </Typography>
                                    <StyledBreakdownContainer onClick={() => setBreakdownModalVisible(true)}>
                                        <Typography variant="small" weight="medium" color="emerald">
                                            View Breakdown
                                        </Typography>
                                        <StyledChevron src={ArrowRightEmerald} />
                                    </StyledBreakdownContainer>
                                </StyledPremiumContainer>
                            )}
                        </StyledDetailsContainer>
                    </StyledEnrolledMemberContainer>
                ) : null}
                {!isModular && (
                    <PolicyInclusionsModal
                        setIsVisible={setPolicyInclusionModalVisible}
                        isVisible={policyInclusionModalVisible}
                        policyId={policyId}
                        insuranceCategory={insuranceProps.insuranceCategory}
                        familyStructure={insuranceProps.dependentsInsured}
                        policyImage={insuranceProps.policyImage}
                        policyName={insurerName ?? insuranceProps.insuranceName}
                    />
                )}
                {isModular && benefits?.modular && benefits.alacarte && benefits.priceSummary && (
                    <CostPremiumModal
                        title="Purchase Summary"
                        isVisible={breakdownModalVisible}
                        setIsVisible={setBreakdownModalVisible}
                        modular={benefits.modular[0]}
                        alacarte={benefits.alacarte}
                        priceSummary={benefits.priceSummary}
                    />
                )}
                {isModular && benefits && isEnrolmentCompleted && (
                    <PolicyBenefitsModal
                        setIsVisible={setBenefitsModalVisible}
                        isVisible={benefitsModalVisible}
                        plan={benefits?.modular?.[0]}
                    />
                )}
            </StyledContainer>
        </StyledWrapper>
    );
};

export default EnrolMembersCard;
