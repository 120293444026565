import styled from 'styled-components';

export const StyledModularEnrolmentSummary = styled.div`
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 36px 72px;
`;

export const StyledModularEnrolmentSummaryCard = styled.div`
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.colors.seafoamGray};
    padding: 32px;
    width: 100%;
    box-sizing: border-box;
`;

export const StyledModularEnrolmentSummaryCardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledSelectedPlanHeader = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    & > * {
        width: fit-content;
    }
`;

export const StyledModularSummaryPlanItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
`;

export const StyledModularSummaryPlanItemInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const StyledModularSummaryPlanCTAWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledModularSummaryPlanText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const StyledModularSummaryPlanCTA = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

export const StyledModularSummaryPlanCTASeperator = styled.div`
    width: 1px;
    height: 18px;
    background-color: ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledModularSummaryHorizontalDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(p) => p.theme.colors.mutedTransparent};
`;

export const StyledModularSummaryHorizontalDottedDivider = styled.div`
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, ${(p) => p.theme.colors.seafoamGray} 50%, transparent 0%);
    background-size: 12px 1px;
`;

export const StyledModularSummaryColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledModularSummaryColumnRight = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: end;
`;

export const StyledModularSummaryPlanWallet = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledModularSummaryPremiumWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
