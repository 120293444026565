export enum PolicyStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED'
}

export interface IPolicyStatusTag {
    status: PolicyStatus;
    step: number;
    icon?: string;
}
