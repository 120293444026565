import { Toast } from '@loophealth/loop-ui-web-library';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { saveAndCheckoutModular, updateBenefitIds, validateBenefits } from '../../../../adapters';
import { ReduxState } from '../../../../redux';
import {
    fetchModularPlans,
    resetModularState,
    setModularCurrentStep
} from '../../../../redux/slices/ModularEnrolmentSlice';
import {
    selectedAlaCarteBenefits,
    selectModularCurrentStep,
    selectModularFamily,
    selectModularPersonalEmail,
    selectModularPlans,
    selectModularPolicyId,
    selectModularSelectedBasePlan,
    selectModularSummary
} from '../../../../redux/slices/ModularEnrolmentSlice/selectors';
import { ModularEnrolmentSteps } from '../../../../redux/slices/ModularEnrolmentSlice/types';
import { fetchUserPolicyList } from '../../../../redux/slices/PolicyListSlice';
import { parseResponse } from '../../../../utils/common';
import { INTERNAL_ROUTES } from '../../../../utils/constants';
import { IChangePlanDeletions, IUseModularEnrolment } from '../types';
import { familyDefinition } from '../../../../utils/mapping';
import useSegment from '../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../../utils/constants/SegmentActionConstants';

const useModularEnrolment = (): IUseModularEnrolment => {
    const history = useHistory();
    const dispatch = useDispatch();
    const trackClick = useSegment('click');
    const toast = Toast.useToast();
    const location = useLocation();

    const currentStep = useSelector(selectModularCurrentStep);
    const selectedBasePlan = useSelector(selectModularSelectedBasePlan);
    const selectedBenefits = useSelector(selectedAlaCarteBenefits);
    const selectedPersonalEmail = useSelector(selectModularPersonalEmail);
    const modularPlans = useSelector(selectModularPlans).data || [];
    const isSummaryLoading = useSelector(selectModularSummary).loading;
    const policyId = useSelector(selectModularPolicyId);
    const userId = useSelector((state: ReduxState) => state.user.userData.data?.userId || '');
    const addedMembers = useSelector(selectModularFamily).data;

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [submitModalVisible, setSubmitModalVisible] = React.useState(false);
    const [confirmDeletions, setConfirmDeletions] = React.useState<IChangePlanDeletions | null>(null);
    const [confirmMemberModalVisible, setConfirmMemberModalVisible] = React.useState(false);

    const isProceedEnabled = useMemo(() => {
        switch (currentStep) {
        case ModularEnrolmentSteps.BASE_PLAN:
            return !!selectedBasePlan;
        case ModularEnrolmentSteps.DEPENDENTS:
        case ModularEnrolmentSteps.HEALTHCARE_BENEFITS:
        case ModularEnrolmentSteps.SUMMARY:
            return !isSummaryLoading;
        }
    }, [selectedBasePlan, currentStep, isSummaryLoading]);

    const updateSelectedBenefits = async () => {
        if (!selectedBasePlan) return;
        const deletions = confirmDeletions
            ? {
                benefitIds: confirmDeletions.benefits.map((benefit) => benefit.id),
                familyIds: confirmDeletions.family.map((family) => family.id)
            }
            : undefined;
        const [err] = await parseResponse(
            updateBenefitIds(
                userId,
                policyId,
                [selectedBasePlan, ...selectedBenefits],
                deletions,
                selectedPersonalEmail
            )
        );
        if (err) throw err;
    };

    const onModularPlanNext = async () => {
        if (!selectedBasePlan) return;
        const previouslySelected = modularPlans.find((plan) => plan.isSelected);

        if (previouslySelected?.id !== selectedBasePlan) {
            setIsProcessing(true);
            try {
                const { deletions } = await validateBenefits(userId, policyId, selectedBasePlan);

                if (deletions?.benefits?.length || deletions?.family?.length) {
                    const planName = modularPlans.find((p) => p.id === selectedBasePlan)?.name;
                    setConfirmDeletions({ ...deletions, planName });
                } else {
                    await handleOnConfirmUpdation();
                }
            } catch (e) {
                toast?.error((e as Error).message || 'Something went wrong');
            }
            setIsProcessing(false);
        } else dispatch(setModularCurrentStep(ModularEnrolmentSteps.DEPENDENTS));
    };

    const handleOnConfirmUpdation = async () => {
        setIsUpdating(true);
        try {
            await updateSelectedBenefits();
            setConfirmDeletions(null);
            dispatch(setModularCurrentStep(ModularEnrolmentSteps.DEPENDENTS));
            dispatch(fetchModularPlans({ userId, policyId })); // refetch modular after save
        } catch (e) {
            toast?.error((e as Error).message || 'Something went wrong');
        }
        setIsUpdating(false);
    };

    const membersAdded = () => {
        const count = addedMembers?.length;
        const selectedPolicy = modularPlans?.find((policy) => policy.id === selectedBasePlan);
        const expectedCount =
            selectedPolicy?.familyDefinition && familyDefinition[selectedPolicy?.familyDefinition]?.totalDependents;
        return {
            addedMembers: count || 0,
            totalMembers: (expectedCount || 0) + 1 // count of self
        };
    };

    const onDependentsNext = () => {
        const { addedMembers, totalMembers } = membersAdded();
        if (addedMembers < totalMembers) setConfirmMemberModalVisible(true);
        else onConfirmMembers();
    };

    const onConfirmMembers = () => {
        const clickAction = SEGMENT_ACTIONS.CLICK.PROCEED;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                page_name: 'Add_Dependant_page',
                page_category: 'Warning_popup_modal'
            }
        });
        setConfirmMemberModalVisible(false);
        dispatch(setModularCurrentStep(ModularEnrolmentSteps.HEALTHCARE_BENEFITS));
    };

    const onAlaCarteBenefitsNext = async () => {
        setIsProcessing(true);
        try {
            await updateSelectedBenefits();
            dispatch(setModularCurrentStep(ModularEnrolmentSteps.SUMMARY));
        } catch (e) {
            toast?.error((e as Error).message || 'Something went wrong');
        }
        setIsProcessing(false);
    };

    const handleOnSubmit = async () => {
        const clickAction = SEGMENT_ACTIONS.CLICK.COMPLETE_REGISTRATION;
        const pageAction = SEGMENT_ACTIONS.PAGE.REVIEW_REGISTRATION_MODAL;
        trackClick({
            name: clickAction.name.toLowerCase(),
            properties: {
                ...pageAction.properties
            }
        });
        setIsSubmitting(true);
        try {
            const [error] = await parseResponse(saveAndCheckoutModular(userId, policyId));
            if (error) throw error;
            // to update policy list for completion screen
            dispatch(fetchUserPolicyList({ userId }));
            history.replace(INTERNAL_ROUTES.confirm, { previousUrl: location.pathname, policyId: policyId });
            setSubmitModalVisible(false);
            dispatch(resetModularState());
        } catch (e) {
            toast?.error((e as Error).message || 'Something went wrong');
        }
        setIsSubmitting(false);
    };

    return {
        isProceedEnabled,
        submitModalVisible,
        isSubmitting,
        isUpdating,
        isProcessing,
        confirmDeletions,
        confirmMemberModalVisible,
        setConfirmMemberModalVisible,
        onModularPlanNext,
        onDependentsNext,
        onAlaCarteBenefitsNext,
        setSubmitModalVisible,
        setConfirmDeletions,
        handleOnSubmit,
        handleOnConfirmUpdation,
        membersAdded,
        onConfirmMembers
    };
};

export default useModularEnrolment;
