import React, { useEffect } from 'react';
import {
    StyledContainer,
    StyledInputGroup,
    StyledRow,
    StyledSpan,
    StyledAlignment,
    StyledButtonContainer,
    StyledStickyContainer,
    StyledTitleContainer
} from './styles';
import { Form, LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { IManageModularMember } from './types';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const ManageModularMemberModal: React.FunctionComponent<IManageModularMember> = ({
    isVisible,
    setIsVisible,
    formValues,
    formErrors,
    relationshipValues,
    onSubmit,
    isLoading,
    handleFormChange,
    currentAction
}) => {
    const trackPage = useSegment('page');
    const availableGender = [
        { name: 'Male', value: 'male' },
        { name: 'Female', value: 'female' }
    ];

    useEffect(() => {
        if (isVisible && formValues?.relationship) {
            const pageAction = SEGMENT_ACTIONS.PAGE.DEPENDENT_DETAILS_PAGE;
            trackPage({
                name: pageAction.name,
                properties: {
                    ...pageAction.properties,
                    dependent_type: formValues.relationship
                }
            });
        }
    }, [isVisible, formValues.relationship]);

    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} disablePadding={true} overflow="visible">
            <WithThemeProvider>
                <StyledContainer>
                    <StyledTitleContainer>
                        <Typography variant="large" weight="semiBold">
                            {currentAction} Dependant
                        </Typography>
                    </StyledTitleContainer>
                    <>
                        <StyledRow $isError={!!formErrors.firstName || !!formErrors.lastName}>
                            <StyledInputGroup data-automationid="first-name-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="medium">
                                        First Name
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.Input
                                    placeholder="Enter First Name"
                                    onChange={(e) => handleFormChange(e, 'firstName')}
                                    value={formValues.firstName}
                                    error={formErrors.firstName}
                                    type="text"
                                />
                            </StyledInputGroup>
                            <StyledInputGroup data-automationid="last-name-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="medium">
                                        Last Name
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.Input
                                    placeholder="Enter Last Name"
                                    onChange={(e) => handleFormChange(e, 'lastName')}
                                    value={formValues.lastName}
                                    error={formErrors.lastName}
                                    type="text"
                                />
                            </StyledInputGroup>
                        </StyledRow>
                        <StyledRow $isError={!!formErrors.relationship || !!formErrors.dob}>
                            <StyledInputGroup data-automationid="relationship-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="medium">
                                        Relationship
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.Dropdown
                                    placeholder="Select Relationship"
                                    listItems={relationshipValues}
                                    onClick={(e: string) => handleFormChange(e, 'relationship')}
                                    selectedItem={formValues.relationship}
                                    isDisabled={relationshipValues.length <= 1 ? true : false}
                                    error={formErrors.relationship}
                                />
                            </StyledInputGroup>

                            <StyledInputGroup data-automationid="dob-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="medium">
                                        Date of Birth (DD/MM/YYYY)
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.DatePicker
                                    value={formValues.dob ? new Date(formValues.dob) : null}
                                    onChange={(e) => handleFormChange(e, 'dob')}
                                    maxDate={new Date()}
                                    placeholder="Enter DOB"
                                    iconOrder="left"
                                    size="medium"
                                    fontSize="small"
                                    error={formErrors.dob}
                                />
                            </StyledInputGroup>
                        </StyledRow>
                        <StyledRow $isError={!!formErrors.gender || !!formErrors.mobile}>
                            <StyledInputGroup data-automationid="gender-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="medium">
                                        Gender
                                    </Typography>
                                    <StyledSpan>*</StyledSpan>
                                </StyledAlignment>
                                <Form.Dropdown
                                    placeholder="Select Gender"
                                    listItems={availableGender}
                                    onClick={(e: string) => handleFormChange(e, 'gender')}
                                    selectedItem={formValues.gender}
                                    error={formErrors.gender}
                                />
                            </StyledInputGroup>
                            <StyledInputGroup data-automationid="mobile-number-field">
                                <StyledAlignment>
                                    <Typography variant="small" weight="medium">
                                        Mobile Number
                                    </Typography>
                                </StyledAlignment>
                                <Form.Input
                                    placeholder="+91 | Enter phone number"
                                    onChange={(e) => handleFormChange(e, 'mobile')}
                                    value={formValues.mobile}
                                    error={formErrors.mobile}
                                    type="number"
                                />
                            </StyledInputGroup>
                        </StyledRow>
                    </>
                </StyledContainer>

                <StyledStickyContainer>
                    <StyledButtonContainer>
                        <LoopButton variant="filled" onClick={() => !isLoading && onSubmit()} isLoading={isLoading}>
                            Save Details
                        </LoopButton>
                    </StyledButtonContainer>
                </StyledStickyContainer>
            </WithThemeProvider>
        </Modal>
    );
};

export default ManageModularMemberModal;
