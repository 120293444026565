import { createSlice } from '@reduxjs/toolkit';
import { IActionPayload } from '../../types';
import { initialState } from './initialState';
import {
    fetchModularAlaCarteBenefits,
    fetchModularPlans,
    updateBenefits,
    fetchModularFamily,
    fetchModularSummary
} from './thunks';
import {
    IFetchModularPlansPayload,
    IModularEnrolmentSlice,
    IModularEnrolmentSummary,
    IFetchModularAlaCartePayload,
    ModularEnrolmentSteps
} from './types';

const resetToInitialState = (state: IModularEnrolmentSlice, initialState: IModularEnrolmentSlice) => {
    state.policyId = initialState.policyId;
    state.currentStep = initialState.currentStep;
    state.selectedBasePlan = initialState.selectedBasePlan;
    state.selectedAlaCarte = initialState.selectedAlaCarte;
    state.family = initialState.family;
    state.basePlans = initialState.basePlans;
    state.alaCarteBenefits = initialState.alaCarteBenefits;
    state.balance = initialState.balance;
    state.personalEmail = initialState.personalEmail;
    state.crudFamily = initialState.crudFamily;
    state.summary = initialState.summary;
};

const ModularEnrolmentSlice = createSlice({
    name: 'modularEnrolmentSlice',
    initialState,
    reducers: {
        resetModularState(state) {
            resetToInitialState(state, initialState);
        },
        setModularPolicy(state, { payload }: IActionPayload<string>) {
            state.policyId = payload;
        },
        setModularCurrentStep(state, { payload }: IActionPayload<ModularEnrolmentSteps>) {
            state.currentStep = payload;
        },
        setModularSelectedBasePlan(state, { payload }: IActionPayload<string>) {
            state.selectedBasePlan = payload;
        },
        setModularSelectedAlaCarte(state, { payload }: IActionPayload<string[]>) {
            state.selectedAlaCarte = payload;
        },
        setModularPersonalEmail(state, { payload }: IActionPayload<string>) {
            state.personalEmail = payload;
        },
        setModularWalletBalance(state, { payload }: IActionPayload<number | undefined>) {
            state.balance = payload;
        },
        resetCrudFamily(state) {
            state.crudFamily.data = initialState.crudFamily.data;
            state.crudFamily.loading = initialState.crudFamily.loading;
            state.crudFamily.error = initialState.crudFamily.error;
        },
        resetCrudErrors(state) {
            state.crudFamily.error = initialState.crudFamily.error;
        }
    },
    extraReducers: {
        [fetchModularPlans.pending.toString()]: (state) => {
            state.basePlans.loading = true;
            state.basePlans.error = null;
        },
        [fetchModularPlans.fulfilled.toString()]: (state, { payload }: IActionPayload<IFetchModularPlansPayload>) => {
            state.basePlans.loading = false;
            state.basePlans.data = payload.plans;
            state.basePlans.error = null;
            if (payload.selectedPlanId) state.selectedBasePlan = payload.selectedPlanId;
        },
        [fetchModularPlans.rejected.toString()]: (state, { error }: IActionPayload) => {
            state.basePlans.loading = false;
            state.basePlans.data = null;
            state.basePlans.error = error;
        },
        [fetchModularSummary.pending.toString()]: (state) => {
            state.summary.loading = true;
            state.summary.error = null;
        },
        [fetchModularSummary.fulfilled.toString()]: (state, { payload }: IActionPayload<IModularEnrolmentSummary>) => {
            state.summary.loading = false;
            state.summary.data = payload;
            state.summary.error = null;
        },
        [fetchModularSummary.rejected.toString()]: (state, { error }: IActionPayload) => {
            state.summary.loading = false;
            state.summary.data = null;
            state.summary.error = error;
        },
        [fetchModularAlaCarteBenefits.pending.toString()]: (state) => {
            state.alaCarteBenefits.loading = true;
            state.alaCarteBenefits.error = null;
        },
        [fetchModularAlaCarteBenefits.fulfilled.toString()]: (
            state,
            { payload }: IActionPayload<IFetchModularAlaCartePayload>
        ) => {
            state.alaCarteBenefits.loading = false;
            state.alaCarteBenefits.data = payload.benefits;
            state.alaCarteBenefits.error = null;
            if (payload.selectedBenefits) state.selectedAlaCarte = payload.selectedBenefits;
            if (payload.personalEmail) state.personalEmail = payload.personalEmail;
        },
        [fetchModularAlaCarteBenefits.rejected.toString()]: (state, { error }: IActionPayload) => {
            state.alaCarteBenefits.loading = false;
            state.alaCarteBenefits.data = null;
            state.alaCarteBenefits.error = error;
        },
        [fetchModularFamily.pending.toString()]: (state) => {
            state.family.loading = true;
            state.family.error = null;
        },
        [fetchModularFamily.fulfilled.toString()]: (state, { payload }: IActionPayload) => {
            state.family.loading = false;
            state.family.data = payload;
            state.family.error = null;
        },
        [fetchModularFamily.rejected.toString()]: (state, { error }: IActionPayload) => {
            state.family.loading = false;
            state.family.data = null;
            state.family.error = error;
        },
        'common/cleanup': (state) => {
            resetToInitialState(state, initialState);
        }
    }
});
export default ModularEnrolmentSlice.reducer;
export {
    fetchModularPlans,
    fetchModularAlaCarteBenefits,
    updateBenefits,
    fetchModularFamily
};
export const {
    setModularPolicy,
    setModularCurrentStep,
    setModularWalletBalance,
    setModularSelectedBasePlan,
    setModularSelectedAlaCarte,
    setModularPersonalEmail,
    resetModularState,
    resetCrudFamily,
    resetCrudErrors
} = ModularEnrolmentSlice.actions;
