import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IPolicy } from '../../../../../../redux/slices/PolicyListSlice/types';
import { INTERNAL_ROUTES } from '../../../../../../utils/constants';
import {
    fetchModularAlaCarteBenefits,
    fetchModularPlans,
    setModularCurrentStep,
    setModularPolicy,
    setModularWalletBalance
} from '../../../../../../redux/slices/ModularEnrolmentSlice';
import { ModularEnrolmentSteps } from '../../../../../../redux/slices/ModularEnrolmentSlice/types';
import { setCurrentlySelectedPolicy } from '../../../../../../redux/slices/EnrolmentSlice';
import { ReduxState } from '../../../../../../redux';

const useEnrolmentNavigation = (): ((policy: IPolicy) => void) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId);

    return useCallback(
        (policy: IPolicy) => {
            const isModular = policy.policySecondaryType === 'MODULAR';
            if (isModular) {
                const policyId = policy.policyId;
                dispatch(setModularPolicy(policyId));
                dispatch(setModularWalletBalance(policy.walletAmount));
                dispatch(fetchModularPlans({ userId, policyId }));
                dispatch(setModularCurrentStep(ModularEnrolmentSteps.BASE_PLAN));
                dispatch(
                    fetchModularAlaCarteBenefits({ userId, policyId, benefitId: policy.benefits?.modular?.[0]?.id })
                );
            } else dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: policy }));
            history.push(isModular ? INTERNAL_ROUTES.modular : INTERNAL_ROUTES.enrolment, {
                previousUrl: location.pathname
            });
        },
        [dispatch]
    );
};

export default useEnrolmentNavigation;
