import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';

const ApplicationStateSlice = createSlice({
    name: 'applicationState',
    initialState: initialState,
    reducers: {
        addFTUShownUserIds: (state, action) => {
            state.ftuShownUserIds = (state.ftuShownUserIds ?? []).concat(action.payload);
        },
        addFTUShownUSerPolicyIds: (state, action) => {
            state.ftuShownUserPolicyIds = (state.ftuShownUserPolicyIds ?? []).concat(action.payload);
        }
    }
});

export const { addFTUShownUserIds, addFTUShownUSerPolicyIds } = ApplicationStateSlice.actions;

export default ApplicationStateSlice.reducer;
