import React from 'react';
import { IGenericIconProps } from './types';

const PlayStore: React.FC<IGenericIconProps> = ({ width = 24, height = 24, fill = '#7F8785' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
    >
        <path
            // eslint-disable-next-line max-len
            d="M12.7935 11.9204L15.944 8.72506L5.76808 2.92437C5.09366 2.5548 4.46186 2.50293 3.90784 2.90708L12.7935 11.9204ZM16.481 15.6615L19.7561 13.7931C20.3954 13.43 20.7459 12.9157 20.7459 12.3451C20.7459 11.7756 20.3954 11.2602 19.7572 10.8971L16.7921 9.20809L13.4541 12.5926L16.481 15.6615ZM3.36021 3.69268C3.29202 3.90556 3.25366 4.14329 3.25366 4.40372V20.294C3.25366 20.7057 3.34316 21.0602 3.5051 21.3422L12.1319 12.5915L3.36021 3.69268ZM12.7935 13.2615L4.21468 21.9636C4.37876 22.0273 4.55562 22.0608 4.74207 22.0608C5.07448 22.0608 5.42075 21.9614 5.77341 21.7626L15.634 16.1467L12.7935 13.2615Z"
            fill={fill}
        />
    </svg>
);

export default PlayStore;
