import styled from 'styled-components';
import { PolicyStatus } from './types';

export const StyledTagWrapper = styled.div<{
    $status: PolicyStatus;
}>`
    border: 1px solid ${(p) => (p.$status === 'PENDING' ? p.theme.colors.error : p.theme.colors.loopEmerald)};
    border-radius: 50px;
    width: fit-content;
`;

export const StyledTagTextContainer = styled.div`
    display: flex;
    gap: 4px;
`;
