/* eslint-disable quotes */
import styled from 'styled-components';

export const StyledContainer = styled.div<{
    $divBorderRadius: boolean;
}>`
    display: grid;
    grid-template:
        'stepStatus stepStatus'
        'insuranceHeader buttonWrapper'
        'defaultDivider defaultDivider'
        'detailsWrapper detailsWrapper'
        'enrolledMembersContainer enrolledMembersContainer';
    justify-content: space-between;
    border-radius: ${(p) => (p.$divBorderRadius ? '0px 0px 12px 12px' : '12px')};
    background-color: ${(p) => p.theme.colors.white};
    padding: 32px;
    gap: 12px;
    border: 1px solid ${(p) => p.theme.colors.seafoamGray};
    @media only screen and (max-width: 768px) {
        padding: 20px;
        justify-content: normal;
        grid-template:
            'stepStatus'
            'insuranceHeader'
            'defaultDivider'
            'detailsWrapper'
            'enrolledMembersContainer'
            'buttonWrapper';
    }
`;

export const StyledEnrolledMemberButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const StyledInsuranceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        padding: 0px;
    }
`;

export const StyledButtonContainer = styled.div<{
    $displayBorder?: boolean;
}>`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
    height: 48px;
    grid-area: buttonWrapper;
    @media only screen and (max-width: 768px) {
        height: auto;
        border-top: ${(p) => (p.$displayBorder ? `1px dashed ${p.theme.colors.seafoamGray}` : 'none')};
        padding-top: ${(p) => (p.$displayBorder ? '16px' : 'none')};
        margin-top: ${(p) => (p.$displayBorder ? '4px' : 'none')};
    }
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    width: 192px;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledEnrolButtonWrapper = styled.div`
    display: flex;
    width: 220px;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledContentWrapper = styled.div<{ $extendTemplate: boolean }>`
    display: grid;
    grid-template:
        'sumInsured coveredMembers enrolmentDate'
        ${(p) => (p.$extendTemplate ? "'selectedBenefits selectedBenefits selectedBenefits'" : '')};
    margin-bottom: 16px;
    gap: 48px;
    width: 100%;
    justify-content: flex-start;
    grid-area: detailsWrapper;
    @media only screen and (max-width: 768px) {
        width: 100%;
        gap: 20px;
        justify-content: space-between;
        margin: 0px 0px;
        grid-template:
            'sumInsured enrolmentDate'
            'coveredMembers coveredMembers'
            'selectedBenefits selectedBenefits';
    }
`;

export const StyleLoopTagWrapper = styled.div`
    grid-area: stepStatus;
    margin-bottom: 4px;
`;

export const StyledDetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-top: 16px;
    gap: 48px;
    border-top: 1px dashed ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledPremiumContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    align-items: center;
`;

export const StyledBreakdownContainer = styled.div`
    display: flex;
    gap: 4px;
    cursor: pointer;
`;

export const StyledChevron = styled.img`
    display: flex;
    width: 20px;
`;

export const StyledEnrolledMemberContainer = styled.div`
    grid-area: enrolledMembersContainer;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media only screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

export const StyledEnrolledMemberWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
        border-top: none;
        padding-top: 0px;
    }
`;

export const StyledEnrolledMemberContent = styled.div<{ $isCollapsed: boolean }>`
    display: ${(p) => (p.$isCollapsed ? 'flex' : 'none')};
    width: 100%;
    flex-direction: column;
    gap: 16px;
`;

export const StyledIcon = styled.img`
    display: flex;
    width: 18px;
`;

export const StyledArrowIcon = styled.img<{ $isCollapsed: boolean }>`
    display: flex;
    width: 20px;
    cursor: pointer;
    transform: rotate(${(p) => (p.$isCollapsed ? '180deg' : '0deg')});
`;

export const StyledEnrolledMemberCard = styled.div`
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: ${(p) => p.theme.colors.surface0};
    flex-direction: column;
    gap: 16px;
`;

export const StyledEnrolledMemberCardHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    text-transform: capitalize;
`;

export const StyledRelationContainer = styled.div`
    display: flex;
    padding: 3px 8px;
    background-color: ${(p) => p.theme.colors.white};
    border: 1px solid ${(p) => p.theme.colors.seafoamGray};
    border-radius: 8px;
    margin-left: 8px;
`;

export const StyledEnrolledMemberCardBody = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 110px;
    @media only screen and (max-width: 768px) {
        gap: 0px;
        justify-content: space-between;
    }
`;

export const StyledEnrolledMemberItem = styled.div`
    display: flex;
    gap: 8px;
    text-transform: capitalize;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledModalContainer = styled.div`
    display: flex;
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    flex-direction: column;
    gap: 20px;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledModalHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${(p) => p.theme.colors.white};
    gap: 16px;
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledTopUpContainer = styled.div<{
    $purchased: boolean;
}>`
    display: flex;
    padding: 12px 32px;
    align-items: center;
    background-color: ${(p) => (p.$purchased ? p.theme.colors.mutedTransparent : p.theme.colors.loopEmerald)};
    border-radius: 12px 12px 0px 0px;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        display: flex;
        margin-top: 4px;
        align-items: flex-start;
        gap: 4px;
    }
`;

export const StyledTopUpIcon = styled.img`
    display: flex;
    width: 32px;
`;

export const StyledCardDefaultDivider = styled.div<{
    $display: boolean;
}>`
    background: ${(p) => p.theme.colors.seafoamGray};
    margin: 16px 0px;
    width: 100%;
    height: 1px;
    grid-area: defaultDivider;
    display: ${(p) => (p.$display ? 'flex' : 'none')};
    @media only screen and (max-width: 768px) {
        display: flex;
        margin-top: 4px;
    }
`;

export const StyledAddTopUpBtn = styled.div`
    display: flex;
    width: 132px;
    @media only screen and (max-width: 768px) {
        margin-left: 30px;
    }
`;

export const StyledStripWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const StyledBenefitsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const StyledBenefits = styled.div`
    cursor: pointer;
`;

export const StyledSIValue = styled.div`
    display: flex;
    gap: 4px;`;
