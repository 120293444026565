import React from 'react';
import { Switch } from 'react-router-dom';
import { INTERNAL_ROUTES } from '../../../utils/constants';
import { InternalRouteGuard } from '../../../utils/RouteGuards';
import {
    DashboardPage,
    EnrolmentPage,
    SuperTopUpPage,
    WelcomePage,
    CheckoutSummaryPage,
    ModularEnrolmentPage,
    DownloadApp
} from './pages';
import ConfirmationPage from './pages/ConfirmationPage';
import ThresholdPage from './pages/ThresholdPage';

const InternalRouter: React.FunctionComponent = () => {
    return (
        <Switch>
            <InternalRouteGuard path={INTERNAL_ROUTES.welcome} Component={WelcomePage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.home} Component={DashboardPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.enrolment} Component={EnrolmentPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.modular} Component={ModularEnrolmentPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.confirm} Component={ConfirmationPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.threshold} Component={ThresholdPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.dashboard} Component={DashboardPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.summary} Component={CheckoutSummaryPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.topup} Component={SuperTopUpPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.downloadApp} Component={DownloadApp} exact />
        </Switch>
    );
};

export default InternalRouter;
