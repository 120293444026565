import { useDispatch, useSelector } from 'react-redux';
import {
    selectModularFamily,
    selectModularPlans,
    selectModularPolicyId,
    selectModularSelectedBasePlan
} from '../../../../redux/slices/ModularEnrolmentSlice/selectors';
import { ReduxState } from '../../../../redux';
import { familyDefinition, getDependentMappings, IMappings } from '../../../../utils/mapping';
import { useEffect, useState } from 'react';
import { fetchModularFamily, resetCrudErrors, resetCrudFamily } from '../../../../redux/slices/ModularEnrolmentSlice';
import { IFamilyToDisplay, IRelation } from '../types';
import { IGender, IModularFamily } from '../../../../redux/slices/ModularEnrolmentSlice/types';
import { capitalizeFirstLetter, formatDateString, replaceFamilyStructureText } from '../../../../utils/common';
import { IDropdownItem, IForm } from '../../ManageModularMember/types';
import { initialFormValues } from '../utils';
import { Toast } from '@loophealth/loop-ui-web-library';
import { deleteMember as deleteModularMember, editMember as editModularMember, addMember } from '../../../../adapters';
import useSegment from '../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../../utils/constants/SegmentActionConstants';

interface IAddDependents {
    currentMapping: IMappings | null;
    targetMapping: IMappings | null;
    getTitle: (index: number) => string;
    getMembersToDisplay: (category: string) => IModularFamily[];
    relations: string[];
    setSelectedParentOption: (value: string) => void;
    addMemberModal: boolean;
    setAddMemberModal: (value: boolean) => void;
    formValues: IForm;
    formErrors: IForm;
    onAddCardClick: (relationCategory: string) => void;
    handleFormChange: (e: React.ChangeEvent<HTMLInputElement> | Date | string, type: keyof IForm) => void;
    relationshipValues: IDropdownItem[];
    currentAction: IAction;
    editMemberModal: boolean;
    setEditMemberModal: (value: boolean) => void;
    onEditCardClick: (member: IModularFamily) => void;
    isLoading: boolean;
    onSaveDetails: () => void;
    crudLoading: boolean;
    setDeleteMemberModal: (value: boolean) => void;
    deleteMemberModal: boolean;
    onDeleteMember: () => void;
    onDeleteClick: (id: string, relationCategory: string) => void;
}

export enum IAction {
    'ADD' = 'Add',
    'EDIT' = 'Edit',
    'DELETE' = 'Delete'
}

export const useAddDependents = (): IAddDependents => {
    const dispatch = useDispatch();
    const trackClick = useSegment('click');
    const trackPage = useSegment('page');
    const loopToast = Toast.useToast();
    const selectedPolicyId = useSelector(selectModularPolicyId);
    const selectedModularPolicyId = useSelector(selectModularSelectedBasePlan);
    const policyList = useSelector(selectModularPlans).data;
    const selectedPolicy = policyList?.find((policy) => policy.id === selectedModularPolicyId);
    const familyStructure = selectedPolicy?.familyDefinition || '';
    const userData = useSelector((state: ReduxState) => state.user?.userData?.data);
    const currentFamily = useSelector(selectModularFamily).data;
    const [currentMapping, setCurrentMapping] = useState<IMappings | null>(null);
    const [targetMapping, setTargetMapping] = useState<IMappings | null>(null);
    const [familyToDisplay, setFamilyToDisplay] = useState<IFamilyToDisplay | null>(null);
    const [relations, setRelations] = useState<string[]>([]);
    const [selectedParentOption, setSelectedParentOption] = useState<string>('');
    const [selectedRelationship, setSelectedRelationship] = useState<IRelation>('');
    const [addMemberModal, setAddMemberModal] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<IForm>(initialFormValues);
    const [formErrors, setFormErrors] = useState<IForm>(initialFormValues);
    const [currentAction, setCurrentAction] = useState<IAction>(IAction.ADD);
    const [selectedRelationCategory, setSelectedRelationCategory] = useState<string>('');
    const [relationshipValues, setRelationshipValues] = useState<IDropdownItem[]>([]);
    const [editMemberModal, setEditMemberModal] = useState<boolean>(false);
    const isLoading = useSelector(selectModularFamily).loading;
    const [crudLoading, setCrudLoading] = useState<boolean>(false);
    const [editMember, setEditMember] = useState<IModularFamily | null>(null);
    const [deleteMemberModal, setDeleteMemberModal] = useState<boolean>(false);
    const [deleteMember, setDeleteMember] = useState<string>('');

    useEffect(() => {
        if (userData.userId && selectedModularPolicyId) {
            dispatch(fetchModularFamily({ userId: userData.userId, policyId: selectedPolicyId }));
            trackPage(SEGMENT_ACTIONS.PAGE.ADD_DEPENDENT_PAGE);
        }
    }, [userData.userId, selectedModularPolicyId]);

    useEffect(() => {
        let dependent: IFamilyToDisplay = {};
        const spouseSelf = ['self', 'spouse'];
        const children = currentFamily?.filter((rel) => rel.relationship.toLowerCase() === 'Child'.toLowerCase());
        const selfSpouse = currentFamily
            ?.filter((rel) => spouseSelf.includes(rel.relationship.toLowerCase()))
            .sort((a, b) => {
                if (a.relationship.toLowerCase() === 'self') return -1;
                if (b.relationship.toLowerCase() === 'self') return 1;
                return 0;
            });
        const parents = currentFamily?.filter((rel) => ['parent'].includes(rel.relationship.toLowerCase()));
        const parentsInLaw = currentFamily?.filter((rel) => ['parent-in-law'].includes(rel.relationship.toLowerCase()));
        dependent = { selfSpouse, children, parents, parentsInLaw };
        setFamilyToDisplay(dependent);

        setTargetMapping(familyDefinition[familyStructure]);
        const dependentTypes = familyStructure.split(' + ');
        setRelations(dependentTypes);

        currentFamily && setCurrentMapping(getDependentMappings(currentFamily, familyStructure));
    }, [currentFamily, familyStructure]);

    // handle relationship dropdown population
    useEffect((): void => {
        const relationships: IDropdownItem[] = [];
        if (selectedRelationCategory === 'Parents (including in-laws)') {
            relationships.push({ name: 'Parent', value: 'parent' }, { name: 'Parent-in-law', value: 'parent-in-law' });
            setRelationshipValues(relationships);
        } else {
            relationships.push({ name: selectedRelationship, value: selectedRelationship.toLowerCase() });
            setRelationshipValues(relationships);
            setFormValues({ ...formValues, relationship: selectedRelationship.toLowerCase() });
        }
    }, [selectedRelationCategory, selectedRelationship]);

    useEffect(() => {
        if (!isLoading && currentFamily?.length) {
            if (addMemberModal || editMemberModal || deleteMemberModal) {
                setAddMemberModal(false);
                setEditMemberModal(false);
                setDeleteMemberModal(false);
                setFormErrors(initialFormValues);
                loopToast?.success(
                    `${capitalizeFirstLetter(selectedRelationship)} ${
                        currentAction === IAction.ADD
                            ? 'added'
                            : currentAction === IAction.EDIT
                                ? 'details edited'
                                : 'removed'
                    } successfully`
                );
            }
        }
    }, [isLoading]);

    useEffect(() => {
        if (!addMemberModal && !editMemberModal && !deleteMemberModal) {
            dispatch(resetCrudErrors());
        }
    }, [addMemberModal, editMemberModal, deleteMemberModal]);

    const getTitle = (index: number): string => {
        let title: string;
        if (relations.length === 1) {
            title = 'Your details';
        } else if (relations.length === 2 || index === 1) {
            // For Self + Spouse title
            title = `You and Your Spouse`;
        } else if (relations.length > 4 && index === 3) {
            // For Parents + Parents-in-law title
            title = `${relations[3]} + ${relations[4]}`;
        } else if (index === 2) {
            // For Children
            title = `${targetMapping?.dependents?.[index - 1].number} Children`;
        } else {
            // For Parents
            title = targetMapping?.dependents?.[index - 1]?.number
                ? targetMapping?.dependents?.[index - 1]?.number + ' ' + relations[index]
                : relations[index];
        }
        return replaceFamilyStructureText(title);
    };

    const getMembersToDisplay = (category: string): IModularFamily[] => {
        const children = ['1 Kid', '2 Kids', '3 Kids', '4 Kids'];
        const oneOfParentsOrInLawsOptions = ['Parents OR parents-in-law', 'Parents OR parent-in-laws'];
        const anyOfParentsOrInLawsOptions = ['Parents (including in-laws)'];

        if (category === 'Spouse' || category === 'Self') return familyToDisplay?.selfSpouse || [];
        else if (children.includes(category)) return familyToDisplay?.children || [];
        else if (category === 'Parents' || category === 'Parents Only') return familyToDisplay?.parents || [];
        else if (category === 'Parents-in-law') return familyToDisplay?.parentsInLaw || [];
        else if (oneOfParentsOrInLawsOptions.includes(category)) {
            if (familyToDisplay?.parents && familyToDisplay?.parents?.length > 0) {
                return familyToDisplay?.parents || [];
            }
            return familyToDisplay?.parentsInLaw || [];
        } else if (anyOfParentsOrInLawsOptions.includes(category)) {
            return [...(familyToDisplay?.parents ?? []), ...(familyToDisplay?.parentsInLaw ?? [])];
        } else return [];
    };

    const getRelation = (relationship: string): IRelation => {
        switch (relationship) {
        case 'Spouse':
            return 'Spouse';
        case '1 Kid':
        case '2 Kids':
        case '3 Kids':
        case '4 Kids':
            return 'Child';
        case 'Parents':
        case 'Parents Only':
            return 'Parent';
        case 'Parent-in-laws':
        case 'Parents-in-law':
            return 'Parent-in-law';
        case 'Parents OR parent-in-laws':
            return selectedParentOption === 'Parent' ? 'Parent' : 'Parent-in-law';
        default:
            return relationship as IRelation;
        }
    };

    const onAddCardClick = (relationCategory: string) => {
        const clickAction = SEGMENT_ACTIONS.CLICK.ADD_DEPENDENT;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                dependent_type: getRelation(relationCategory),
                eligible_members: familyStructure
            }
        });
        setSelectedRelationship(getRelation(relationCategory));
        dispatch(resetCrudFamily());
        setFormValues(initialFormValues);
        setFormErrors(initialFormValues);
        setCurrentAction(IAction.ADD);
        setSelectedRelationCategory('');
        setTimeout(() => {
            setSelectedRelationCategory(relationCategory);
        }, 0);
        setAddMemberModal(true);
    };

    const onEditCardClick = (member: IModularFamily) => {
        dispatch(resetCrudFamily());
        setEditMember(member);
        setCurrentAction(IAction.EDIT);
        setEditMemberModal(true);
        setSelectedRelationship(member.relationship as IRelation);
        setFormValues({
            firstName: member.firstName,
            lastName: member.lastName,
            dob: member.dob,
            relationship: member.relationship,
            gender: member.gender,
            mobile: member.mobile
        });
        setFormErrors(initialFormValues);
        setSelectedRelationCategory(member.relationship);
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement> | Date | string, type: keyof IForm) => {
        switch (type) {
        case 'firstName':
        case 'lastName':
        case 'mobile':
            const value = (e as React.ChangeEvent<HTMLInputElement>).target.value;
            setFormValues({ ...formValues, [type]: value });
            setFormErrors({ ...formErrors, [type]: '' });
            break;
        case 'dob':
            const date = new Date(e as Date).toString();
            setFormValues({ ...formValues, dob: date });
            setFormErrors({ ...formErrors, dob: '' });
            break;
        case 'relationship':
        case 'gender':
            const data = e as string;
            setFormValues({ ...formValues, [type]: data });
            setFormErrors({ ...formErrors, [type]: '' });
            break;
        }
    };

    const isFormValid = (): boolean => {
        const errors: IForm = { ...initialFormValues };
        if (!formValues.firstName) errors.firstName = 'First name is required';
        if (!formValues.lastName) errors.lastName = 'Last name is required';
        if (!formValues.dob) errors.dob = 'Date of birth is required';
        if (!formValues.relationship) errors.relationship = 'Relationship is required';
        if (!formValues.gender) errors.gender = 'Gender is required';
        if (formValues.mobile && formValues.mobile.length !== 10) errors.mobile = 'Enter valid mobile number';
        if (Object.values(errors).some((value) => typeof value === 'string' && value.trim() !== '')) {
            const pageAction = SEGMENT_ACTIONS.PAGE.ERROR;
            trackPage({
                name: pageAction.name,
                properties: {
                    ...pageAction.properties,
                    ...errors
                }
            });
            setFormErrors(errors);
            return false;
        }
        return true;
    };

    const onSaveDetails = async () => {
        if (crudLoading || !isFormValid()) return;

        try {
            const memberDetails = {
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                dob: formatDateString(formValues.dob),
                relationship: formValues.relationship,
                mobile: formValues.mobile,
                gender: formValues.gender as IGender,
                employer: userData.employer || '',
                employeeId: userData.employeeId || ''
            };
            const clickAction = SEGMENT_ACTIONS.CLICK.SAVE_DETAILS;
            trackClick({
                name: clickAction.name,
                properties: {
                    ...clickAction.properties,
                    ...memberDetails
                }
            });
            setCrudLoading(true);
            if (currentAction === IAction.ADD) {
                await addMember(userData.userId, selectedPolicyId, memberDetails);
                setCrudLoading(false);
            } else {
                await editModularMember(
                    userData.userId,
                    selectedPolicyId,
                    editMember?.id || '',
                    memberDetails
                );
                setCrudLoading(false);
            }
            dispatch(fetchModularFamily({ userId: userData.userId, policyId: selectedPolicyId }));
        } catch (error) {
            const err = JSON.parse((error as Error).message);
            const errors: IForm = { ...initialFormValues };
            Object.keys(err).forEach((key) => {
                if (key in errors) errors[key as keyof IForm] = err?.[key];
                else if (key === 'other') loopToast?.error(err?.[key]);
            });
            const pageAction = SEGMENT_ACTIONS.PAGE.ERROR;
            trackPage({
                name: pageAction.name,
                properties: {
                    ...pageAction.properties,
                    ...errors
                }
            });
            setFormErrors(errors);
            setCrudLoading(false);
        }
    };

    const onDeleteClick = (id: string, relationCategory: string) => {
        setSelectedRelationship(getRelation(relationCategory));
        setCurrentAction(IAction.DELETE);
        dispatch(resetCrudFamily());
        setDeleteMember(id);
        setDeleteMemberModal(true);
    };

    const onDeleteMember = async () => {
        if (crudLoading) return;
        try {
            setCrudLoading(true);
            const response = await deleteModularMember(userData.userId, selectedPolicyId, deleteMember);
            dispatch(fetchModularFamily({ userId: userData.userId, policyId: selectedPolicyId || '' }));
            if (response.message) {
                loopToast?.success(`${capitalizeFirstLetter(selectedRelationship)} removed successfully`);
            }
            setDeleteMemberModal(false);
            setCrudLoading(false);
        } catch (error) {
            const err = JSON.parse((error as Error).message);
            loopToast?.error(err.message);
            setDeleteMemberModal(false);
            setCrudLoading(false);
        }
    };

    return {
        currentMapping,
        getTitle,
        getMembersToDisplay,
        relations,
        targetMapping,
        setSelectedParentOption,
        addMemberModal,
        setAddMemberModal,
        formValues,
        formErrors,
        onAddCardClick,
        handleFormChange,
        relationshipValues,
        currentAction,
        editMemberModal,
        setEditMemberModal,
        onEditCardClick,
        isLoading,
        onSaveDetails,
        crudLoading,
        setDeleteMemberModal,
        deleteMemberModal,
        onDeleteMember,
        onDeleteClick
    };
};
