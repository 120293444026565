import * as Sentry from '@sentry/react';
import { store } from '../../redux';

const initializeSentry = (): void => {
    if (process.env.REACT_APP_ENV === 'production' && !!process.env.REACT_APP_SENTRY_URL) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_URL,
            integrations: [Sentry.browserTracingIntegration()],
            tracesSampleRate: 1.0,
            environment: process.env.REACT_APP_ENV,
            // having localhost won't harm in production, it will only support local development
            tracePropagationTargets: ['localhost', process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL as string],
            ignoreErrors: [
                'Non-Error promise rejection captured',
                'remoteconfig/fetch-client-network',
                'remoteconfig/storage-open'
            ]
        });
    }
};

const captureSentryException = (message: string, error: unknown, extra?: Record<string, unknown>): Promise<void> =>
    new Promise((res) => {
        let tags = {};
        try {
            const state = store.getState();
            const user = state.user?.userData?.data;
            tags = {
                userId: user?.userId,
                email: user?.email,
                companyId: user?.employer,
                mobile: user?.mobile
            };
        } catch (e) {}
        Sentry.captureException(new Error(message, { cause: error }), { tags, extra });
        res();
    });

export { initializeSentry, captureSentryException };
