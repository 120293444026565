import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoDefaultIcon } from '../../../assets/img';
import { setModularCurrentStep } from '../../../redux/slices/ModularEnrolmentSlice';
import {
    selectModularCurrentStep,
    selectModularSelectedBasePlan
} from '../../../redux/slices/ModularEnrolmentSlice/selectors';
import { ModularEnrolmentSteps } from '../../../redux/slices/ModularEnrolmentSlice/types';
import { formatAmount } from '../../../utils/common';
import { StepperStepTitles } from '../../../utils/constants/ModularConstants';
import PageStepper from '../PageStepper';
import CostPremiumModal from '../PremiumBreakdownModal';
import useModularEnrolment from './hooks/useModularEnrolment';
import usePremiumBreakdown from './hooks/usePremiumBreakdown';
import PlanChangeModal from './PlanChangeModal';
import {
    StyledFooterBackButtonWrapper,
    StyledFooterInfoIcon,
    StyledModularEnrolmentFooter,
    StyledModularFooterCTA,
    StyledModularFooterCTAButton,
    StyledModularFooterLeft,
    StyledModularPremiumAmount
} from './styles';
import SubmitConfirmationModal from './SubmitConfirmationModal';
import MemberConfirmationModal from './MemberConfirmationModal';
import { Prompt } from 'react-router-dom';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const ModularEnrolmentFooter: React.FC = () => {
    const dispatch = useDispatch();
    const trackClick = useSegment('click');
    const currentStep = useSelector(selectModularCurrentStep);
    const selectedBasePlan = useSelector(selectModularSelectedBasePlan);

    const [costModalVisible, setCostModalVisible] = React.useState(false);

    const showPremiumAmount = currentStep !== ModularEnrolmentSteps.SUMMARY && selectedBasePlan;
    const showBackButton = currentStep !== ModularEnrolmentSteps.BASE_PLAN;

    const {
        isProceedEnabled,
        isSubmitting,
        isUpdating,
        isProcessing,
        submitModalVisible,
        confirmDeletions,
        confirmMemberModalVisible,
        setConfirmMemberModalVisible,
        onModularPlanNext,
        onDependentsNext,
        onAlaCarteBenefitsNext,
        handleOnSubmit,
        handleOnConfirmUpdation,
        setSubmitModalVisible,
        setConfirmDeletions,
        membersAdded,
        onConfirmMembers
    } = useModularEnrolment();

    const { modular, alacarte, priceSummary } = usePremiumBreakdown();
    const isWalletUsed = priceSummary.walletDeducted > 0;

    const onProceedClick = () => {
        const clickAction = SEGMENT_ACTIONS.CLICK.PROCEED;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                page_name: ModularEnrolmentSteps[currentStep],
                page_category: ModularEnrolmentSteps[currentStep]
            }
        });
        if (isProcessing) return;
        switch (currentStep) {
        case ModularEnrolmentSteps.BASE_PLAN:
            return onModularPlanNext();
        case ModularEnrolmentSteps.DEPENDENTS:
            return onDependentsNext();
        case ModularEnrolmentSteps.HEALTHCARE_BENEFITS:
            return onAlaCarteBenefitsNext();
        case ModularEnrolmentSteps.SUMMARY:
            return setSubmitModalVisible(true);
        }
    };

    const handleBackClick = () => {
        const clickAction = SEGMENT_ACTIONS.CLICK.BACK_BUTTON;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                page_name: ModularEnrolmentSteps[currentStep],
                page_category: ModularEnrolmentSteps[currentStep]
            }
        });
        dispatch(setModularCurrentStep(currentStep - 1));
    };

    const onInfoIconClick = () => setCostModalVisible(true);

    const isInProgress = !submitModalVisible;

    useEffect(() => {
        if (isInProgress) {
            const beforeUnloadCallback = async (event: BeforeUnloadEvent) => {
                event.preventDefault();
                return true;
            };
            window.addEventListener('beforeunload', beforeUnloadCallback);
            return () => {
                window.removeEventListener('beforeunload', beforeUnloadCallback);
            };
        }
    }, [isInProgress]);

    return (
        <StyledModularEnrolmentFooter>
            <Prompt when={isInProgress} message="Wait! Are you sure you want to leave?" />
            <StyledModularFooterLeft>
                {showBackButton && (
                    <StyledFooterBackButtonWrapper>
                        <LoopButton variant="secondary" onClick={handleBackClick}>
                            Back
                        </LoopButton>
                    </StyledFooterBackButtonWrapper>
                )}
                <PageStepper totalSteps={4} currentStep={currentStep} text={StepperStepTitles[currentStep]} />
            </StyledModularFooterLeft>
            <StyledModularFooterCTA>
                {showPremiumAmount && (
                    <StyledModularPremiumAmount>
                        <Typography variant="small">To Pay:</Typography>
                        {isWalletUsed && (
                            <Typography variant="medium" weight="medium">
                                <s>{formatAmount(priceSummary.totalPremium)}</s>
                            </Typography>
                        )}
                        <Typography variant="large" color="emerald" weight="semiBold">
                            {formatAmount(isWalletUsed ? priceSummary.salaryDeducted : priceSummary.totalPremium)}
                        </Typography>
                        <StyledFooterInfoIcon src={InfoDefaultIcon} onClick={onInfoIconClick} />
                    </StyledModularPremiumAmount>
                )}
                <StyledModularFooterCTAButton>
                    <LoopButton
                        variant={isProceedEnabled ? 'filled' : 'disabled'}
                        onClick={onProceedClick}
                        isLoading={isProcessing}
                    >
                        Proceed
                    </LoopButton>
                </StyledModularFooterCTAButton>
            </StyledModularFooterCTA>
            <CostPremiumModal
                title="Premium Breakdown"
                isVisible={costModalVisible}
                setIsVisible={setCostModalVisible}
                alacarte={alacarte}
                modular={modular}
                priceSummary={priceSummary}
                taxExcluded
            />
            <SubmitConfirmationModal
                isVisible={submitModalVisible}
                setIsVisible={setSubmitModalVisible}
                onSubmit={handleOnSubmit}
                isLoading={isSubmitting}
            />
            <PlanChangeModal
                isVisible={!!confirmDeletions}
                onClose={() => setConfirmDeletions(null)}
                deletions={confirmDeletions}
                onConfirm={handleOnConfirmUpdation}
                isUpdating={isUpdating}
            />
            <MemberConfirmationModal
                isVisible={confirmMemberModalVisible}
                setIsVisible={setConfirmMemberModalVisible}
                onSubmit={onConfirmMembers}
                totalMembers={membersAdded().totalMembers}
                addedMembers={membersAdded().addedMembers}
            />
        </StyledModularEnrolmentFooter>
    );
};

export default ModularEnrolmentFooter;
