import { IModule, IModuleProperty } from '../../../redux/slices/PolicyListSlice/types';

export const getSortedBasePlanProperties = (plan?: IModule, filtered?: boolean, limit?: number): IModuleProperty[] => {
    const sortedProperties = structuredClone(plan?.properties)
        ?.filter((property: IModuleProperty) => !filtered || property.isCovered)
        .sort((a: IModuleProperty, b: IModuleProperty) => (a.priority ?? 200) - (b.priority ?? 200))
        .slice(0, limit || plan?.properties.length);
    return sortedProperties || [];
};

export const getMaxPropertiesToShow = (plans: IModule[]): number => {
    return Math.min(Math.max(...plans.map((p) => p.properties.filter((p) => p.isCovered).length)), 10); // max 10
};
