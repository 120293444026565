import React from 'react';
import { StyledContainer, StyledImage, StyledText, StyledButton } from './styles';
import { AddMemberIcon } from '../../../assets/img';
import { IAddMemberCard } from './types';
import { IRelationship } from '../EnrolDependents/types';

const AddMemberCard: React.FunctionComponent<IAddMemberCard> = ({ onClick, relationship }) => {
    return (
        <StyledContainer
            data-automationid={`add-${relationship}-card`}
            onClick={() => onClick(relationship as IRelationship)}
        >
            <StyledButton>
                <StyledImage src={AddMemberIcon} />
                <StyledText>
                    Add {relationship} {!(relationship.toLowerCase() === 'spouse')}
                </StyledText>
            </StyledButton>
        </StyledContainer>
    );
};

export default AddMemberCard;
