import React, { useState } from 'react';
import { IPersonalDetailsModal } from './type';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
    StyledButton,
    StyledEmailContainer,
    StyledEmailContent,
    StyledEmailFooter,
    StyledEmailInput,
    StyledEmailTitle
} from './styles';
import { Form, LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { ImageView } from '../../atoms/ImageView';
import { EmailIcon } from '../../../assets/img';
import { validateEmail } from '../../../utils/common';

const PersonalEmailModal: React.FunctionComponent<IPersonalDetailsModal> = ({
    isVisible,
    setIsVisible,
    personalEmail,
    handleSubmitEmail
}) => {
    const [email, setEmail] = useState<string>(personalEmail ?? '');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showError, setShowError] = useState(false);

    const handleSubmit = () => {
        if (email.trim().length === 0) {
            setShowError(true);
            setErrorMessage('Email ID is mandatory');
            return;
        } else if (!validateEmail(email)) {
            setShowError(true);
            setErrorMessage('Enter a valid email ID');
            return;
        } else {
            handleSubmitEmail(email);
            setIsVisible(false);
        }
    };

    const handleFocus = () => {
        if (showError) setShowError(false);
    };

    const isSaveDisabled = email.trim().length === 0;

    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} disablePadding={true}>
            <WithThemeProvider>
                <StyledEmailContainer>
                    <StyledEmailContent>
                        <ImageView src={EmailIcon} width={'116px'} height={'116px'} />
                        <StyledEmailTitle>
                            <Typography variant="large" weight="semiBold">
                                Add Your Personal Email
                            </Typography>
                            <Typography variant={'small'} color={'secondary'}>
                                We’ll register your benefits to this email so you can access them anytime — even after
                                you leave the company.
                            </Typography>
                        </StyledEmailTitle>
                        <StyledEmailInput>
                            <Typography variant="small" weight="semiBold">
                                {'Personal Email ID*'}
                            </Typography>
                            <Form.Input
                                type="email"
                                placeholder="Enter your email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                onFocus={handleFocus}
                                error={showError ? errorMessage : ''}
                            />
                        </StyledEmailInput>
                    </StyledEmailContent>
                    <StyledEmailFooter>
                        <StyledButton>
                            <LoopButton
                                size={'small'}
                                onClick={handleSubmit}
                                variant={isSaveDisabled ? 'disabled' : 'filled'}
                            >
                                {'Save Details'}
                            </LoopButton>
                        </StyledButton>
                    </StyledEmailFooter>
                </StyledEmailContainer>
            </WithThemeProvider>
        </Modal>
    );
};

export default PersonalEmailModal;
