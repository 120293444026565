import React, { useEffect, useMemo, useState } from 'react';
import { StyledBenefitParentContainer, StyledCardContainer } from './styles';
import BenefitDetailsModal from '../BenefitDetailsModal';
import BenefitCard from './BenefitCard';
import ModularEnrolmentHeader from '../ModularEnrolmentHeader';
import {
    fetchModularAlaCarteBenefits,
    setModularPersonalEmail,
    setModularSelectedAlaCarte
} from '../../../redux/slices/ModularEnrolmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectedAlaCarteBenefits,
    selectModularAlaCarteBenefits,
    selectModularPersonalEmail,
    selectModularPolicyId,
    selectModularSelectedBasePlan
} from '../../../redux/slices/ModularEnrolmentSlice/selectors';
import { Loader } from '../../atoms';
import { Alerts, Typography } from '@loophealth/loop-ui-web-library';
import { IAlacarte } from '../../../redux/slices/PolicyListSlice/types';
import PersonalEmailModal from '../BenefitDetailsModal/PersonalEmailModal';
import { EmailInfo } from './EmailInfo';
import { ReduxState } from '../../../redux';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const AlaCarteBenefits: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const trackClick = useSegment('click');
    const trackPage = useSegment('page');
    const alaCarteBenefits = useSelector(selectModularAlaCarteBenefits);
    const selectedBasePlan = useSelector(selectModularSelectedBasePlan);
    const selectedBenefits = useSelector(selectedAlaCarteBenefits);
    const personalEmail = useSelector(selectModularPersonalEmail);
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId || '');
    const policyId = useSelector(selectModularPolicyId);

    const setSelectedBenefits = (benefitIds: string[]) => {
        dispatch(setModularSelectedAlaCarte(benefitIds));
    };

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [focusedPlan, setFocusedPlan] = useState<IAlacarte>();
    const [benefitIdToUpdate, setBenefitIdToUpdate] = useState('');

    const isLoading = alaCarteBenefits.loading;
    const errorMessage = alaCarteBenefits.error?.message;

    const benefits = alaCarteBenefits.data ?? [];

    const isLoopBenefit = (benefit: IAlacarte) => {
        return benefit?.offeredBy?.toLowerCase() === 'loop';
    };

    const handleReadMore = (plan: IAlacarte) => {
        const clickAction = SEGMENT_ACTIONS.CLICK.READ_MORE;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                benefit_name: plan.name,
                benefit_id: plan.id
            }
        });
        setFocusedPlan(plan);
        setShowDetailsModal(true);
    };

    const handleUpdate = (prev: string, curr: string) => {
        const tempBenefits = selectedBenefits.filter((benefitId) => benefitId !== prev);
        setSelectedBenefits([...tempBenefits, curr]);
    };

    const handleSelect = (id: string) => {
        if (selectedBenefits.includes(id)) return;
        setSelectedBenefits([...selectedBenefits, id]);
    };

    const handleToggleEmailModal = () => {
        setShowEmailModal(!showEmailModal);
    };

    const handleRemove = (id: string) => {
        const updatedPlans = selectedBenefits.filter((benefitId) => benefitId !== id);
        setSelectedBenefits(updatedPlans);
    };

    const handleShowEmailModal = (plan: IAlacarte, id?: string) => {
        if (!personalEmail && isLoopBenefit(plan)) {
            setShowEmailModal(true);
            if (id) {
                setBenefitIdToUpdate(id);
            }
        }
    };

    const nonInsuranceBenefitNames = useMemo(() => {
        return benefits
            ?.filter(
                (benefit) =>
                    (selectedBenefits.includes(benefit?.id) ||
                        (benefit.subCategories?.length
                            ? benefit.subCategories.find((b) => selectedBenefits.includes(b.id))
                            : false)) &&
                    isLoopBenefit(benefit)
            )
            ?.map((benefit) => benefit?.name);
    }, [selectedBenefits]);

    const handleSubmitEmail = (email: string) => {
        dispatch(setModularPersonalEmail(email));
        if (benefitIdToUpdate) handleSelect(benefitIdToUpdate);
    };

    useEffect(() => {
        trackPage(SEGMENT_ACTIONS.PAGE.INSURANCE_BENEFITS_PAGE);
        dispatch(
            fetchModularAlaCarteBenefits({
                userId,
                policyId,
                benefitId: selectedBasePlan
            })
        );
    }, []);

    return (
        <StyledBenefitParentContainer>
            <ModularEnrolmentHeader
                title={'Add more to your policy'}
                subtitle={'Choose from various add-ons tailored for your needs'}
            />
            {nonInsuranceBenefitNames?.length > 0 && personalEmail && (
                <EmailInfo
                    email={personalEmail}
                    benefits={nonInsuranceBenefitNames}
                    handleShowEmailInfo={handleToggleEmailModal}
                />
            )}

            {isLoading ? (
                <Loader />
            ) : errorMessage ? (
                <Alerts.StatusAlert
                    variant="over"
                    text={
                        <Typography variant="medium" color="error">
                            {errorMessage}
                        </Typography>
                    }
                    alignItems="center"
                />
            ) : (
                <StyledCardContainer>
                    {benefits?.map((plan) => (
                        <BenefitCard
                            key={plan.id}
                            plan={plan}
                            selectedBenefits={selectedBenefits}
                            handleReadMore={(plan) => handleReadMore(plan)}
                            handleSelectPlan={handleSelect}
                            handleRemovePlan={handleRemove}
                            handleUpdate={handleUpdate}
                            handleToggleEmailModal={handleShowEmailModal}
                            isLoopBenefit={isLoopBenefit}
                            personalEmail={personalEmail}
                        />
                    ))}
                    <BenefitDetailsModal
                        benefit={focusedPlan}
                        isVisible={showDetailsModal}
                        setIsVisible={setShowDetailsModal}
                    />
                    <PersonalEmailModal
                        isVisible={showEmailModal}
                        setIsVisible={setShowEmailModal}
                        personalEmail={personalEmail}
                        handleSubmitEmail={handleSubmitEmail}
                    />
                </StyledCardContainer>
            )}
        </StyledBenefitParentContainer>
    );
};

export default AlaCarteBenefits;
