import React, { useEffect } from 'react';
import { ISubmitConfirmationModal } from './types';
import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { PeopleHiveFiveGraphic } from '../../../assets/img';
import {
    StyledSubmitConfirmationModal,
    StyledSubmitConfirmationModalBody,
    StyledSubmitConfirmationModalContent,
    StyledSubmitConfirmationModalFooter
} from './styles';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const SubmitConfirmationModal: React.FC<ISubmitConfirmationModal> = ({
    isVisible,
    setIsVisible,
    onSubmit,
    isLoading
}) => {
    const trackPage = useSegment('page');
    const handleClose = () => {
        if (isLoading) return;
        setIsVisible(false);
    };

    useEffect(() => {
        if (isVisible) {
            trackPage(SEGMENT_ACTIONS.PAGE.REVIEW_REGISTRATION_MODAL);
        }
    }, [isVisible]);

    return (
        <Modal isVisible={isVisible} setIsVisible={handleClose} disablePadding>
            <WithThemeProvider>
                <StyledSubmitConfirmationModal>
                    <img src={PeopleHiveFiveGraphic} alt="People Hive Five Graphic" />
                    <StyledSubmitConfirmationModalBody>
                        <Typography variant="title2" weight="medium">
                            Final Check Before You Register!
                        </Typography>
                        <StyledSubmitConfirmationModalContent>
                            <Typography variant="medium" weight="medium">
                                Before we wrap things up, take a moment to review your registration details.
                            </Typography>
                            <Typography variant="small" color="secondary">
                                Ready to complete your registration?
                            </Typography>
                        </StyledSubmitConfirmationModalContent>
                    </StyledSubmitConfirmationModalBody>
                    <StyledSubmitConfirmationModalFooter>
                        <LoopButton variant="filled" onClick={onSubmit} isLoading={isLoading}>
                            Complete My Registration
                        </LoopButton>
                    </StyledSubmitConfirmationModalFooter>
                </StyledSubmitConfirmationModal>
            </WithThemeProvider>
        </Modal>
    );
};

export default SubmitConfirmationModal;
