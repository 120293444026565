import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginPage, OTPPage } from './components/pages';
import firebase from 'firebase';
import { ILoginContainer } from './types';
import { StyledContainer, StyledPanelContainer, StyledContentContainer } from './styles';
import { checkUserAccess, getUserDetails } from './redux/slices/UserSlice';
import { ReduxState } from '../../../redux';
import useToken from './utils/hooks/useToken';
import { ErrorContainer } from '../../atoms';
import { Loader } from './components/atoms';
import useSegment from '../../../utils/hooks/useSegment';

declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
        confirmationResult: firebase.auth.ConfirmationResult | null;
        analytics: any;
        plotline: (...args: (string | Record<string, string>)[]) => void;
    }
}
const LoginContainer: React.FunctionComponent<ILoginContainer> = ({
    PanelComponent,
    title,
    subTitle,
    authTokenName,
    redirectTo
}) => {
    const dispatch = useDispatch();
    const authToken = useToken(authTokenName);
    const userMobile = useSelector((state: ReduxState) => state.user.userMobile);
    const userData = useSelector((state: any) => state.user.userData);
    const authenticationError = useSelector((state: any) => state.user.isUserAuthenticated.error);
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [displayLogin, setDisplayLogin] = React.useState(true);
    const [displayOTP, setDisplayOTP] = React.useState(false);
    const trackLogin = useSegment('identify');

    // check for persistance
    React.useEffect(() => {
        if (firebase) {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    // User is signed in.
                    if (!userData.data) {
                        const email = user.email;
                        const mobile = user.phoneNumber;
                        if (email) {
                            setIsPersistanceLoading(true);
                            dispatch(getUserDetails({ key: 'email', value: email }));
                        } else if (mobile) {
                            setIsPersistanceLoading(true);
                            dispatch(getUserDetails({ key: 'mobile', value: mobile.slice(-10) }));
                        }
                    }
                }
            });
        }
        return () => {
            setIsPersistanceLoading(false);
        };
    }, [firebase]);

    // Remove the loading screen when an error is received and display the error
    React.useEffect(() => {
        if (userData.error) {
            setIsPersistanceLoading(false);
        }
    }, [userData.error]);

    // trigger when user has entered mobile number
    // when user enters mobile number, userMobile is set in redux state, thus trigerring below useEffect
    React.useEffect(() => {
        // if user has entered mobile number, redirect to OTP page
        if (window.confirmationResult && userMobile.data?.length === 10) {
            if (!displayOTP) {
                setDisplayLogin(false);
                setDisplayOTP(true);
            }
        }
    }, [window.confirmationResult, userMobile, history]);

    // trigger only if we have aquired userData from firestore
    // 1. When OTP verification is successful
    // 2. When OAuth Login is successful
    React.useEffect(() => {
        if (isPersistanceLoading && userData?.data) {
            const mobile = userData.data.mobile;
            const email = userData.data.email;
            if (mobile || email) {
                authToken
                    .generateFirebaseToken()
                    .then(() => {
                        dispatch(
                            checkUserAccess({
                                redirectFunction: redirectTo,
                                roles: userData.data.roles,
                                setIsLoading: setIsPersistanceLoading,
                                trackLogin,
                                mobile,
                                email
                            })
                        );
                    })
                    .catch(() => {
                        setError('Unable to login user at this time. Please refresh the page and try again.');
                    });
            }
        }
    }, [history, userData.data?.userId]);

    const showLoginPage = () => {
        setDisplayLogin(true);
        setDisplayOTP(false);
    };
    return (
        <StyledContainer>
            <StyledPanelContainer>
                <PanelComponent />
            </StyledPanelContainer>
            <StyledContentContainer>
                {isPersistanceLoading || userData.loading ? (
                    <Loader />
                ) : (
                    <>
                        {(displayLogin && <LoginPage title={title} subTitle={subTitle} />) || <></>}
                        {displayOTP && <OTPPage goBack={showLoginPage} mobile={userMobile.data || ''} />}
                        {!!error?.length && <ErrorContainer message={error} />}
                        {!!authenticationError && <ErrorContainer message={authenticationError.message} />}
                    </>
                )}
            </StyledContentContainer>
        </StyledContainer>
    );
};

export default LoginContainer;
