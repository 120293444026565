import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 575px;
`;

export const StyledHeader = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    background-color: ${(p) => p.theme.colors.loopEmerald};
`;

export const StyledClose = styled.div`
    position: absolute;
    right: 0px;
    margin: 30px;
    cursor: pointer;
`;

export const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: ${(p) => p.theme.colors.white};
`;

export const StyledTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4px;
`;

export const StyledBenefitsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 12px;
    border: 1.5px solid ${(p) => p.theme.colors.platinum};
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 16px;
    margin-bottom: 8px;
`;

export const StyledBenefitRow = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.mutedTransparent};
`;
