import { Alerts, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRightEmerald } from '../../../assets/img';
import { setModularSelectedBasePlan } from '../../../redux/slices/ModularEnrolmentSlice';
import {
    selectModularPlans,
    selectModularSelectedBasePlan,
    selectModularWalletBalance
} from '../../../redux/slices/ModularEnrolmentSlice/selectors';
import { Loader } from '../../atoms';
import ScrollToTop from '../../atoms/ScrollToTop';
import ModularEnrolmentHeader from '../ModularEnrolmentHeader';
import ModularPlanItem from './ModularPlanItem';
import {
    StyledModularPlanNavigationArrow,
    StyledModularPlanNavPageItem,
    StyledModularPlanNavPages,
    StyledModularPlansContainer,
    StyledModularPlansList,
    StyledModularPlansNavigation
} from './styles';
import { getMaxPropertiesToShow } from './utils';
import usePremiumBreakdown from '../ModularEnrolmentFooter/hooks/usePremiumBreakdown';
import { IModule } from '../../../redux/slices/PolicyListSlice/types';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const ModularPlansContainer: React.FC = () => {
    const dispatch = useDispatch();
    const trackClick = useSegment('click');
    const trackPage = useSegment('page');
    const basePlansData = useSelector(selectModularPlans);
    const selectedBasePlan = useSelector(selectModularSelectedBasePlan);
    const walletAmount = useSelector(selectModularWalletBalance);

    const [page, setPage] = React.useState(0);
    const [flexDownBalance, setFlexDownBalance] = React.useState(0);
    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
    const { modular: prevPlan, priceSummary } = usePremiumBreakdown();

    const isLoading = basePlansData.loading;
    const errorMessage = basePlansData.error?.message;

    const plans = basePlansData.data ?? [];
    const totalPlans = plans.length;
    const hasPagination = totalPlans > 4;
    const totalPages = Math.ceil(totalPlans / 4);
    const currentPlans = hasPagination ? plans.slice(page * 4, (page + 1) * 4) : plans;
    const hasPrev = hasPagination && page > 0;
    const hasNext = hasPagination && page < totalPages - 1;

    const maxCoveredBenefits = useMemo(() => getMaxPropertiesToShow(plans), [plans]);

    const setCurrentBasePlan = (id: string) => {
        if (selectedBasePlan != id && walletAmount) {
            const currentPlan = plans.find((p) => p.id === id);
            const newPrice = priceSummary.totalPremium - (prevPlan?.price || 0) + (currentPlan?.price || 0);

            if (
                prevPlan &&
                currentPlan &&
                currentPlan.price < prevPlan.price &&
                currentPlan.price < walletAmount &&
                newPrice < walletAmount
            ) {
                const freed = walletAmount - newPrice;
                setFlexDownBalance(freed);
                if (timeoutRef.current) clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => setFlexDownBalance(0), 10000);
            } else {
                setFlexDownBalance(0);
            }
        }
        dispatch(setModularSelectedBasePlan(id));
    };

    const handleNextNavClick = () => {
        if (hasNext) setPage(page + 1);
    };
    const handlePrevNavClick = () => {
        if (hasPrev) setPage(page - 1);
    };

    const handleSelectPlan = (plan: IModule) => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.CHOOSE_PLAN;
        trackClick({
            name: segmentAction.name,
            properties: {
                ...segmentAction.properties,
                plan_id: plan.id,
                plan_name: plan.name,
                sum_insured: plan.sumInsured,
                eligible_members: plan.familyDefinition
            }
        });
        setCurrentBasePlan(plan.id);
    };

    useEffect(() => {
        if (plans && !isLoading) {
            const pageAction = SEGMENT_ACTIONS.PAGE.POLICY_PLAN_SELECTION_PAGE;
            trackPage({
                name: pageAction.name,
                properties: {
                    ...pageAction.properties,
                    total_plans: plans?.length
                }
            });
        }
    }, [plans]);

    return (
        <StyledModularPlansContainer>
            <ScrollToTop />
            <ModularEnrolmentHeader
                title="Find the right plan for your needs"
                subtitle="Select a plan that best fits you"
                freedBalance={flexDownBalance}
                onFreedBalanceClose={() => setFlexDownBalance(0)}
            />
            {isLoading ? (
                <Loader />
            ) : errorMessage ? (
                <Alerts.StatusAlert
                    variant="over"
                    text={
                        <Typography variant="medium" color="error">
                            {errorMessage}
                        </Typography>
                    }
                    alignItems="center"
                />
            ) : (
                <StyledModularPlansNavigation>
                    <StyledModularPlansList $total={plans.length}>
                        {currentPlans.map((p, i) => (
                            <ModularPlanItem
                                key={p.id}
                                plan={p}
                                index={i}
                                isSelected={selectedBasePlan === p.id}
                                onClick={() => handleSelectPlan(p)}
                                maxProperties={maxCoveredBenefits}
                            />
                        ))}
                    </StyledModularPlansList>
                    {hasPrev && (
                        <StyledModularPlanNavigationArrow $flip src={ArrowRightEmerald} onClick={handlePrevNavClick} />
                    )}
                    {hasNext && (
                        <StyledModularPlanNavigationArrow src={ArrowRightEmerald} onClick={handleNextNavClick} />
                    )}
                    {hasPagination && (
                        <StyledModularPlanNavPages>
                            {Array.from({ length: totalPages }).map((_, index) => (
                                <StyledModularPlanNavPageItem
                                    $filled={page === index}
                                    key={index}
                                    onClick={() => setPage(index)}
                                />
                            ))}
                        </StyledModularPlanNavPages>
                    )}
                </StyledModularPlansNavigation>
            )}
        </StyledModularPlansContainer>
    );
};

export default ModularPlansContainer;
