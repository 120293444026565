import { useSelector } from 'react-redux';
import {
    selectedAlaCarteBenefits,
    selectModularAlaCarteBenefits,
    selectModularPlans,
    selectModularSelectedBasePlan,
    selectModularWalletBalance
} from '../../../../redux/slices/ModularEnrolmentSlice/selectors';
import { useMemo } from 'react';
import { IAlacarte, IPriceSummary } from '../../../../redux/slices/PolicyListSlice/types';
import { IUsePremiumBreakdown } from '../types';

const usePremiumBreakdown = (): IUsePremiumBreakdown => {
    const selectedModularPlan = useSelector(selectModularSelectedBasePlan);
    const modularPlans = useSelector(selectModularPlans).data || [];
    const selectedAlaCarte = useSelector(selectedAlaCarteBenefits);
    const alacarteBenefits = useSelector(selectModularAlaCarteBenefits).data || [];
    const balance = useSelector(selectModularWalletBalance);

    const modular = useMemo(
        () => modularPlans.find((plan) => plan.id === selectedModularPlan),
        [modularPlans, selectedModularPlan]
    );
    const alacarte = useMemo(() => {
        const al: IAlacarte[] = [];
        alacarteBenefits.forEach((benefit) => {
            if (benefit.subCategories.length) {
                const sub = benefit.subCategories.find((sub) => selectedAlaCarte.includes(sub.id));
                if (sub) {
                    // only selected tier subcategory
                    al.push({ ...benefit, price: sub.price, subCategories: [sub] });
                }
            } else if (selectedAlaCarte.includes(benefit.id)) {
                al.push(benefit);
            }
        });
        return al;
    }, [alacarteBenefits, selectedAlaCarte]);

    const priceSummary: IPriceSummary = useMemo(() => {
        const totalPremium = alacarte.reduce((acc, benefit) => (acc += benefit.price), modular?.price || 0);
        const walletDeducted = balance ? Math.min(balance, totalPremium) : 0;
        const salaryDeducted = totalPremium - walletDeducted;
        return { totalPremium, walletDeducted, salaryDeducted, gst: 0 };
    }, [modular, alacarte, balance]);
    return { modular, alacarte, priceSummary };
};
export default usePremiumBreakdown;
