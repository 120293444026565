/* eslint-disable */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/remote-config';
import useToken from '../utils/hooks/useToken';
import { nanoid } from 'nanoid';
import { ISuccessResponse } from './types';
import { captureSentryException } from '../utils/sentry';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    appVerificationDisabledForTesting: process.env.REACT_APP_DISABLED_FIREBASE_CAPTCHA
};
const tokens = useToken();
let FirebaseProvider: firebase.app.App;
if (!firebase.apps.length) {
    FirebaseProvider = firebase.initializeApp(firebaseConfig);
} else {
    FirebaseProvider = firebase.app(); // if already initialized, use that one
}
const appVerificationDisabledForTesting = process.env.REACT_APP_DISABLED_FIREBASE_CAPTCHA === 'true' ? true : false;
if (appVerificationDisabledForTesting) {
    firebase.auth().settings.appVerificationDisabledForTesting = true;
}
export const Firestore = FirebaseProvider.firestore();
export const FirebaseStorage = FirebaseProvider.storage();
export const FirebaseFunctions = FirebaseProvider.functions('asia-south1');
export const FirebaseCaptcha = firebase.auth;
export const FirebaseAuth = firebase.auth();
export const FirebaseTimestamp = firebase.firestore.Timestamp;
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export const FirebaseRemoteConfig = firebase.remoteConfig();
export default FirebaseProvider;

export const getContentType = (res: any) => {
    const isJSON = res.headers.get('Content-Type')?.startsWith('application/json') || false;

    if (isJSON) {
        return 'JSON';
    }

    const isText = res.headers.get('Content-Type')?.startsWith('text') || false;
    if (isText) {
        return 'Text';
    }

    return 'Unsupported';
};

export const processResponse = async (res: any) => {
    const contentType = getContentType(res);
    if (res.ok) {
        if (contentType === 'JSON') {
            return await res.json();
        } else {
            return res;
        }
    }
};

type IMethod = 'DELETE' | 'PUT' | 'POST' | 'GET';

export const accessAPI = async (
    method: IMethod,
    url: string,
    body?: Record<string, unknown>,
    epService?: boolean
): Promise<Record<string, unknown>> => {
    const traceId = nanoid();
    const baseUrl = epService ? process.env.REACT_APP_EP_SERVICE_URL : process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL;
    const fullURL = baseUrl + url;
    try {
        const token = await tokens.generateToken();

        const result = await fetch(fullURL, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'loop-source': process.env.REACT_APP_NAME ?? '',
                'trace-id': traceId
            }
        });

        if (result.ok) {
            return await processResponse(result);
        } else {
            const errorMessage = (await result.json()).message;
            // if result is such tht token is expired then process new token
            if (tokens.tokenIsExpired(errorMessage)) {
                try {
                    const isTokenAvailable = await tokens.generateToken();
                    if (isTokenAvailable) {
                        return await accessAPI(method, url, body);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            if (Array.isArray(errorMessage) || typeof errorMessage === 'object') {
                throw new Error(JSON.stringify(errorMessage));
            }
            throw new Error(errorMessage);
        }
    } catch (e) {
        captureSentryException(`${method}: ${(e as Error).message}`, e, {
            fullURL,
            body: body ? JSON.stringify(body) : undefined,
            traceId
        });
        throw e;
    }
};

export const get = async (url: string, epService?: boolean): Promise<Record<string, unknown> | ISuccessResponse> => {
    return await accessAPI('GET', url, undefined, epService);
};

export const post = async (
    url: string,
    body: Record<string, unknown>,
    epService?: boolean
): Promise<Record<string, unknown> | ISuccessResponse> => {
    return await accessAPI('POST', url, body, epService);
};

export const del = async (
    url: string,
    body?: Record<string, unknown>,
    epService?: boolean
): Promise<Record<string, unknown> | ISuccessResponse> => {
    return await accessAPI('DELETE', url, body, epService);
};

export const put = async (
    url: string,
    body?: Record<string, unknown>,
    epService?: boolean
): Promise<Record<string, unknown> | ISuccessResponse> => {
    return await accessAPI('PUT', url, body, epService);
};
