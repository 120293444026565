import { IPolicy } from '../redux/slices/PolicyListSlice/types';
import { COMPANY_ID, InsuranceTypeConstants, INTERNAL_ROUTES, MAIN_ROUTES } from './constants';
import { SEGMENT_ACTIONS } from './constants/SegmentActionConstants';

import { customAlphabet, nanoid } from 'nanoid';
import { tz } from 'moment-timezone';
import { useSelector } from 'react-redux';
import { ReduxState } from '../redux';
import { IToastContext } from './hooks/useToast';
import { isReEditEnabled } from './featureFlags';
import { EnrolmentStatus } from '../components/pages/SPAContainer/pages/DashboardPage/utils';
import { FirebaseRemoteConfig } from '../adapters/provider';
import { IDeviceDetails } from './types';
import { IGender } from '../redux/slices/ModularEnrolmentSlice/types';
import { BoyIcon, GirlIcon, GrandmaIcon, GrandpaIcon, ManIcon, WomanIcon } from '../assets/img';

export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';
export const redirect = (url: string): void => {
    window.open(url, '_blank');
};

export const insuranceCardDateFormat = (seconds: number): string => defaultDateFormat(seconds);

export const defaultDateFormat = (seconds: number): string =>
    tz(new Date(seconds * 1000), 'Asia/Kolkata').format(DEFAULT_DATE_FORMAT);

export const getDaysLeft = (date: number): string => {
    const dateToFormat = new Date(date * 1000);
    const today = new Date();
    const diffTime = +dateToFormat - +today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 0) {
        return 'OVERDUE';
    } else {
        const daysLeft = isNaN(diffDays) ? 'N/A' : diffDays;
        return `${daysLeft} Days Left`;
    }
};
export const getSumInsured = (sumInsured: string | number, topUpAmount: string | number): string => {
    const sumInsuredValue = Number(sumInsured) / 100000;
    const topUpAmountValue = Number(topUpAmount) / 100000;
    const sumInsuredString = sumInsuredValue !== 0 ? `${sumInsuredValue} Lacs` : ``;
    const topUpAmountString = topUpAmountValue !== 0 ? `${topUpAmountValue} Lacs Top Up` : ``;
    return `${sumInsuredString} 
            ${sumInsuredValue === 0 || topUpAmountValue === 0 ? '' : '+'} 
            ${topUpAmountString}`.trim();
};

export const getAvailableTopup = (topUpAmount: string | number): string => {
    const topUpAmountValue = Number(topUpAmount) / 100000;
    const topUpAmountString = topUpAmountValue !== 0 ? ` Upto ₹ ${topUpAmountValue} Lacs` : ``;
    return topUpAmountString;
};
export const createLoopId = (prefix: string): string => {
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);
    const documentId = `${prefix}-` + nanoid().toLocaleUpperCase();
    return documentId;
};
export const ageFromDateOfBirthday = (dateOfBirth: { seconds: number }): number => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth.seconds * 1000);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

export const getFullInsuranceName = (short: string): string => {
    return InsuranceTypeConstants[short?.toUpperCase().trim()] || short?.toUpperCase().trim();
};

export const getAgeFromDOB = (userDob: number): number => {
    const dob = new Date(userDob * 1000);
    const monthDiff = Date.now() - dob.getTime();
    const ageDt = new Date(monthDiff);
    const year = ageDt.getUTCFullYear();
    return Math.abs(year - 1970);
};

export const topUpFeatureEnabled = (): string | boolean => {
    return process.env.REACT_APP_SUPERTOPUP_INTEGRATED || false;
};

export const checkIfTopUpAvailable = (status: string, GMCPolicy: IPolicy | any): boolean => {
    let parentCount = 0;
    // TODO: temp check for specific company
    const companyId = useSelector((state: ReduxState) => state.user.userData.data?.employer);
    const companyIDs = FirebaseRemoteConfig.getValue('SupertopUpParentalWhitelisting').asString();
    const companyIDsArray = companyIDs && JSON.parse(companyIDs);
    if (
        typeof companyId != 'undefined' && companyIDsArray.length
            ? companyIDsArray.includes(companyId)
            : COMPANY_ID.includes(companyId)
    ) {
        return checkIfTopUpAvailableCompany(status, GMCPolicy);
    }
    if (GMCPolicy?.policyType.toLowerCase() === 'gmc' && GMCPolicy?.familyStruture.toLowerCase().includes('self')) {
        const filteredUserDOB = GMCPolicy?.dependents.find((user: { doB: number; relationship: string }) => {
            if (user.relationship.toLowerCase() === 'parent' || user.relationship.toLowerCase() === 'parent-in-law') {
                return getAgeFromDOB(user.doB) > 90 || getAgeFromDOB(user.doB) < 35;
            }
        });
        if (typeof filteredUserDOB != 'undefined') {
            return false;
        }
        GMCPolicy?.dependents.forEach((user: { relationship: string }) => {
            if (user.relationship.toLowerCase() === 'parent' || user.relationship.toLowerCase() === 'parent-in-law') {
                parentCount++;
            }
        });
        if (parentCount > 2) {
            return false;
        }
    } else {
        return false;
    }
    if (
        GMCPolicy?.policyType.toLowerCase() === 'gmc' &&
        GMCPolicy?.familyStruture.toLowerCase().includes('self') &&
        Number(GMCPolicy?.sumInsured) < 200000
    ) {
        return false;
    }
    return status !== 'NOT_AVAILABLE';
};
// TODO: temp function
export const checkIfTopUpAvailableCompany = (status: string, GMCPolicy: IPolicy | any): boolean => {
    let parentCount = 0;
    if (GMCPolicy?.policyType.toLowerCase() === 'gmc' && !GMCPolicy?.familyStruture.toLowerCase().includes('self')) {
        const filteredUserDOB = GMCPolicy?.dependents.find((user: { doB: number; relationship: string }) => {
            if (user.relationship.toLowerCase() === 'parent' || user.relationship.toLowerCase() === 'parent-in-law') {
                return getAgeFromDOB(user.doB) > 90 || getAgeFromDOB(user.doB) < 35;
            }
        });
        if (typeof filteredUserDOB != 'undefined') {
            return false;
        }
        GMCPolicy?.dependents.forEach((user: { relationship: string }) => {
            if (user.relationship.toLowerCase() === 'parent' || user.relationship.toLowerCase() === 'parent-in-law') {
                parentCount++;
            }
        });
        if (parentCount > 2) {
            return false;
        }
    } else {
        return false;
    }
    if (
        GMCPolicy?.policyType.toLowerCase() === 'gmc' &&
        !GMCPolicy?.familyStruture.toLowerCase().includes('self') &&
        Number(GMCPolicy?.sumInsured) < 200000
    ) {
        return false;
    }
    return status === 'NOT_SELECTED' || status === 'PENDING';
};
export const getSegmentPageName = (pathName: string): string => {
    switch (pathName) {
    case MAIN_ROUTES.login:
        return 'LOGIN_PAGE';
    case MAIN_ROUTES.app:
    case INTERNAL_ROUTES.dashboard:
    case INTERNAL_ROUTES.home:
        return 'DASHBOARD_PAGE';
    case INTERNAL_ROUTES.enrolment:
        return 'ENROLMENT_PAGE';
    case INTERNAL_ROUTES.summary:
        return 'SUMMARY_PAGE';
    case INTERNAL_ROUTES.topup:
        return 'FAMILY_TOPUP_PAGE';
    case INTERNAL_ROUTES.checkout:
        return 'CHECKOUT_PAGE';
    default:
        return 'UNKNOWN_PAGE';
    }
};

export const checkSegmentTrackPageCall = (pathName: string): boolean => {
    switch (pathName) {
    case INTERNAL_ROUTES.summary:
        return false;
    case INTERNAL_ROUTES.checkout:
        return false;
    default:
        return true;
    }
};

export const getPolicyCardTrackObject = (
    policy: IPolicy
): { name: string; properties?: Record<string, unknown> | undefined } => {
    const isEnrolmentCompleted = policy.enrolmentStatus?.toLowerCase() === EnrolmentStatus.ENROLED;
    let segmentAction;
    if (isEnrolmentCompleted && isReEditEnabled) {
        segmentAction = SEGMENT_ACTIONS.CLICK.DASHBOARD_INSURANCE_CARD_REDO;
    } else {
        segmentAction = SEGMENT_ACTIONS.CLICK.DASHBOARD_INSURANCE_CARD;
    }

    return {
        name: segmentAction.name,
        properties: {
            describe: segmentAction.properties?.describe || '',
            // Below, we check if the policy due date to "enrol" into the policy has passed.
            // there is a function in SEGMENT_ACTIONS, to which we pass a boolean.
            // the function returns a string.
            // We could have directly passed the boolean value from here, instead of passing it
            // through SEGMENT_ACTIONS, but in that case, SEGMENT_ACTIONS would have lost "single-source of truth"
            policy_lapsed:
                (segmentAction.properties?.policy_lapsed as (isLapsed: boolean) => string)(
                    getDaysLeft(policy.enrolmentDueDate) === 'OVERDUE'
                ) || '',
            policy_id: (segmentAction.properties?.policy_id as (policyId: string) => string)(policy.policyId) || '',
            policy_status:
                (segmentAction.properties?.policy_status as (policyStatus: string) => string)(
                    policy.enrolmentStatus || 'Pending'
                ) || '',
            enrolling_in:
                (segmentAction.properties?.enrolling_in as (policyType: string) => string)(policy.policyType) || '',
            login_status: segmentAction.properties?.login_status,
            page_title: segmentAction.properties?.page_title
        }
    };
};

export const getGender = (gender: string): string => {
    return gender.toLowerCase() === 'male' ? 'M' : 'F';
};

export const capitalizeFirstLetter = (name: string): string => {
    return name.charAt(0).toUpperCase() + name.slice(1);
};

export const capitalizeFirstLetterEach = (str?: string): string => {
    return str
        ? str
            .toLowerCase()
            .split(' ')
            .map((word) => capitalizeFirstLetter(word))
            .join(' ')
        : '';
};

export const getPremiumAmount = (amount: number, percent: number): number => {
    return Number((amount * (percent / 100)).toFixed(2));
};

export const replaceFamilyStructureText = (category: string): string => {
    const children = /\Kids\b/g;
    const child = /\Kid\b/g;
    if (children.test(category)) {
        category = category.replace(children, 'Children');
    }
    if (child.test(category)) {
        category = category.replace(child, 'Child');
    }
    return category;
};

export const parseResponse = async (promise: Promise<any>): Promise<any> => {
    return promise
        .then((data) => {
            return [null, data];
        })
        .catch((err) => [err]);
};
export const showApiFailureToast = (toast: IToastContext | null, message?: string): void => {
    toast?.displayToast('error', 'Something went wrong', message || '', 5000);
};

export const formatAmount = (amount: string | number): string => {
    const formatAmount = parseFloat(amount?.toString());
    const formattedAmount = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(formatAmount);
    return `₹ ${formattedAmount.slice(1)}`;
};

export const getDaysLeftTopUp = (date: number, type: string, days = 30): boolean => {
    const dateToFormat = new Date(date * 1000);
    const today = new Date();
    let diffTime;
    let diffDays;
    if (type === 'startDate') {
        diffTime = +today - +dateToFormat;
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays <= days) {
            return true;
        }
    }
    if (type === 'endDate') {
        diffTime = +dateToFormat - +today;
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 0) {
            return true;
        }
    }
    return false;
};

export const enrollmentDaysLeft = (date: number, days = 30): number => {
    const dateToFormat = new Date(date * 1000);
    const today = new Date();
    const diffTime = +today - +dateToFormat;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const daysLeft = isNaN(days - diffDays) ? 0 : days - diffDays;
    return daysLeft;
};

export const getDeductible = (sumInsured: number): number => {
    if (sumInsured >= 500000) {
        return 500000;
    } else if (sumInsured >= 300000 && sumInsured < 500000) {
        return 300000;
    } else if (sumInsured >= 200000 && sumInsured < 300000) {
        return 200000;
    } else {
        return 0;
    }
};

export interface RecommendedSI {
    ESCP: {
        AgeRange: number[];
        FamilyMembers: number[];
        sumInsured: number;
        lowMsg: string;
        highMsg: string;
    }[];
    ESC: {
        AgeRange: number[];
        FamilyMembers: number[];
        sumInsured: number;
        lowMsg: string;
        highMsg: string;
    }[];
    E: {
        AgeRange: number[];
        FamilyMembers: number[];
        sumInsured: number;
        lowMsg: string;
        highMsg: string;
    }[];
}

export interface RecommendedConfig {
    template1: string[];
    template2: string[];
    template3: string[];
    defaultTemplate: number;
    recommendedSI: RecommendedSI;
}
export const getRecommendedTemplate = (userId: string, defaultConfig: RecommendedConfig | any): number => {
    const template = defaultConfig?.defaultTemplate;
    const templateOne = defaultConfig?.template1?.find((user: string) => user === userId);
    if (templateOne) {
        return 1;
    }
    const templateTwo = defaultConfig?.template2?.find((user: string) => user === userId);
    if (templateTwo) {
        return 2;
    }
    const templateThree = defaultConfig?.template3?.find((user: string) => user === userId);
    if (templateThree) {
        return 3;
    }
    return template;
};

export const formatDate = (date: string): string => {
    const formatDate = new Date(date);
    return tz(new Date(formatDate), 'Asia/Kolkata').format(DEFAULT_DATE_FORMAT);
};

export const formatDateString = (date: string): string => {
    return tz(date, 'Asia/Kolkata').format('DD/MM/YYYY');
};

export const formatFullName = (firstName?: string, lastName?: string): string => {
    return capitalizeFirstLetterEach(`${firstName || ''} ${lastName || ''}`.trim());
};

export const getDeviceDetails = (): IDeviceDetails => {
    let deviceId = localStorage.getItem('LOOP_DEVICE_ID') || '';
    if (!deviceId) {
        deviceId = nanoid();
        localStorage.setItem('LOOP_DEVICE_ID', deviceId);
    }

    const userAgent = navigator.userAgent;
    const platform = navigator.platform;
    const vendor = navigator.vendor;
    return {
        userAgent: userAgent,
        platform,
        vendor,
        deviceId: deviceId
    };
};

export const validateEmail = (emailTxt: string): boolean => {
    if (!emailTxt) {
        return false;
    }
    const regEx =
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(emailTxt);
};

export const getIconMapping = (relationship: string, gender: IGender): string => {
    switch (relationship) {
    case 'self':
    case 'spouse':
        if (gender === 'female') return WomanIcon;
        else return ManIcon;
    case 'child':
        if (gender === 'female') return GirlIcon;
        else return BoyIcon;
    case 'parent':
    case 'parent-in-law':
        if (gender === 'female') return GrandmaIcon;
        else return GrandpaIcon;
    default:
        return ManIcon;
    }
};
