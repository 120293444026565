import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectModularCurrentStep,
    selectModularPolicyId,
    selectModularWalletBalance
} from '../../../../../redux/slices/ModularEnrolmentSlice/selectors';
import { ModularEnrolmentSteps } from '../../../../../redux/slices/ModularEnrolmentSlice/types';
import { StyledModularEnrolmentPage } from './styles';
import ModularEnrolmentSummary from '../../../../containers/ModularEnrolmentSummary';
import { Redirect } from 'react-router-dom';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';
import {
    AlaCarteBenefits,
    ModularAddDependents,
    ModularEnrolmentFooter,
    ModularFtuxModal,
    ModularPlansContainer
} from '../../../../containers';
import { ReduxState } from '../../../../../redux';
import { selectFTUShownUserPolicyIds } from '../../../../../redux/slices/ApplicationStateSlice/selectors';
import { addFTUShownUSerPolicyIds } from '../../../../../redux/slices/ApplicationStateSlice';
import { FreshChat } from '../../../../atoms/FreshChat';

const ModularEnrolmentPage: React.FC = () => {
    const dispatch = useDispatch();
    const currentStep = useSelector(selectModularCurrentStep);
    const policyId = useSelector(selectModularPolicyId);
    const ftuShownUserPolicyIds = useSelector(selectFTUShownUserPolicyIds);
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId);
    const [ftuxModal, setFtuxModal] = useState(false);
    const walletAmount = useSelector(selectModularWalletBalance);

    useEffect(() => {
        if (walletAmount) {
            const isFtuxShown = ftuShownUserPolicyIds.find(
                (item) => item.userId === userId && item.policyId === policyId
            );
            if (!isFtuxShown) {
                setFtuxModal(true);
            }
        }
    }, [ftuShownUserPolicyIds, userId]);

    const onFtuModalClose = () => {
        dispatch(addFTUShownUSerPolicyIds({ userId, policyId }));
    };

    if (!policyId) {
        return <Redirect to={INTERNAL_ROUTES.dashboard} />;
    }
    return (
        <StyledModularEnrolmentPage>
            <FreshChat topic="loop_flex" />
            {currentStep === ModularEnrolmentSteps.BASE_PLAN && <ModularPlansContainer />}
            {currentStep === ModularEnrolmentSteps.DEPENDENTS && <ModularAddDependents />}
            {currentStep === ModularEnrolmentSteps.HEALTHCARE_BENEFITS && <AlaCarteBenefits />}
            {currentStep === ModularEnrolmentSteps.SUMMARY && <ModularEnrolmentSummary />}
            <ModularEnrolmentFooter />
            <ModularFtuxModal isVisible={ftuxModal} setIsVisible={setFtuxModal} onClose={onFtuModalClose} />
        </StyledModularEnrolmentPage>
    );
};

export default ModularEnrolmentPage;
