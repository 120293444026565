import React from 'react';
import { IGenericIconProps } from './types';

const Secure: React.FC<IGenericIconProps> = ({
    width = 20,
    height = 20,
    strokeColor = '#025F4C',
    strokeWidth = 1.2
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
        <path
            d="M8.74166 1.8583L4.58334 3.41663C3.62501 3.77496 2.84167 4.90829 2.84167 5.93329V12.1249C2.84167 13.1083 3.49168 14.4 4.28335 14.9916L7.86667 17.6666C9.04167 18.55 10.975 18.55 12.15 17.6666L15.7333 14.9916C16.525 14.4 17.175 13.1083 17.175 12.1249V5.93329C17.175 4.90829 16.3917 3.77496 15.4333 3.41663L11.275 1.8583C10.5667 1.59997 9.43333 1.59997 8.74166 1.8583Z"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 9.09998C9.96668 9.09998 9.92501 9.09998 9.89167 9.09998C9.10834 9.07498 8.48334 8.42497 8.48334 7.6333C8.48334 6.82497 9.14168 6.16663 9.95001 6.16663C10.7583 6.16663 11.4167 6.82497 11.4167 7.6333C11.4084 8.4333 10.7833 9.07498 10 9.09998Z"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.3417 11.4333C7.5417 11.9666 7.5417 12.8416 8.3417 13.375C9.25003 13.9833 10.7417 13.9833 11.65 13.375C12.45 12.8416 12.45 11.9666 11.65 11.4333C10.75 10.825 9.25837 10.825 8.3417 11.4333Z"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Secure;
