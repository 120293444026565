import React from 'react';
import {
    StyledButton,
    StyledButtonContainer,
    StyledCard,
    StyledCardContainer,
    StyledCardTitleContainer,
    StyledContainer,
    StyledFooter,
    StyledHeaderContainer,
    StyledIconContainer,
    StyledImageContainer,
    StyledQR,
    StyledScanContainer,
    StyledScanDetailsContainer
} from './styles';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import {
    DownloadAppGraphicOne,
    DownloadAppGraphicThree,
    DownloadAppGraphicTwo,
    QRImg
} from '../../../../../assets/img';
import DownloadAppModal from '../../../../containers/DownloadAppCard/DownloadAppModal';
import { AppStore, PlayStore } from '../../../../../assets/img/CustomIcons';
import theme from '../../../../../theme';
import { useHistory } from 'react-router-dom';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';

interface ICard {
    title: string;
    subtitle: string;
    buttontext: string;
    onClick: () => void;
    image: string;
}

const Card: React.FC<ICard> = ({ title, subtitle, buttontext, onClick, image }) => {
    return (
        <StyledCard>
            <StyledCardTitleContainer>
                <Typography variant="title2" weight="medium" color="emerald">
                    {title}
                </Typography>
                <Typography variant="small">{subtitle}</Typography>
            </StyledCardTitleContainer>

            <StyledButtonContainer>
                <LoopButton
                    variant="outline"
                    borderColor={theme.colors.loopEmerald}
                    textColor="emerald"
                    size="small"
                    onClick={onClick}
                >
                    {buttontext}
                </LoopButton>
            </StyledButtonContainer>

            <StyledImageContainer src={image} />
        </StyledCard>
    );
};

const DownloadApp: React.FC = () => {
    const history = useHistory();
    const [downloadModal, setDownloadModal] = React.useState(false);

    const onClick = () => {
        setDownloadModal(true);
    };

    const homeNavgation = () => {
        history.push(INTERNAL_ROUTES.dashboard);
    };

    return (
        <StyledContainer>
            <StyledHeaderContainer>
                <Typography variant="title1" weight="medium">
                    {`What's next?`}
                </Typography>
                <StyledScanContainer>
                    <StyledQR src={QRImg} />
                    <StyledScanDetailsContainer>
                        <Typography variant="medium" weight="medium">
                            Scan to download the Loop App
                        </Typography>
                        <StyledIconContainer>
                            <PlayStore />
                            <AppStore />
                        </StyledIconContainer>
                    </StyledScanDetailsContainer>
                </StyledScanContainer>
            </StyledHeaderContainer>

            <StyledCardContainer>
                <Card
                    title="File a claim easily"
                    subtitle="Instant access to your e-card on app!"
                    buttontext="File Claim"
                    onClick={onClick}
                    image={DownloadAppGraphicOne}
                />
                <Card
                    title="Consult top doctors for free"
                    subtitle="Top-rated doctors across 10+ specialities"
                    buttontext="Consult Doctor"
                    onClick={onClick}
                    image={DownloadAppGraphicTwo}
                />
                <Card
                    title="See policy coverage details"
                    subtitle="Detailed policy coverage at your fingertips"
                    buttontext="Check Policy Details"
                    onClick={onClick}
                    image={DownloadAppGraphicThree}
                />
            </StyledCardContainer>
            <StyledFooter>
                <StyledButton>
                    <LoopButton variant="filled" onClick={homeNavgation}>
                        Back to Home
                    </LoopButton>
                </StyledButton>
            </StyledFooter>
            <DownloadAppModal isVisible={downloadModal} setIsVisible={setDownloadModal} />
        </StyledContainer>
    );
};

export default DownloadApp;
