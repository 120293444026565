import styled from 'styled-components';

export const StyledModularPlansContainer = styled.div`
    padding: 36px 72px;
    display: flex;
    flex-direction: column;
    gap: 36px;
`;

export const StyledModularPlansList = styled.div<{ $total: number }>`
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(${(p) => Math.min(Math.max(p.$total, 3), 4)}, 1fr);
    flex: 1;
    padding-top: ${(p) => (p.$total > 4 ? '24px' : '0px')};
`;

export const StyledModularPlanItem = styled.div`
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.colors.mutedTransparent};
    position: relative;
    min-height: 100%;
    background-color: ${(p) => p.theme.colors.loopEmerald};
    display: flex;
    flex-direction: column;
`;

export const StyledModularPlanPlanHeader = styled.div<{ $bg: string }>`
    padding: 24px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-image: url(${(p) => p.$bg});
    background-position: right top;
    background-repeat: no-repeat;
`;

export const StyledModularPlanPlanHeaderText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledModularPlanHeaderTagline = styled.img`
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 0;
`;

export const StyledModularPlanSelectorWrapper = styled.div`
    position: absolute;
    top: 24px;
    right: 14px;
`;

export const StyledModularPlanSelector = styled.div<{ $selected: boolean }>`
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
    position: relative;
    border-radius: 50%;
    border: 2px solid ${(p) => p.theme.colors.white};
    cursor: pointer;

    &::after {
        content: ${(p) => (p.$selected ? '"a"' : '')};
        color: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: ${(p) => p.theme.colors.coverUpGreen};
    }
`;

export const StyledModularPlanContent = styled.div<{ $selected: boolean }>`
    display: flex;
    flex-direction: column;
    padding: ${(p) => (p.$selected ? '16px' : '16px 18px')};
    border-radius: 10.5px;
    background-color: ${(p) => p.theme.colors.white};
    margin: ${(p) => (p.$selected ? '0 2px 2px' : '0px 0px')};
    box-sizing: border-box;
    flex: 1;
`;

export const StyledModularPlanContentDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    height: 100%;
`;

export const StyledModularPlanSumInsured = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledModularPlanFamDef = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledModularPlanPremium = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 8px;
    padding: 8px 16px;
    background-color: ${(p) => p.theme.colors.mutedTransparent};
`;

export const StyledModularPlanTaglineWrapper = styled.div`
    position: absolute;
    top: -16px;
    left: 16px;
`;

export const StyledModularPlanTagline = styled.div`
    background-color: ${(p) => p.theme.colors.coverUpGreen};
    border-radius: 6px 0px 4px 4px;
    padding: 6px 12px;
    display: flex;
    gap: 8px;
    align-items: center;
    position: relative;
    height: 32px;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

    &::after {
        content: 'a';
        color: transparent;
        position: absolute;
        top: 0;
        left: 100%;
        width: 0px;
        height: 0px;
        border-left: 18px solid ${(p) => p.theme.colors.coverUpGreen};
        border-top: 16px solid transparent;
    }
`;

export const StyledModularPlanPremiumAmount = styled.div`
    display: flex;
    align-items: end;
`;

export const StyledModularPlanProperty = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledModularPlanPropertyIcon = styled.img`
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
`;

export const StyledModularPlanPropertyText = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledModularHorizontalDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledModularPlanCta = styled.div`
    padding-top: 24px;
`;

export const StyledModularPlansNavigation = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const StyledModularPlanNavigationArrow = styled.img<{ $flip?: boolean }>`
    height: 36px;
    width: 36px;
    border-radius: 50px;
    padding: 8px;
    background-color: ${(p) => p.theme.colors.mutedTransparent};
    transform: rotate(${(p) => (p.$flip ? '180deg' : '0deg')});
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    ${(p) => (p.$flip ? 'left' : 'right')} : 16px;
    cursor: pointer;
`;

export const StyledModularPlanNavPages = styled.div`
    display: flex;
    gap: 8px;
    position: absolute;
    top: -20px;
    right: 0;
`;

export const StyledModularPlanNavPageItem = styled.div<{ $filled: boolean }>`
    width: 40px;
    height: 4px;
    border-radius: 10px;
    background-color: ${(p) => (p.$filled ? p.theme.colors.loopEmerald : p.theme.colors.seafoamGray)};
    cursor: pointer;
`;
