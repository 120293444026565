import { IModularFamily } from '../../../redux/slices/ModularEnrolmentSlice/types';
import { IDependent } from '../../../redux/slices/PolicyListSlice/types';
import { FamilyMemberSortingPreference } from '../../../utils/constants/ModularConstants';
import { IMappings } from '../../../utils/mapping';
import { IForm } from '../ManageModularMember/types';
import { IGetDependentCountReturnType, IGetRelationshipReturnType, oneOfParentsOrInLawsOptions } from './types';

export const getCountByrelationUtil = (
    arr:
        | {
              relation: string;
              number: number;
          }[]
        | null
        | undefined,
    r: string
): number => arr?.find((d) => d.relation.toLowerCase() === r)?.number ?? 0;

export const getRelationshipUtil = (category: string): IGetRelationshipReturnType => {
    const children = ['1 Kid', '2 Kids', '3 Kids', '4 Kids'];
    const parent = ['Parents', 'Parents Only'];
    if (category === 'Spouse') return category;
    else if (children.includes(category)) return 'child';
    else if (parent.includes(category)) return 'parent';
    else if (category === 'Parents (including in-laws)') return 'parent/parent-in-law';
    else if (oneOfParentsOrInLawsOptions.includes(category)) return 'parent|parent-in-law';
    else if (category === 'Parents-in-law') return 'parent-in-law';
    else return '';
};

export const getDependentCountUtil = (
    mappingType: 'target' | 'current',
    relationship: string,
    currentMapping: IMappings | null,
    targetMapping: IMappings | null,
    selectedParentId: string
): IGetDependentCountReturnType => {
    let number = 0;
    if (mappingType === 'target') {
        number = getCountByrelationUtil(targetMapping?.dependents, relationship.toLowerCase());
    } else {
        if (relationship.toLowerCase() === 'parent/parent-in-law') {
            number =
                getCountByrelationUtil(currentMapping?.dependents, 'parent') +
                getCountByrelationUtil(currentMapping?.dependents, 'parent-in-law');
        } else if (relationship.toLowerCase() === 'parent|parent-in-law') {
            number = getCountByrelationUtil(currentMapping?.dependents, selectedParentId.toLowerCase());
        } else {
            number = getCountByrelationUtil(currentMapping?.dependents, relationship.toLowerCase());
        }
    }
    return { relation: relationship, number };
};

export const getAddMemberCardCount = (
    relation: string,
    currentMapping: IMappings | null,
    targetMapping: IMappings | null,
    selectedParentId: string
): number => {
    const targetCount =
        getDependentCountUtil('target', getRelationshipUtil(relation), currentMapping, targetMapping, selectedParentId)
            ?.number || 0;

    const currentCount =
        getDependentCountUtil('current', getRelationshipUtil(relation), currentMapping, targetMapping, selectedParentId)
            ?.number || 0;

    return Math.max(targetCount - currentCount, 0);
};

export const initialFormValues: IForm = {
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    relationship: '',
    mobile: ''
};

export const sortFamilyMembers = (family: (IModularFamily | IDependent)[]): (IModularFamily | IDependent)[] => {
    return structuredClone(family).sort(
        (a, b) =>
            FamilyMemberSortingPreference[a.relationship?.toLowerCase()] -
            FamilyMemberSortingPreference[b.relationship?.toLowerCase()]
    );
};
