import flagsmith from 'flagsmith';
import { FlagsmithProvider, useFlagsmith, useFlagsmithLoading } from 'flagsmith/react';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LOCAL_FLAGS } from '.';
import { ReduxState } from '../../redux';
import { getDeviceDetails } from '../common';
import { isLocalFlags } from '../featureFlags';
import { captureSentryException } from '../sentry';
import { Loader } from '../../components/atoms';

export const FlagsmithWrapper: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const traits = useMemo(
        () => ({
            ...getDeviceDetails(),
            env: process.env.REACT_APP_ENV ?? process.env.NODE_ENV
        }),
        []
    );
    return (
        <FlagsmithProvider
            options={{
                // use local flags to prevent api calls while development
                // this will show nonblocking-error in console
                environmentID: isLocalFlags ? 'dummy-key-to-fail' : `${process.env.REACT_APP_FLAGSMITH_KEY}`,
                onError: (error) => {
                    captureSentryException(`Flagsmith error: ${error.message}`, error);
                },
                traits,
                defaultFlags: LOCAL_FLAGS,
                cacheFlags: true
            }}
            flagsmith={flagsmith}
        >
            <FlagsmithIdentity>{children}</FlagsmithIdentity>
        </FlagsmithProvider>
    );
};

const FlagsmithIdentity: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
    const user = useSelector((state: ReduxState) => state.user?.userData?.data);
    const flagsmithState = useFlagsmithLoading();
    const fs = useFlagsmith();

    useEffect(() => {
        if (user && user.userId && (!fs.initialised || fs.identity !== user.userId)) {
            fs.identify(user.userId, {
                email: user.email,
                mobile: user.mobile,
                company_id: user.employer
            });
        }
    }, [user, fs]);

    return flagsmithState?.isLoading ? <Loader /> : <>{children}</>;
};
