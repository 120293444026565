import React from 'react';
import { useSelector } from 'react-redux';
import {
    StyledContainer,
    StyledContent,
    StyledDescription,
    StyledErrorText,
    StyledButtonContainer,
    StyledIcon
} from './styles';
import { ReduxState } from '../../../redux';
import { IDeleteMemberModal } from './types';
import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { DeleteCircleIcon } from '../../../assets/img';

const DeleteMemberModal: React.FunctionComponent<IDeleteMemberModal> = ({
    isVisible,
    setIsVisible,
    dependentId,
    confirmDelete,
    selectedRelationship
}) => {
    const loading = useSelector((state: ReduxState) => state.enrolment.crudDependents?.loading);
    const error = useSelector((state: ReduxState) => state.enrolment.crudDependents?.error);
    const handleSubmit = () => {
        if (!loading) {
            confirmDelete(dependentId, selectedRelationship);
        }
    };
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
            <StyledContainer>
                <StyledContent>
                    <StyledIcon src={DeleteCircleIcon} />
                    <Typography variant="large" weight="semiBold">{`Delete ${selectedRelationship}`}</Typography>
                </StyledContent>
                <StyledDescription>
                    <Typography variant="small">
                        Are you sure you want to delete this member from the policy?
                    </Typography>
                </StyledDescription>
                {error && error.message ? <StyledErrorText>{error.message}</StyledErrorText> : ''}
                <StyledButtonContainer>
                    <LoopButton variant="error" onClick={handleSubmit} isLoading={loading}>
                        Delete member
                    </LoopButton>
                    <LoopButton variant="outline" onClick={() => setIsVisible(false)} >
                        Cancel
                    </LoopButton>
                </StyledButtonContainer>
            </StyledContainer>
        </Modal>
    );
};

export default DeleteMemberModal;
