import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: center;
    width: 353px;
    gap: 24px;
`;

export const StyledIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    width: 72px;
    height: 72px;
    background-color: ${({ theme }) => theme.colors.teamTurquoise};
    border-radius: 50%;
`;

export const StyledDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-align: center;
`;

export const StyledDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

export const StyledBulletPoint = styled.div`
    position: relative;
    padding-left: 1.5rem;
    line-height: 1.5;

    &::before {
        content: '•';
        position: absolute;
        left: 0;
        font-size: 1rem;
    }
`;
