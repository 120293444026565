import { IModularEnrolmentSlice, ModularEnrolmentSteps } from './types';

export const initialState: IModularEnrolmentSlice = {
    policyId: '',
    currentStep: ModularEnrolmentSteps.BASE_PLAN,
    selectedBasePlan: '',
    selectedAlaCarte: [],
    personalEmail: '',
    family: {
        data: [],
        loading: false,
        error: null
    },
    basePlans: {
        data: null,
        loading: false,
        error: null
    },
    alaCarteBenefits: {
        data: null,
        loading: false,
        error: null
    },
    crudFamily: {
        data: null,
        loading: false,
        error: null
    },
    summary: {
        data: null,
        loading: false,
        error: null
    }
};
