import React from 'react';
import { StyledContainer } from './styles';
import { IProfileIcon } from './types';
import theme from '../../../theme';

const ProfileIcon: React.FC<IProfileIcon> = ({
    icon,
    bgColor = theme.colors.mutedTransparent,
    height = '48',
    width = '48'
}) => {
    return (
        <StyledContainer $bgColor={bgColor} $height={height} $width={width}>
            <img src={icon} />
        </StyledContainer>
    );
};

export default ProfileIcon;
