import flagsmith from 'flagsmith';
import { useFlags } from 'flagsmith/react';
import { IFlagsmithFeature } from 'flagsmith/types';

enum FlagsmithFlags {
    MODULAR = 'feature_modular'
}

// define flags to run locally
const LOCAL_FLAGS: Record<FlagsmithFlags, IFlagsmithFeature> = {
    [FlagsmithFlags.MODULAR]: { enabled: true, value: '' }
};

// to use in components
const useFeatureFlag = (flag: FlagsmithFlags): boolean => {
    const flags = useFlags([flag]);
    return flags[flag].enabled;
};

// for multiple features
const useFeatureFlags = (flags: FlagsmithFlags[]): Record<FlagsmithFlags, IFlagsmithFeature> => {
    return useFlags(flags);
};

// get value of a feature flag
const useFeatureFlagValue = (flag: FlagsmithFlags): unknown => {
    const flags = useFlags([flag]);
    return flags[flag].value;
};

// to use outside components
const isFlagsmithFeatureEnabled = (flag: FlagsmithFlags): boolean => {
    return flagsmith.hasFeature(flag);
};

export { FlagsmithFlags, LOCAL_FLAGS, useFeatureFlag, useFeatureFlags, useFeatureFlagValue, isFlagsmithFeatureEnabled };
