import React from 'react';
import {
    StyledAppIcon,
    StyledAppsWrapper,
    StyledHeaderWrapper,
    StyledHighlightIcon,
    StyledModalContainer,
    StyledModalContent,
    StyledModalWrapper,
    StyledPhoneImg,
    StyledPlayIcon,
    StyledQRImg,
    StyledQRWrapper
} from './styles';
import { IDownloadAppModalProps } from './types';
import { Modal, Typography } from '@loophealth/loop-ui-web-library';
import { AndroidAppIcon, AppViewGraphic, HighlightIcon, IphoneAppIcon, QRImg } from '../../../assets/img';
import WithThemeProvider from '../../../theme/WithThemeProvider';

const DownloadAppModal: React.FunctionComponent<IDownloadAppModalProps> = ({ isVisible, setIsVisible }) => {
    return (
        <StyledModalWrapper>
            <Modal isVisible={isVisible} setIsVisible={setIsVisible}>
                <WithThemeProvider>
                    <StyledModalContainer>
                        <StyledModalContent>
                            <StyledPhoneImg src={AppViewGraphic} />
                            <StyledQRWrapper>
                                <StyledHeaderWrapper>
                                    <Typography variant="title2" color="emerald" weight="bold">
                                        Download Loop
                                    </Typography>
                                    <StyledHighlightIcon src={HighlightIcon} />
                                </StyledHeaderWrapper>

                                <Typography variant="medium" color="secondary" weight="medium">
                                    Scan the QR code using your phone to <br /> get the app.
                                </Typography>
                                <StyledQRImg src={QRImg} />
                                <StyledAppsWrapper>
                                    <StyledPlayIcon src={AndroidAppIcon} />
                                    <StyledAppIcon src={IphoneAppIcon} />
                                </StyledAppsWrapper>
                            </StyledQRWrapper>
                        </StyledModalContent>
                    </StyledModalContainer>
                </WithThemeProvider>
            </Modal>
        </StyledModalWrapper>
    );
};

export default DownloadAppModal;
