import styled from 'styled-components';

export const StyledCoveredMembersModal = styled.div`
    display: flex;
    flex-direction: column;
    width: 740px;
    min-height: 300px;
    justify-content: space-between;
`;

export const StyledCoveredMembersHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 30px 24px 24px;
    border-bottom: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledCoveredMembersList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 32px 24px;
`;

export const StyledCoveredMembersFooter = styled.div`
    border-top: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
    padding: 16px 24px;
    display: flex;
    justify-content: end;
    & > * {
        width: fit-content;
    }
`;

export const StyledCoveredMember = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid ${(p) => p.theme.colors.seafoamGray};
    padding: 16px;
`;

export const StyledCoveredMemberText = styled.div`
    display: flex;
    flex-direction: column;
`;
