import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { createMigrate, PersistConfig, persistStore } from 'redux-persist';
import rootReducer from '../reducers';
import persistReducer from 'redux-persist/es/persistReducer';

const initialState = {};
const AppVersion = Number(process.env.REACT_APP_VERSION);
const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
    key: 'root',
    storage,
    blacklist: ['user'],
    version: AppVersion,
    migrate: createMigrate({
        // auto clear state with new version
        [AppVersion]: () => ({
            _persist: {
                version: AppVersion,
                rehydrated: false
            }
        })
    })
};
const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

const store = createStore(persistedReducer, initialState, compose(applyMiddleware(thunk)));

export default store;
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
