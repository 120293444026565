import styled from 'styled-components';

export const StyledContainer = styled.div`
    min-height: 60px;
    height: 60px;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid ${(p) => p.theme.colors.border};
    background-color: ${(p) => p.theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 80px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 0px 20px;
    }
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
`;
export const SignoutButton = styled.div(
    (p) => `
    color: ${p.theme.colors.loopEmerald};
    font-size: ${p.theme.fontSizes.mediumFont};
    font-weight: 700;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0px 18px;
    &:hover {
        text-decoration: underline;
    }
    @media (max-width: 768px) {
       padding: 7px 10px;
    }
`
);
export const SignoutIcon = styled.img`
    @media (min-width: 768px) {
        display: none;
    }
`;
export const SignoutText = styled.div`
    @media (max-width: 768px) {
        /* display: none; */
    }
`;
export const StyledLogo = styled.img`
    max-width: 140px;
    height: auto;
    cursor: pointer;
    width: 60px;
    @media (max-width: 768px) {
        height: 20px;
    }
`;
export const SignoutWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledTalkBtnWrapper = styled.div`
    max-width: 200px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledLogoutBtnWrapper = styled.div`
    max-width: 90px;
`;

export const StyledHeaderProfile = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
`;

export const StyledChevronIcon = styled.img<{ $flip: boolean }>`
    width: 16px;
    height: 16px;
    aspect-ratio: 1;
    transform: rotate(${(p) => (p.$flip ? '180deg' : '0deg')});
    cursor: pointer;
`;

export const StyledProfileDropdownContainer = styled.div`
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 200px;
    border-radius: 8px;
`;

export const StyledProfileDropdownItems = styled.div`
    border: 1px solid ${(p) => p.theme.colors.seafoamGray};
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledProfileDropdownItem = styled.div`
    padding: 12px 20px;
    cursor: pointer;
    &:hover {
        background-color: ${(p) => p.theme.colors.surface0};
    }
`;
