import React, { useEffect } from 'react';
import { ImageView } from '../../atoms/ImageView';
import {
    Celebrate,
    InfoDefaultIcon,
    ModularConfirmationGraphic,
    PersonImg,
    QuotesImg,
    SelectedBenefits,
    ThumbsUp
} from '../../../assets/img';
import PolicyInfoDetails from '../PolicyInfoDetails';
import theme from '../../../theme';
import { Benefits } from '../EnrolMembersCard/PolicyDetailsSection';
import { LoopButton, Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledCardContainer,
    StyledColumnContainer,
    StyledDetailsContainer,
    StyledDot,
    StyledFlexContainer,
    StyledFooterContainer,
    StyledFooterInfoIcon,
    StyledImageContainer,
    StyledLoadingContainer,
    StyledPolicyInfoContainer
} from './styles';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { InsuranceTypeConstants, INTERNAL_ROUTES } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { LocationState } from '../../../utils/types';
import { familyDefinition } from '../../../utils/mapping';
import Loader from '../../atoms/Loader';
import { StyledLoaderContainer } from '../ModularAddDependents/styles';
import { useNextPolicy } from '../../pages/SPAContainer/pages/DashboardPage/hooks/useNextPolicy';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const RegistrationConfirmation: React.FunctionComponent = () => {
    const history = useHistory();
    const trackClick = useSegment('click');
    const trackPage = useSegment('page');
    const location = useLocation<LocationState>();
    const [isLoading, setIsLoading] = React.useState(false);
    const user = useSelector((state: ReduxState) => state.user?.userData?.data);
    const { completedPolicy, isPendingPolicyEmpty, navigateNext, rawPolicyList, pendingPolicies } = useNextPolicy();

    const handleGoHome = () => {
        trackClick(SEGMENT_ACTIONS.CLICK.HOME_BUTTON);
        history.push(INTERNAL_ROUTES.dashboard);
    };

    const benefits = completedPolicy?.benefits?.alacarte?.map((benefit) => benefit.name) || [];

    const handleNextPolicy = () => {
        const clickAction = SEGMENT_ACTIONS.CLICK.PROCEED_TO_NEXT_POLICY;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                total_pending_policy: pendingPolicies?.length
            }
        });
        navigateNext();
    };

    useEffect(() => {
        trackPage(SEGMENT_ACTIONS.PAGE.REGISTRATION_COMPLETE_PAGE);
    }, []);

    useEffect(() => {
        if (isPendingPolicyEmpty) {
            setIsLoading(true);
            const timerId = setTimeout(() => setIsLoading(false), 1000);
            return () => clearTimeout(timerId);
        }
    }, [rawPolicyList]);

    useEffect(() => {
        if (isPendingPolicyEmpty && !isLoading) {
            const timerId = setTimeout(() => history.push(INTERNAL_ROUTES.downloadApp), 4000);
            return () => clearTimeout(timerId);
        }
    }, [isLoading, rawPolicyList]);

    if (!location?.state?.policyId) {
        return <Redirect to={INTERNAL_ROUTES.dashboard} />;
    }

    return isPendingPolicyEmpty ? (
        <StyledLoadingContainer>
            {isLoading ? (
                <>
                    <StyledFlexContainer gap={'4px'}>
                        <StyledDot />
                        <StyledDot />
                        <StyledDot />
                        <StyledDot />
                    </StyledFlexContainer>
                    <Typography variant={'small'} color={'secondary'}>
                        {'Completing your registration....'}
                    </Typography>
                </>
            ) : (
                <>
                    <ImageView src={Celebrate} width="200px" margin={'20px'} />
                    <Typography variant={'title1'} weight={'semiBold'} color={'emerald'}>
                        {'You’re all done!'}
                    </Typography>
                    <Typography variant={'large'} color={'secondary'}>
                        {'You have successfully registered for all your policies and are insured!'}
                    </Typography>
                </>
            )}
        </StyledLoadingContainer>
    ) : rawPolicyList.loading ? (
        <StyledLoaderContainer>
            <Loader />
        </StyledLoaderContainer>
    ) : (
        <StyledCardContainer>
            <StyledDetailsContainer>
                <StyledColumnContainer>
                    <Typography variant="title2" weight="semiBold">
                        {`Congratulations, ${user.firstName}!`}
                        <StyledImageContainer src={ThumbsUp} />
                    </Typography>
                    <Typography variant={'small'}>
                        {'You have successfully registered for the following policy'}
                    </Typography>
                    <StyledFlexContainer gap={'8px'}>
                        <ImageView src={completedPolicy?.policyImage} width={'40px'} />
                        <StyledFlexContainer gap={'2px'} flexDirection={'column'}>
                            <Typography variant="small" weight="semiBold">
                                {completedPolicy?.policyName}
                            </Typography>
                            <Typography variant="extraSmall" color="secondary">
                                {InsuranceTypeConstants[completedPolicy?.policyType || '']}
                            </Typography>
                        </StyledFlexContainer>
                    </StyledFlexContainer>
                    <StyledPolicyInfoContainer>
                        {completedPolicy?.benefits?.modular?.[0] ? (
                            <PolicyInfoDetails
                                icon={QuotesImg}
                                label={'Selected Base Policy'}
                                value={completedPolicy?.benefits?.modular[0].name}
                                bgColor={theme.colors.mutedTransparent}
                                itemName="basePolicy"
                            />
                        ) : null}
                        <PolicyInfoDetails
                            icon={PersonImg}
                            label="Covered Family Members"
                            value={familyDefinition[completedPolicy?.familyStruture || 0]?.description}
                            bgColor={theme.colors.mutedTransparent}
                            itemName="coveredMembers"
                        />
                        {benefits?.length > 0 && (
                            <PolicyInfoDetails
                                icon={SelectedBenefits}
                                label="Additional Benefits"
                                value={<Benefits benefits={benefits} />}
                                bgColor={theme.colors.mutedTransparent}
                                itemName="selectedBenefits"
                            />
                        )}
                    </StyledPolicyInfoContainer>
                </StyledColumnContainer>
                <StyledColumnContainer>
                    <ImageView src={ModularConfirmationGraphic} width={'100%'} />
                </StyledColumnContainer>
            </StyledDetailsContainer>
            <StyledFooterContainer>
                <StyledFlexContainer gap={'8px'}>
                    <Typography variant="small">
                        {pendingPolicies?.length + ' policies pending for registration'}
                    </Typography>
                    <Tooltip type="informative" beak="bottom-center" text={pendingPolicies?.join(' & ')}>
                        <StyledFooterInfoIcon src={InfoDefaultIcon} />
                    </Tooltip>
                </StyledFlexContainer>
                <StyledFlexContainer gap={'24px'}>
                    <LoopButton borderColor={theme.colors.loopEmerald} variant="outline" onClick={handleGoHome}>
                        {'Go to Home'}
                    </LoopButton>
                    <LoopButton variant="filled" onClick={handleNextPolicy}>
                        {'Proceed to Next Policy'}
                    </LoopButton>
                </StyledFlexContainer>
            </StyledFooterContainer>
        </StyledCardContainer>
    );
};

export default RegistrationConfirmation;
