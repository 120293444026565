import React from 'react';
import { InfoDefaultIcon, NewEditIcon } from '../../../assets/img';
import { Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledEmailEditIcon,
    StyledEmailInfo,
    StyledEmailInfoIcon
} from './styles';
import { IEmailInfoProps } from './types';

export const EmailInfo: React.FunctionComponent<IEmailInfoProps> = ({
    email,
    benefits,
    handleShowEmailInfo
}) => {
    const benefitNames =
      benefits?.length > 1
          ? benefits?.slice(0, -1).join(', ') +
          ' and ' +
          benefits[benefits.length - 1]
          : benefits[0] || '';

    return (
        <StyledEmailInfo>
            <StyledEmailInfoIcon src={InfoDefaultIcon} />
            <span>
                <Typography variant={'small'}>
                    {'Important updates about '}
                    <Typography variant={'small'} weight={'semiBold'}>
                        {benefitNames}
                    </Typography>
                    {' will be sent on '}
                    <Typography variant={'small'} weight={'semiBold'}>
                        {email}
                    </Typography>
                </Typography>
                <StyledEmailEditIcon src={NewEditIcon} onClick={handleShowEmailInfo} />
            </span>
        </StyledEmailInfo>
    );
};
