import { IFamilyDefinition } from '../../../utils/mapping';
import { IError } from '../../types';

export interface IPolicyListSlice {
    policyList: IPolicyList;
    allDependents: IAllDependents;
    companyDetails: ICompanyForms;
}

export interface IPolicyList {
    data: IPolicy[] | null;
    loading: boolean;
    error: IError | null;
}

export interface IPolicy {
    enrolmentStatus: 'ENROLED' | 'PENDING' | 'NOT_SELECTED' | null;
    familyStruture: string;
    policyId: string;
    policyImage: string;
    policyName: string;
    policyType: string;
    enrolmentDueDate: number;
    sumInsured: string;
    topUpAmount: string;
    policyEndDate?: IPOLICYDATE;
    nickName?: string;
    // below field is called data when it comes from the api
    dependents: IDependent[];
    availableTopUp: string;
    topUpAdded: number;
    annualPremium: number;
    annualPremiumWithoutGST: number;
    gstOfAnnualPremium: number;
    insurerName?: string;
    digitPremiumWithoutGST?: number;
    gst?: number;
    walletAmount?: number;
    enrolButton: boolean;
    policySecondaryType?: 'MODULAR';
    isParentalSuperTopup: boolean;
    tpaName: string;
    policyStartDate: IPOLICYDATE;
    policyNumber: string;
    policyPlanStartDate: IPOLICYDATE;
    policyPlanEndDate: IPOLICYDATE;
    premiumPaid: number;
    slabId: string;
    benefits?: IBenefits;
    minSumInsured?: string;
    maxFamilyStructure: keyof IFamilyDefinition;
}

export interface IPOLICYDATE {
    _seconds: number;
    _nanoseconds: number;
}

export interface ICompanyForms {
    data: ICompanyData | null;
    loading: boolean;
    error: IError | null;
}

export interface ICompanyData {
    companyId: string;
    companyName: string;
}
export interface IForms {
    isMandatory: boolean;
    label: string;
    name: string;
    regex: string;
    type: string;
    options?: FieldOptions[];
    isError?: boolean;
    validationMessage?: string;
}
export interface FieldOptions {
    name: string;
    value: string;
}

export interface IDependent extends IDependentBase {
    userId: string;
}

export interface IDependentBase {
    relationship: 'Self' | 'Spouse' | 'Child' | 'Parent' | 'Parent-in-law';
    doB: number;
    gender: 'male' | 'female' | '';
    mobile: string;
    firstName: string;
    lastName: string;
}

export interface IAllDependents {
    data: IDependent[] | null;
    loading: boolean;
    error: IError | null;
}

export interface IBenefits {
    modular: IModule[];
    alacarte: IAlacarte[];
    priceSummary: IPriceSummary;
}

export enum BenefitType {
    MODULAR = 'MODULAR',
    ALA_CARTE = 'ALA_CARTE'
}

export interface IModule {
    id: string;
    name: string;
    description: string;
    logo: string;
    familyDefinition: keyof IFamilyDefinition;
    isDefault: boolean;
    price: number;
    properties: IModuleProperty[];
    isSelected: boolean;
    sumInsured: number;
}

export interface IModuleProperty {
    name: string;
    description: string;
    logo?: string;
    value: string;
    priority?: number;
    isCovered: boolean;
}

export interface IAlacarte {
    isSelected: boolean;
    id: string;
    description: string;
    descriptionUrl?: string;
    name: string;
    logo: string;
    price: number;
    sumInsured: number;
    offerings?: string;
    termsAndConditions?: string;
    providerName?: string;
    lockIn?: string;
    subCategories: IAlacarteCategory[];
    category: string;
    familyDefinition?: keyof IFamilyDefinition;
    offeredBy?: 'LOOP' | 'NON_LOOP';
}

export interface IAlacarteCategory {
    id: string;
    name: string;
    desc: string;
    price: number;
    sumInsured: number;
    description: string;
    isSelected: boolean;
    offerings?: string;
    termsAndConditions?: string;
    providerName?: string;
    familyDefinition?: keyof IFamilyDefinition;
}

export interface IPriceSummary {
    totalPremium: number;
    walletDeducted: number;
    salaryDeducted: number;
    gst: number
}
