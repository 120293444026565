import styled from 'styled-components';

export const StyledThresholdPage = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  height: 100%;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  width: max-content;
  gap: 12px;
`;
