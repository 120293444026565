import styled from 'styled-components';

export const StyledCardContainer = styled.div`
  width: max-content;
  margin: auto;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 16px;
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  margin: auto;
  gap: 16px;
`;

export const StyledDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledDetailsContainer = styled.div`
  display: flex;
  padding: 36px;
  gap: 36px;
`;

export const StyledPolicyInfoContainer = styled.div`
  background-color: #f8f9fa;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
`;

export const StyledFooterContainer = styled.div`
  border-top: 1px solid #eeeeee;
  padding: 24px 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
`;

export const StyledFlexContainer = styled.div<{
  gap: string;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}>(
    (p) => `
  display:flex;
  flex-direction:${p.flexDirection || 'row'};
  gap:${p.gap}
`
);

export const StyledFooterInfoIcon = styled.img`
    width: 20px;
`;

export const StyledImageContainer = styled.img`
  width: 128px;
  height: 64px;
  position: relative;
  top: 20px;
  left: -40px;
`;
