import { RootState } from '../../reducers';
import { IAdapterData } from '../../types';
import { IModule, IAlacarte } from '../PolicyListSlice/types';
import { IModularEnrolmentSummary, ModularEnrolmentSteps, IModularFamily, ICRUDResponse } from './types';

export const selectModularPolicyId = (state: RootState): string => state.modular.policyId;
export const selectModularPlans = (state: RootState): IAdapterData<IModule[]> => state.modular.basePlans;
export const selectModularCurrentStep = (state: RootState): ModularEnrolmentSteps => state.modular.currentStep;
export const selectModularSelectedBasePlan = (state: RootState): string | undefined => state.modular.selectedBasePlan;
export const selectModularSummary = (state: RootState): IAdapterData<IModularEnrolmentSummary> => state.modular.summary;
export const selectModularAlaCarteBenefits = (state: RootState): IAdapterData<IAlacarte[]> =>
    state.modular.alaCarteBenefits;
export const selectModularFamily = (state: RootState): IAdapterData<IModularFamily[]> => state.modular.family;
export const selectedAlaCarteBenefits = (state: RootState): string[] => state.modular.selectedAlaCarte;
export const selectModularCrudFamily = (state: RootState): IAdapterData<ICRUDResponse> => state.modular.crudFamily;
export const selectModularWalletBalance = (state: RootState): number | undefined => state.modular.balance;
export const selectModularPersonalEmail = (state: RootState): string | undefined => state.modular.personalEmail;
