import { CardIcon, LoopButton, Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import React, { memo } from 'react';
import { ArrowRightEmerald, CompanyIcon, FirstAidIcon } from '../../../assets/img';
import { formatAmount } from '../../../utils/common';
import { BasePlanCardIllustrations } from '../../../utils/constants/ModularConstants';
import {
    StyledModularPlanContent,
    StyledModularPlanCta,
    StyledModularPlanFamDef,
    StyledModularPlanItem,
    StyledModularPlanPremium,
    StyledModularPlanPremiumAmount,
    StyledModularPlanProperty,
    StyledModularPlanPropertyIcon,
    StyledModularPlanPropertyText,
    StyledModularPlanSelector,
    StyledModularPlanSelectorWrapper,
    StyledModularPlanSumInsured,
    StyledModularPlanTagline,
    StyledModularPlanTaglineWrapper,
    StyledModularHorizontalDivider,
    StyledModularPlanPlanHeader,
    StyledModularPlanPlanHeaderText,
    StyledModularPlanContentDetails
} from './styles';
import { IModularPlanItem } from './types';
import { getSortedBasePlanProperties } from './utils';
import { getFamilyDefinitionDescription } from '../../../utils/mapping';
import PolicyBenefitsModal from '../PolicyBenefitsModal';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const ModularPlanItem: React.FC<IModularPlanItem> = ({ plan, index, isSelected, onClick, maxProperties }) => {
    const sortedProperties = getSortedBasePlanProperties(plan, false, maxProperties);
    const [isCoverageVisible, setIsCoverageVisible] = React.useState(false);
    const trackClick = useSegment('click');
    const handlePlanSelection: React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (e.defaultPrevented || isSelected) return;
        onClick();
    };

    const handleViewCompleteCoverage = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault(); // to stop plan selection
        setIsCoverageVisible(true);
        const clickAction = SEGMENT_ACTIONS.CLICK.VIEW_COMPLETE_COVERAGE;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                plan_name: plan.name,
                plan_id: plan.id
            }
        });
    };

    return (
        <>
            <StyledModularPlanItem onClick={handlePlanSelection}>
                {plan.isDefault && (
                    <StyledModularPlanTaglineWrapper>
                        <StyledModularPlanTagline>
                            <img src={CompanyIcon} />
                            <Typography variant="small" weight="medium" color="emerald">
                                Company Recommended
                            </Typography>
                        </StyledModularPlanTagline>
                    </StyledModularPlanTaglineWrapper>
                )}
                <StyledModularPlanSelectorWrapper>
                    <StyledModularPlanSelector $selected={isSelected} />
                </StyledModularPlanSelectorWrapper>
                <StyledModularPlanPlanHeader $bg={BasePlanCardIllustrations[index]}>
                    <CardIcon imageUrl={plan.logo} />
                    <StyledModularPlanPlanHeaderText>
                        <Typography variant="large" weight="semiBold" color="tertiary">
                            {plan.name}
                        </Typography>
                        <Typography variant="extraSmall" color="tertiary">
                            {plan.description}
                        </Typography>
                    </StyledModularPlanPlanHeaderText>
                </StyledModularPlanPlanHeader>
                <StyledModularPlanContent $selected={isSelected}>
                    <StyledModularPlanContentDetails>
                        <StyledModularPlanSumInsured>
                            <Typography variant="extraSmall">Sum Insured</Typography>
                            <Tooltip
                                type="informative"
                                text="This Sum Insured is for the whole family covered in the plan."
                                beak="top-left"
                            >
                                <Typography variant="large" weight="semiBold" color="emerald">
                                    {formatAmount(plan.sumInsured)}
                                </Typography>
                            </Tooltip>
                        </StyledModularPlanSumInsured>
                        <StyledModularPlanFamDef>
                            <Typography variant="extraSmall">Family Members Covered</Typography>
                            <Typography variant="small" weight="semiBold">
                                {getFamilyDefinitionDescription(plan.familyDefinition)}
                            </Typography>
                        </StyledModularPlanFamDef>
                        <StyledModularPlanPremium>
                            <Typography variant="extraSmall">Premium to pay</Typography>
                            <StyledModularPlanPremiumAmount>
                                <Typography variant="title3">{formatAmount(plan.price)}</Typography>
                                <Typography variant="extraSmall" weight="semiBold">
                                    /yr
                                </Typography>
                            </StyledModularPlanPremiumAmount>
                        </StyledModularPlanPremium>
                        <Typography variant="small" weight="semiBold" color="secondary">
                            Plan Highlights:
                        </Typography>
                        {sortedProperties.map((sp, i) => (
                            <React.Fragment key={sp.name}>
                                {i !== 0 && <StyledModularHorizontalDivider />}
                                <StyledModularPlanProperty>
                                    <StyledModularPlanPropertyIcon src={sp.logo || FirstAidIcon} />
                                    <StyledModularPlanPropertyText>
                                        <Typography variant="extraSmall">{sp.name}</Typography>
                                        <Typography variant="extraSmall" weight="semiBold">
                                            {sp.value}
                                        </Typography>
                                    </StyledModularPlanPropertyText>
                                </StyledModularPlanProperty>
                            </React.Fragment>
                        ))}
                    </StyledModularPlanContentDetails>
                    <StyledModularPlanCta>
                        <LoopButton
                            variant="secondary"
                            size="small"
                            iconOrder="right"
                            iconSrc={ArrowRightEmerald}
                            onClick={handleViewCompleteCoverage}
                        >
                            View complete coverage
                        </LoopButton>
                    </StyledModularPlanCta>
                </StyledModularPlanContent>
            </StyledModularPlanItem>
            <PolicyBenefitsModal isVisible={isCoverageVisible} setIsVisible={setIsCoverageVisible} plan={plan} />
        </>
    );
};

export default memo(ModularPlanItem);
