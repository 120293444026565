import styled from 'styled-components';

export const StyledModularEnrolmentHeaderWrapper = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
`;

export const StyledModularEnrolmentHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
`;

export const StyledModularWalletWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colors.mutedTransparent};
    padding: 14px 16px;
    background-color: ${(p) => p.theme.colors.white};
`;

export const StyledWalletIcon = styled.img`
    width: 24px;
    height: 24px;
    aspect-ratio: 1;
`;

export const StyledModularHeaderFlexDown = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
    padding: 16px 32px;
    background-color: ${(p) => p.theme.colors.beyondGrey};
`;

export const StyledModularHeaderFlexDownCTA = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
`;

export const StyledCloseIcon = styled.img`
    cursor: pointer;
    width: 14px;
    height: 14px;
    aspect-ratio: 1;
`;
