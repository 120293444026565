import React, { useEffect, useMemo, useState } from 'react';
import {
    StyledBenefitCard,
    StyledBenefitDescription,
    StyledButton,
    StyledDetails,
    StyledDropDown,
    StyledDropDownContainer,
    StyledHeader,
    StyledHeaderText,
    StyledImageContainer,
    StyledLockInCard,
    StyledRow,
    StyledRowBetween,
    StyledRowPremiumPrice
} from './styles';
import { ArrowRightEmerald, Dismiss, LockIn, Plus } from '../../../assets/img';
import { CardIcon, Form, LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { StyledChip } from '../BenefitDetailsModal/styles';
import { ImageView } from '../../atoms/ImageView';
import { IBenefitCard } from './types';
import { formatAmount } from '../../../utils/common';
import theme from '../../../theme';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

export const BenefitCard: React.FunctionComponent<IBenefitCard> = ({
    plan,
    selectedBenefits,
    handleSelectPlan,
    handleReadMore,
    handleRemovePlan,
    handleUpdate,
    handleToggleEmailModal,
    isLoopBenefit,
    personalEmail
}) => {
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedPrice, setSelectedPrice] = useState(plan.price);
    const [pseudoSelect, setPseudoSelect] = useState(false);
    const selected = !!selectedItem || pseudoSelect;
    const trackClick = useSegment('click');

    const handleSelect = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const clickAction = SEGMENT_ACTIONS.CLICK.ADD_BENEFIT;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                benefit_name: plan.name,
                benefit_id: plan.id
            }
        });
        if (pseudoSelect && !selectedItem) setPseudoSelect(false);
        else if (selectedItem) {
            handleRemovePlan(selectedItem);
            setSelectedItem('');
            setPseudoSelect(false);
        } else {
            if (plan?.subCategories.length > 0) {
                setPseudoSelect(true);
            } else {
                handleSingleBenefit();
            }
        }
    };

    const handleSingleBenefit = () => {
        if (isLoopBenefit(plan) && !personalEmail) {
            handleToggleEmailModal(plan, plan.id);
        } else {
            const id = plan.id;
            handleSelectPlan(id);
            setSelectedItem(id);
        }
    };

    const handleClick = (e: string) => {
        const clickAction = SEGMENT_ACTIONS.CLICK.SELECT_PLAN;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                benefit_id: e,
                benefit_name: plan.name
            }
        });
        if (isLoopBenefit(plan) && !personalEmail) {
            handleToggleEmailModal(plan, e);
        } else {
            handleUpdate(selectedItem, e);
        }
    };

    const handleOnCardClick = (e: React.MouseEvent) => {
        if (e.isPropagationStopped() || e.isDefaultPrevented()) return;
        handleReadMore(plan);
    };

    const handleOnDropdownClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const subCategoryOptions = useMemo(() => {
        return plan.subCategories?.map((subCategory) => {
            return { name: `${subCategory.name} - ${formatAmount(subCategory.price)}`, value: subCategory.id };
        });
    }, [plan.subCategories, selectedBenefits]);

    useEffect(() => {
        if (plan?.subCategories?.length === 0 && selectedBenefits.includes(plan.id)) {
            setSelectedItem(plan.id);
        } else {
            const selectedSubCategory = plan?.subCategories?.find((subCategory) =>
                selectedBenefits.includes(subCategory.id)
            );
            if (selectedSubCategory) {
                setSelectedPrice(selectedSubCategory?.price);
                setSelectedItem(selectedSubCategory.id);
            }
        }
    }, [selectedBenefits, plan]);

    return (
        <StyledBenefitCard selected={selected} onClick={handleOnCardClick}>
            <StyledDetails>
                <StyledHeader>
                    <StyledHeader>
                        <CardIcon imageUrl={plan.logo} />
                        <StyledHeaderText>
                            <Typography variant={'large'} weight={'semiBold'}>
                                {plan.name}
                            </Typography>
                            {!!plan.sumInsured && (
                                <Typography variant={'small'}>
                                    {plan?.subCategories?.length === 0 ? '' : 'Cover Starts From '}
                                    <Typography variant={'small'} weight={'semiBold'}>
                                        {formatAmount(plan.sumInsured)}
                                    </Typography>
                                    <Typography variant={'small'}>
                                        {plan?.subCategories?.length === 0 ? ' Cover' : ''}
                                    </Typography>
                                </Typography>
                            )}
                        </StyledHeaderText>
                    </StyledHeader>
                    <div>
                        <LoopButton
                            borderColor={theme.colors.loopEmerald}
                            fontWeight={'semiBold'}
                            size={'small'}
                            variant="text"
                            onClick={handleSelect}
                        >
                            <StyledButton>
                                <StyledImageContainer src={selected ? Dismiss : Plus} height="16px" width="16px" />
                                <Typography variant={'extraSmall'}>{selected ? 'Remove' : 'Add Benefit'}</Typography>
                            </StyledButton>
                        </LoopButton>
                    </div>
                </StyledHeader>
                <StyledBenefitDescription>
                    <Typography variant={'small'}>{plan.description}</Typography>
                    <LoopButton
                        variant="text"
                        onClick={handleOnCardClick}
                        iconOrder="right"
                        iconSrc={ArrowRightEmerald}
                    >
                        View Details
                    </LoopButton>
                </StyledBenefitDescription>
                {plan.lockIn && (
                    <StyledLockInCard>
                        <StyledChip>
                            <ImageView src={LockIn} height="20px" width="20px" />
                            <Typography variant={'extraSmall'} weight={'semiBold'}>
                                {plan.lockIn}
                            </Typography>
                        </StyledChip>
                    </StyledLockInCard>
                )}
            </StyledDetails>
            <StyledRowBetween>
                <StyledRowPremiumPrice selected={selected}>
                    <Typography variant={'extraSmall'}>
                        {selected ? 'Premium To Pay' : 'Premium Starts From'}
                    </Typography>
                    <Typography variant={'extraSmall'} weight={'semiBold'}>
                        <Typography variant={'title2'} weight={'semiBold'}>
                            {formatAmount(selectedPrice)}
                        </Typography>
                        {'/yr'}
                    </Typography>
                </StyledRowPremiumPrice>
                {selected && plan?.subCategories?.length > 0 && (
                    <StyledRow>
                        <StyledDropDownContainer>
                            <Typography variant={'small'} weight={'medium'}>
                                {'Choose your Plan'}
                            </Typography>
                        </StyledDropDownContainer>
                        <StyledDropDown onClick={handleOnDropdownClick}>
                            <Form.Dropdown
                                placeholder="Select Plan"
                                listItems={subCategoryOptions}
                                onClick={(e: string) => handleClick(e)}
                                selectedItem={selectedItem}
                                autoDisplayItems={!selectedItem}
                                onClickOutside={() => {
                                    if (pseudoSelect) setPseudoSelect(false);
                                }}
                            />
                        </StyledDropDown>
                    </StyledRow>
                )}
            </StyledRowBetween>
        </StyledBenefitCard>
    );
};

export default BenefitCard;
