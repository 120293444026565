import React from 'react';
import { IGenericIconProps } from './types';

const Close: React.FC<IGenericIconProps> = ({ width = 24, height = 24, fill = '#FFF' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
        <path
            d="M17.9999 6C17.8124 5.81253 17.5581 5.70721 17.2929 5.70721C17.0278 5.70721 16.7735 5.81253 16.5859 6L11.9999 10.586L7.41394 6C7.22641 5.81253 6.9721 5.70721 6.70694 5.70721C6.44178 5.70721 6.18747 5.81253 5.99994 6C5.81247 6.18753 5.70715 6.44184 5.70715 6.707C5.70715 6.97216 5.81247 7.22647 5.99994 7.414L10.5859 12L5.99994 16.586C5.81247 16.7735 5.70715 17.0278 5.70715 17.293C5.70715 17.5582 5.81247 17.8125 5.99994 18C6.18747 18.1875 6.44178 18.2928 6.70694 18.2928C6.9721 18.2928 7.22641 18.1875 7.41394 18L11.9999 13.414L16.5859 18C16.7735 18.1875 17.0278 18.2928 17.2929 18.2928C17.5581 18.2928 17.8124 18.1875 17.9999 18C18.1874 17.8125 18.2927 17.5582 18.2927 17.293C18.2927 17.0278 18.1874 16.7735 17.9999 16.586L13.4139 12L17.9999 7.414C18.1874 7.22647 18.2927 6.97216 18.2927 6.707C18.2927 6.44184 18.1874 6.18753 17.9999 6Z"
            fill={fill}
        />
    </svg>
);

export default Close;
