import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect } from 'react';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
    StyledCoveredMember,
    StyledCoveredMembersFooter,
    StyledCoveredMembersHeader,
    StyledCoveredMembersList,
    StyledCoveredMembersModal,
    StyledCoveredMemberText
} from './styles';
import { ICoveredMembersModal } from './types';
import { capitalizeFirstLetter, formatFullName, getIconMapping } from '../../../utils/common';
import { Loader } from '../../atoms';
import theme from '../../../theme';
import ProfileIcon from '../../atoms/ProfileIcon';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const CoveredMembersModal: React.FC<ICoveredMembersModal> = ({
    members,
    title,
    subtitle,
    isVisible,
    setIsVisible,
    isLoading
}) => {
    const trackPage = useSegment('page');
    useEffect(() => {
        if (isVisible) {
            const clickAction = SEGMENT_ACTIONS.PAGE.COVERED_MEMBER_DETAILS;
            trackPage({
                name: clickAction.name,
                properties: {
                    ...clickAction.properties,
                    member_count: members?.length
                }
            });
        }
    }, [isVisible]);
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} disablePadding>
            <WithThemeProvider>
                <StyledCoveredMembersModal>
                    <StyledCoveredMembersHeader>
                        <Typography variant="large" weight="semiBold">
                            {title}
                        </Typography>
                        <Typography variant="extraSmall">{subtitle}</Typography>
                    </StyledCoveredMembersHeader>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <StyledCoveredMembersList>
                            {members.map((m) => (
                                <StyledCoveredMember key={m.id}>
                                    <ProfileIcon
                                        icon={getIconMapping(m.relationship, m.gender)}
                                        bgColor={theme.colors.coverYellow}
                                    />
                                    <StyledCoveredMemberText>
                                        <Typography variant="medium" weight="semiBold">
                                            {formatFullName(m.firstName, m.lastName)}
                                        </Typography>
                                        <Typography variant="extraSmall" weight="medium" color="secondary">
                                            {capitalizeFirstLetter(m.relationship)}
                                        </Typography>
                                    </StyledCoveredMemberText>
                                </StyledCoveredMember>
                            ))}
                        </StyledCoveredMembersList>
                    )}
                    <StyledCoveredMembersFooter>
                        <LoopButton variant="filled" onClick={() => setIsVisible(false)}>
                            Okay
                        </LoopButton>
                    </StyledCoveredMembersFooter>
                </StyledCoveredMembersModal>
            </WithThemeProvider>
        </Modal>
    );
};

export default CoveredMembersModal;
