import { IJSON } from './InsuranceTypeConstants';

export interface ISegmentAct {
    name: string;
    properties?: Record<string, unknown>;
    // eslint-disable-next-line camelcase
    login_status?: boolean;
}
interface ISegmentActions extends IJSON {
    PAGE: Record<string, ISegmentAct>;
    CLICK: Record<string, ISegmentAct>;
    IDENTIFY: Record<string, ISegmentAct>;
}
export const SEGMENT_ACTIONS: ISegmentActions = {
    PAGE: {
        LOGIN_PAGE: {
            name: 'login',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'login',
                login_status: (loginStatus: boolean) => loginStatus,
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl
            }
        },
        OTP_PAGE: {
            name: 'OTP PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'Otp',
                login_status: (loginStatus: boolean) => loginStatus,
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl
            }
        },
        TWOFA_PAGE: {
            name: 'TWO FA PAGE',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'Two FA Page',
                login_status: (loginStatus: boolean) => loginStatus,
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl
            }
        },
        CONFIRMATION_PAGE: {
            name: 'confirmation',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'confirmation',
                login_status: (loginStatus: boolean) => loginStatus,
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl,
                enrolling_in: (enrolledIN: string) => enrolledIN
            }
        },
        DASHBOARD_PAGE: {
            name: 'dashboard',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'dashboard',
                login_status: (loginStatus: boolean) => loginStatus,
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl
            }
        },
        ENROLMENT_PAGE: {
            name: 'enrolment',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'enrolment',
                login_status: (loginStatus: boolean) => loginStatus,
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl
            }
        },
        SUMMARY_PAGE: {
            name: 'summary',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'summary',
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl,
                login_status: (loginStatus: boolean) => loginStatus,
                enrolling_in: (enrolledIN: string) => enrolledIN,
                sum_insured: (sumInsured: number) => sumInsured,
                user_feedback: (feedback: string) => feedback
            }
        },
        WELCOME_PAGE: {
            name: 'welcome',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'welcome',
                login_status: (loginStatus: boolean) => loginStatus,
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl
            }
        },
        UNKNOWN_PAGE: {
            name: 'Unknown',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'Unknown',
                login_status: (loginStatus: boolean) => loginStatus,
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl
            }
        },
        FAMILY_TOPUP_PAGE: {
            name: 'family_topup',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'family_topup',
                login_status: (loginStatus: boolean) => loginStatus,
                referrer_page_title: (refPage: string) => refPage,
                referrer_page_url: (refUrl: string) => refUrl
            }
        },
        CHECKOUT_PAGE: {
            name: 'checkout',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'checkout',
                login_status: true,
                user_feedback: (feedback: string) => feedback,
                referrer_page_title: 'summary',
                referrer_page_url: '',
                sum_insured: (sumInsured: number) => sumInsured,
                enrolling_in: (enrollingIn: string) => enrollingIn
            }
        },
        POLICY_PLAN_SELECTION_PAGE: {
            name: 'policy_plan_selection_page',
            properties: {
                product_type: 'Flex',
                page_name: 'Policy_Plan_Selection_Page',
                page_category: 'Policy_Plan_Selection_Page'
            }
        },
        ADD_DEPENDENT_PAGE: {
            name: 'add_dependent_page',
            properties: {
                product_type: 'Flex',
                page_name: 'Add_Dependant_page',
                page_category: 'Add_Dependant_page'
            }
        },
        DEPENDENT_DETAILS_PAGE: {
            name: 'add_dependant_Details_page',
            properties: {
                product_type: 'Flex',
                page_name: 'dependant_Details_page',
                page_category: 'dependant_Details_page'
            }
        },
        ADD_MORE_DEPENDENTS_MODAL: {
            name: 'add_more_dependants_modal',
            properties: {
                product_type: 'Flex',
                page_name: 'Add_Dependant_page',
                page_category: 'Add_Dependant_page'
            }
        },
        INSURANCE_BENEFITS_PAGE: {
            name: 'insurance_benefits_page',
            properties: {
                product_type: 'Flex',
                page_name: 'Insurance_Benefits_page',
                page_category: 'Insurance_Benefits_page'
            }
        },
        PURCHASE_SUMMARY_PAGE: {
            name: 'purchase_summary_page',
            properties: {
                product_type: 'Flex',
                page_name: 'Purchase_summary_page',
                page_category: 'Purchase_summary_page'
            }
        },
        COVERED_MEMBER_DETAILS: {
            name: 'covered_members_details',
            properties: {
                product_type: 'Flex',
                page_name: 'Purchase_summary_page',
                page_category: 'Covered_Member_details_page'
            }
        },
        REGISTRATION_COMPLETE_PAGE: {
            name: 'registration_complete_page',
            properties: {
                product_type: 'Flex',
                page_name: 'registration_complete_page',
                page_category: 'registration_complete_page'
            }
        },
        REVIEW_REGISTRATION_MODAL: {
            name: 'review_registration_modal',
            properties: {
                product_type: 'Flex',
                page_name: 'Purchase_summary_page',
                page_category: 'Warning_popup_modal'
            }
        },
        ERROR: {
            name: 'error',
            properties: {
                product_type: 'Flex',
                page_name: 'Add_Dependant_page',
                page_category: 'Dependant_Details_page'
            }
        }
    },
    CLICK: {
        PROCEED_TO_BUY_SUMMARY: {
            name: 'PROCEED_TO_BUY_SUMMARY',
            properties: {
                describe: 'User has clicked on a Proceed to buy button on Summary Page',
                user_id: (userId: string) => `${userId}`,
                sum_insured: (sumInsured: string) => `${sumInsured}`,
                selected_members: (selectedMembers: string) => `${selectedMembers}`,
                deductible: (deductible: string) => `${deductible}`,
                annual_premium: (annualPremium: string) => `${annualPremium}`
            }
        },
        OUTLOOK_LOGIN: {
            name: 'OUTLOOK_LOGIN',
            properties: {
                login_status: false,
                page_title: 'login'
            }
        },
        GOOGLE_LOGIN: {
            name: 'GOOGLE_LOGIN',
            properties: {
                login_status: false,
                page_title: 'login'
            }
        },
        MOBILE_LOGIN: {
            name: 'MOBILE_LOGIN',
            login_status: false,
            properties: {
                login_status: false,
                mobile: (num: string) => num,
                page_title: 'login'
            }
        },
        LOGIN_VERIFY_OTP_BUTTON: {
            name: 'LOGIN_VERIFY_OTP_BUTTON',
            properties: {
                describe: 'User has chosen to login with mobile',
                mobile: (num: string) => num,
                page_title: 'enterOtp',
                login_status: false
            }
        },
        LOGIN_OAUTH: {
            name: 'LOGIN_OAUTH',
            properties: {
                describe: `User has chosen to login with OAuth`
            }
        },
        VERIFY_OTP: {
            name: 'VERIFY_OTP',
            properties: {
                describe: 'User has chosen to verify his OTP'
            }
        },
        RESEND_OTP: {
            name: 'RESEND_OTP',
            properties: {
                describe: 'User has chosen to resend OTP',
                mobile: (num: string) => num,
                page_title: 'enterOtp',
                login_status: false
            }
        },
        EDIT_PHONE_NUMBER: {
            name: 'EDIT_PHONE_NUMBER',
            properties: {
                describe: 'User has chosen to go back and edit his phone number'
            }
        },
        WELCOME_PAGE_PROCEED: {
            name: 'WELCOME_PAGE_PROCEED',
            properties: {
                describe: 'User has clicked on Proceed button on the Welcome Page',
                page_title: 'welcome',
                login_status: true
            }
        },
        NAVIGATION_HEADER_LOGO: {
            name: 'NAVIGATION_HEADER_LOGO',
            properties: {
                describe: 'User has clicked on the logo present in Navigation Header to redirect to dashboard.',
                page_title: 'dashboard',
                login_status: true
            }
        },
        NAVIGATION_HEADER_SIGNOUT: {
            name: 'NAVIGATION_HEADER_SIGNOUT',
            properties: {
                describe: 'User has clicked on the signout button in Navigation Header.'
            }
        },
        DASHBOARD_INSURANCE_CARD: {
            name: 'REGISTER_NOW',
            properties: {
                describe: 'User has clicked on the REGISTER_NOW button card on Dashboard Screen',
                policy_lapsed: (isLapsed: boolean) => `${isLapsed}`,
                // we are overriding the value of policy_id, policy_type & policy_status.
                // By default we take it from "currently_selected_policy" in enrolment slice.
                // but when a user clicks on a card, we want to record the policy details of that card,
                // and not what is present in the state of "currently_selected_policy".
                policy_id: (policyId: string) => `${policyId}`,
                enrolling_in: (policyType: string) => `${policyType}`,
                policy_status: (policyStatus: string) => `${policyStatus}`,
                login_status: true,
                page_title: 'dashboard'
            }
        },
        DASHBOARD_INSURANCE_CARD_REDO: {
            name: 'REDO_ENROLMENT',
            properties: {
                describe: 'User has clicked on the REGISTER_NOW button card on Dashboard Screen',
                policy_lapsed: (isLapsed: boolean) => `${isLapsed}`,
                policy_id: (policyId: string) => `${policyId}`,
                enrolling_in: (policyType: string) => `${policyType}`,
                policy_status: (policyStatus: string) => `${policyStatus}`,
                login_status: true,
                page_title: 'dashboard'
            }
        },
        ENROLMENT_SHOW_BREAKDOWN: {
            name: 'ENROLMENT_SHOW_BREAKDOWN',
            properties: {
                describe: `User has clicked on 'Show Breakdown'/'Hide Breakdown'
                in the bottom navigation bar of Enrolment Screen`,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                sum_insured: (sumInsured: number) => sumInsured,
                login_status: true,
                page_title: 'family_topup'
            }
        },
        PROCEED_WITHOUT_TOPUP_MODAL: {
            name: 'PROCEED_WITHOUT_TOPUP_MODAL',
            properties: {
                describe: `User Clicks on proceed without topup button to open modal`,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                sum_insured: (sumInsured: number) => sumInsured,
                sum_insurerd_selected: (selected: string) => selected,
                login_status: true,
                page_title: 'family_topup'
            }
        },
        ENROLMENT_NEXT_STEP: {
            name: 'ENROLMENT_NEXT_STEP',
            properties: {
                describe: `User has clicked on the next-step button in the bottom navigation bar of Enrolment Screen`,
                step: (step: number) => step
            }
        },
        PROCEED_TO_SELECT_SUPER_TOPUP: {
            name: 'PROCEED_TO_SELECT_SUPER_TOPUP',
            properties: {
                // eslint-disable-next-line max-len
                describe: `User has clicked on the proceed_to_select_super_topup btn in the bottom navigation on Enrolment Screen`,
                page_title: 'enrolment',
                login_status: true,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                step: (step: number) => step
            }
        },
        PROCEED_TO_BUY: {
            name: 'PROCEED_TO_BUY',
            properties: {
                describe: `User has clicked on the proceed_to_buy btn in the Checkout Screen`,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                sum_insured: (sumInsured: number) => sumInsured,
                page_title: (pageTile: string) => pageTile,
                enrolling_in: (enrollingIn: string) => enrollingIn,
                login_status: true,
                user_feedback: (feedback: string) => feedback
            }
        },
        SELECT_SUM_INSURED: {
            name: 'SELECT_SUM_INSURED',
            properties: {
                // eslint-disable-next-line max-len
                describe: `User has clicked on the select_sum_insured btn on family topup selection page`,
                page_title: 'family_topup',
                login_status: true,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                sum_insured: (sumInsured: number) => sumInsured,
                covered_members: (coveredMembers: string) => coveredMembers
            }
        },
        ENROLMENT_STEPPER_STEP: {
            name: 'ENROLMENT_STEPPER_STEP',
            properties: {
                describe: 'User has clicked on a step of the Stepper on enrolment screen',
                step: (step: number) => step,
                page_title: (pageTitle: string) => pageTitle,
                login_status: true
            }
        },
        ENROLMENT_ADD_MEMBER_CARD: {
            name: 'ENROLMENT_ADD_MEMBER_CARD',
            properties: {
                describe: 'User has clicked on a step of the stepper on enrolment screen',
                relation_type: (relation: string) => relation,
                login_status: true,
                page_title: 'Enrolment'
            }
        },
        PROCEED_WITH_TOPUP: {
            name: 'PROCEED_WITH_TOPUP',
            properties: {
                describe: 'User has clicked on a add topup on SuperTopUp page',
                sum_insured: (sumInsured: string) => sumInsured,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                login_status: true,
                page_title: 'family_topup'
            }
        },
        PROCEED_WITHOUT_TOPUP: {
            name: 'PROCEED_WITHOUT_TOPUP',
            properties: {
                describe: 'User has clicked on a proceed without selecting topup on SuperTopUp page',
                sum_insured: (sumInsured: string) => sumInsured
            }
        },
        SELECT_TOPUP_IN_MODAL: {
            name: 'SELECT_TOPUP_IN_MODAL',
            properties: {
                describe: 'User has clicked on a select TopUp in proceed without top up Modal',
                page_title: (pageTitle: string) => pageTitle,
                login_status: true,
                sum_insured: (sumInsured: number) => sumInsured,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                sum_insurerd_selected: (selected: string) => selected
            }
        },
        ADD_TOPUP: {
            name: 'ADD_TOPUP',
            properties: {
                describe: `User has clicked on the ADD_TOPUP btn in the bottom navigation on Enrolment Screen`,
                page_title: 'family_topup',
                login_status: true,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                sum_insured: (sumInsured: string) => sumInsured,
                step: (step: number) => step,
                user_feedback: (feedback: string) => feedback
            }
        },
        UNSELECT_TOPUP_IN_MODAL: {
            name: 'UNSELECT_TOPUP_IN_MODAL',
            properties: {
                page_title: (pageTitle: string) => pageTitle,
                login_status: true,
                sum_insured: (sumInsured: number) => sumInsured,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                user_feedback: (feedback: string) => feedback,
                sum_insurerd_selected: (selected: string) => selected
            }
        },
        ENROLMENT_EDIT_MEMBER_CARD: {
            name: 'ENROLMENT_EDIT_MEMBER_CARD',
            properties: {
                describe: (dependentId: string) =>
                    `User has clicked on editing the information on their dependent: ${dependentId}`,
                dependent_id: (dependentId: string) => dependentId,
                relation_type: (relation: string) => relation,
                page_title: 'Enrolment',
                login_status: true
            }
        },
        ENROLMENT_DELETE_MEMBER_CARD: {
            name: 'ENROLMENT_DELETE_MEMBER_CARD',
            properties: {
                describe: (dependentId: string) =>
                    `User has clicked on deleting the information on their dependent: ${dependentId}`,
                dependent_id: (dependentId: string) => dependentId,
                relation_type: (relation: string) => relation,
                page_title: 'Enrolment',
                login_status: true
            }
        },
        ENROLMENT_DELETE_MEMBER_CARD_CONFIRM: {
            name: 'ENROLMENT_DELETE_MEMBER_CARD',
            properties: {
                describe: (dependentId: string) =>
                    `User has clicked on deleting the information on their dependent: ${dependentId}`,
                dependent_id: (dependentId: string) => dependentId,
                relation_type: (relation: string) => relation,
                modal_type: 'DELETE'
            }
        },
        SAVE_DETAILS_MEMBER_CARD_CONFIRM: {
            name: 'SAVE_DETAILS_MEMBER_CARD_CONFIRM',
            properties: {
                describe: 'User has clicked on save details enrolment in Modal',
                dependent_id: (dependentId: string) => dependentId,
                relation_type: (relation: string) => relation,
                modal_type: (modal: string) => modal,
                page_title: 'Enrolment',
                login_status: true
            }
        },
        COMPLETE_REGISTRATION: {
            name: 'COMPLETE_REGISTRATION',
            properties: {
                describe: `User has clicked on the COMPLETE_REGISTRATION btn in the Modal`,
                page_title: (title: string) => title,
                login_status: true,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                policy_id: (policy: string) => policy,
                sum_insured: (sumInsured: number) => sumInsured
            }
        },
        SUMMARY_GO_BACK: {
            name: 'SUMMARY_GO_BACK',
            properties: {
                describe: (buttonId: string) =>
                    `User has chosen to go back to the enrolment page via button: ${buttonId}`,
                button_id: (buttonId: string) => buttonId,
                page_title: 'summary',
                login_status: true,
                sum_insured: (sumInsured: number) => sumInsured,
                enrolling_in: (enrolledIN: string) => enrolledIN,
                user_feedback: (feedback: string) => feedback
            }
        },
        SUMMARY_CONFIRM_CHECKOUT: {
            name: 'SUMMARY_CONFIRM_CHECKOUT',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                describe: 'User has clicked on confirm enrolment in Summary Page',
                enrolling_in: (enrollingIn: string) => enrollingIn,
                sum_insured: (sumInsured: string) => sumInsured,
                page_title: 'summary',
                login_status: true,
                user_feedback: (feedback: string) => feedback
            }
        },
        SUMMARY_CONFIRM: {
            name: 'SUMMARY_CONFIRM',
            properties: {
                describe: 'User has clicked on confirm enrolment in Summary Page',
                policy_type: (policyType: string) => policyType,
                insurer_name: (insurerName: string) => insurerName,
                insurer_logo: (insurerLogo: string) => insurerLogo,
                sum_insured: (sumInsured: string) => sumInsured,
                family_structure: (familyStructure: string) => familyStructure,
                dependent1: (dependent1: string) => dependent1,
                dependent2: (dependent2: string) => dependent2,
                dependent3: (dependent3: string) => dependent3,
                dependent4: (dependent4: string) => dependent4,
                dependent5: (dependent5: string) => dependent5,
                dependent6: (dependent6: string) => dependent6,
                dependent7: (dependent7: string) => dependent7,
                dependent8: (dependent8: string) => dependent8,
                dependent9: (dependent9: string) => dependent9,
                dependent10: (dependent10: string) => dependent10,
                topup1: (topup1: string) => topup1,
                topup2: (topup2: string) => topup2,
                topup3: (topup3: string) => topup3
            }
        },
        CONFIRMATION_ENROL_PENDING_POLICIES: {
            name: 'REGISTER_NOW',
            properties: {
                describe: (policyId: string) =>
                    `User has choosen/clicked to enrol into another policy with policyId: ${policyId}`,
                // we are overriding the value of policy_id, policy_type & policy_status.
                // By default we take it from "currently_selected_policy" in enrolment slice.
                // but when a user clicks on a "pending-enrolment" card, we want to record the policy details of
                // that card, and not what is present in the state of "currently_selected_policy".
                policy_id: (policyId: string) => `${policyId}`,
                policy_type: (policyType: string) => `${policyType}`,
                policy_status: (policyStatus: string) => `${policyStatus}`,
                page_title: 'dashboard',
                login_status: true,
                enrolling_in: (enrolledIN: string) => enrolledIN
            }
        },
        CONFIRMATION_REDIRECT_TO_STORE: {
            name: 'CONFIRMATION_REDIRECT_TO_STORE',
            properties: {
                describe: (store: string) =>
                    `User has chosen/clicked to redirect to the ${store} Store to download the loophealt app`,
                store: (store: string) => `${store} Store`
            }
        },
        CONFIRMATION_REDIRECT_TO_TOPUP: {
            name: 'CONFIRMATION_REDIRECT_TO_TOPUP',
            properties: {
                describe: `User has chosen/clicked to redirect to the topup`
            }
        },
        EXPLORE_BENEFITS: {
            name: 'EXPLORE BENEFITS',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                describe: 'User has clicked on EXPLORE_BENEFITS on family topup page',
                page_title: 'family_topup',
                login_status: true
            }
        },
        CALL_AN_EXPERT: {
            name: 'CALL AN EXPERT',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'family_topup',
                login_status: true,
                cta_id: (cta: number) => cta
            }
        },
        CHAT_WITH_US: {
            name: 'CHAT WITH US',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'family_topup',
                login_status: true
            }
        },
        enrolment_chatbot: {
            name: 'enrolment_chatbot',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: (pageTile: string) => pageTile,
                login_status: true
            }
        },
        SCHEDULE_A_CALLBACK: {
            name: 'SCHEDULE A CALLBACK',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'family_topup',
                login_status: true
            }
        },
        REMOVE_TOPUP: {
            name: 'REMOVE TOPUP',
            properties: {
                describe: `User Clicks on remove topup button to open modal`,
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                sum_insured: (sumInsured: string) => sumInsured,
                login_status: true,
                page_title: 'Summary',
                user_feedback: (feedback: string) => feedback
            }
        },
        PROCEED_SUMMARY: {
            name: 'PROCEED_SUMMARY',
            properties: {
                describe: 'User has clicked on a proceed without selecting topup on SuperTopUp page',
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'enrolment',
                login_status: true,
                step: (step: number) => step,
                topup_available: false
            }
        },
        DASHBOARD_PAGE_POLICY_BENEFITS: {
            name: 'DASHBOARD_PAGE_POLICY_BENEFITS',
            properties: {
                describe: 'User has clicked on a on Policy Benefits on Bridge page',
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'dashboard',
                policy_id: (policyId: string) => policyId,
                login_status: true
            }
        },
        DASHBOARD_PAGE_DOWNLOAD_APP: {
            name: 'DASHBOARD_PAGE_DOWNLOAD_APP',
            properties: {
                describe: 'User has clicked on a on Download App Card',
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'dashboard',
                login_status: true,
                banner_position: (position: string) => position
            }
        },
        SUBMIT_AND_PROCEED: {
            name: 'SUBMIT_AND_PROCEED',
            properties: {
                describe: 'User has clicked on a on SUBMIT_AND_PROCEED',
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                policy_id: (policyId: string) => policyId,
                page_title: 'enrolment',
                login_status: true,
                banner: true
            }
        },
        REGISTERED_MEMBERS: {
            name: 'REGISTERED_MEMBERS',
            properties: {
                describe: 'User has clicked on a on View Members',
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: (title: string) => title,
                login_status: true,
                policy_id: (policyId: string) => policyId,
                action_type: (action: string) => action,
                registered_members: (registeredMembers: number) => registeredMembers,
                total_members: (totalMembers: number) => totalMembers
            }
        },
        DASHBOARD_PAGE_POLICY_BENEFITS_CROSSED: {
            name: 'DASHBOARD_PAGE_POLICY_BENEFITS_CROSSED',
            properties: {
                page_url: (path: string) => path,
                page_id: (path: string) => path,
                page_title: 'Dashboard',
                login_status: true,
                click_location: (action: string) => action
            }
        },
        CHOOSE_PLAN: {
            name: 'choose_plan',
            properties: {
                product_type: 'Flex',
                page_name: 'Policy_Plan_Selection_Page',
                page_category: 'Policy_Plan_Selection_Page'
            }
        },
        VIEW_COMPLETE_COVERAGE: {
            name: 'view_complete_coverage',
            properties: {
                product_type: 'Flex',
                page_name: 'Policy_Plan_Selection_Page',
                page_category: 'Policy_Plan_Selection_Page'
            }
        },
        PROCEED: {
            name: 'proceed',
            properties: {
                product_type: 'Flex'
            }
        },
        ADD_DEPENDENT: {
            name: 'add_dependent',
            properties: {
                product_type: 'Flex',
                page_name: 'Add_Dependant_page',
                page_category: 'Add_Dependant_page'
            }
        },
        SAVE_DETAILS: {
            name: 'save_details',
            properties: {
                product_type: 'Flex',
                page_name: 'Add_Dependant_page',
                page_category: 'Dependant_Details_page'
            }
        },
        ADD_MORE_DEPENDENTS: {
            name: 'add_more_dependents',
            properties: {
                product_type: 'Flex',
                page_name: 'Add_Dependant_page',
                page_category: 'Warning_popup_modal'
            }
        },
        READ_MORE: {
            name: 'read_more',
            properties: {
                product_type: 'Flex',
                page_name: 'Insurance_Benefits_page',
                page_category: 'Insurance_Benefits_page'
            }
        },
        ADD_BENEFIT: {
            name: 'add_benefit',
            properties: {
                product_type: 'Flex',
                page_name: 'Insurance_Benefits_page',
                page_category: 'Insurance_Benefits_page'
            }
        },
        SELECT_PLAN: {
            name: 'select_plan',
            properties: {
                product_type: 'Flex',
                page_name: 'Insurance_Benefits_page',
                page_category: 'Insurance_Benefits_page'
            }
        },
        VIEW_DETAILS: {
            name: 'view_details',
            properties: {
                product_type: 'Flex',
                page_name: 'Purchase_summary_page',
                page_category: 'Purchase_summary_page'
            }
        },
        VIEW_COVERED_MEMBERS: {
            name: 'covered_members',
            properties: {
                product_type: 'Flex',
                page_name: 'Purchase_summary_page',
                page_category: 'Purchase_summary_page'
            }
        },
        EDIT_DETAILS: {
            name: 'choose_plan',
            properties: {
                product_type: 'Flex',
                page_name: 'Purchase_summary_page',
                page_category: 'Covered_Member_details_page'
            }
        },
        HOME_BUTTON: {
            name: 'home_button',
            properties: {
                product_type: 'Flex',
                page_name: 'registration_complete_page',
                page_category: 'registration_complete_page'
            }
        },
        PROCEED_TO_NEXT_POLICY: {
            name: 'proceed_to_next_policy',
            properties: {
                product_type: 'Flex',
                page_name: 'registration_complete_page',
                page_category: 'registration_complete_page'
            }
        },
        BACK_BUTTON: {
            name: 'back_button',
            properties: {
                product_type: 'Flex'
            }
        }
    },
    TASK: {
        LOGIN_SUCCESS: {
            name: 'LOGIN_SUCCESS',
            properties: {
                login_status: true,
                task: 'login',
                status: true,
                login_method: (loginMethod: string) => loginMethod,
                page_url: '/login',
                page_id: '/login',
                page_title: 'login'
            }
        },
        PAYMENT_SUCCESS: {
            name: 'PAYMENT_SUCCESS',
            properties: {
                login_status: true,
                task: 'Payment',
                status: true,
                paid_amount: (amount: string) => amount
            }
        },
        PAYMENT_FAILED: {
            name: 'PAYMENT_FAILED',
            properties: {
                login_status: true,
                task: 'Payment',
                status: true,
                paid_amount: (amount: string) => amount
            }
        },
        LOGIN_FAIL: {
            name: 'LOGIN_FAIL',
            properties: {
                page_url: '/login',
                page_id: '/login',
                page_title: 'login',
                login_status: false,
                task: 'login',
                status: false,
                login_method: (loginMethod: string) => loginMethod
            }
        }
    },
    IDENTIFY: {
        USER_LOGIN: { name: 'USER_LOGIN' },
        USER_LOGOUT: { name: 'USER_LOGOUT' }
    }
};
