import { Carousel, LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React, { useState } from 'react';
import {
    StyledOnboardingScreenCTA,
    StyledOnboardingScreenFtuxItem,
    StyledOnboardingScreenFtuxWrapper,
    StyledOnboardingScreenImage,
    StyledOnboardingScreenItemColumn,
    StyledOnboardingScreenItemHeader,
    StyledOnboardingScreenItemHeaderBadge,
    StyledOnboardingScreenItemHeaderBadgeWrapper
} from './styles';
import { IOnboardingScreenFtuxProps } from './types';
import { OnboardingScreenFtuxSteps } from './constants';

const OnboardingScreenFtux: React.FC<IOnboardingScreenFtuxProps> = ({ onClose }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const totalSteps = OnboardingScreenFtuxSteps.length;
    const isLastStep = currentStep === totalSteps - 1;
    const currentStepItem = OnboardingScreenFtuxSteps[currentStep];

    const handleNextClick = () => {
        if (isLastStep) onClose();
        else setCurrentStep(currentStep + 1);
    };

    const handleBackClick = () => {
        setCurrentStep(currentStep - 1);
    };
    return (
        <StyledOnboardingScreenFtuxWrapper>
            <StyledOnboardingScreenFtuxItem>
                <StyledOnboardingScreenItemHeader>
                    <StyledOnboardingScreenItemHeaderBadgeWrapper>
                        {currentStepItem.showBadge && (
                            <StyledOnboardingScreenItemHeaderBadge>
                                <Typography variant="extraSmall" weight="medium" color="emerald">
                                    👋 Welcome to Loop!
                                </Typography>
                            </StyledOnboardingScreenItemHeaderBadge>
                        )}
                        <StyledOnboardingScreenItemColumn>
                            {currentStepItem.title}
                            <Typography variant="large">{currentStepItem.subtitle}</Typography>
                        </StyledOnboardingScreenItemColumn>
                    </StyledOnboardingScreenItemHeaderBadgeWrapper>
                </StyledOnboardingScreenItemHeader>
                <StyledOnboardingScreenItemColumn>
                    <Carousel variant="points" steps={totalSteps} activeStep={currentStep} setStep={setCurrentStep} />
                    <StyledOnboardingScreenCTA>
                        {currentStepItem.backButton && (
                            <LoopButton variant="secondary" onClick={handleBackClick}>
                                Back
                            </LoopButton>
                        )}
                        <LoopButton variant="filled" onClick={handleNextClick}>
                            {currentStepItem.nextButtonText}
                        </LoopButton>
                    </StyledOnboardingScreenCTA>
                </StyledOnboardingScreenItemColumn>
            </StyledOnboardingScreenFtuxItem>
            <StyledOnboardingScreenFtuxItem>
                <StyledOnboardingScreenImage src={currentStepItem.image} />
            </StyledOnboardingScreenFtuxItem>
        </StyledOnboardingScreenFtuxWrapper>
    );
};

export default OnboardingScreenFtux;
