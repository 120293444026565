import { IModularFamily } from '../redux/slices/ModularEnrolmentSlice/types';
import { IDependent } from '../redux/slices/PolicyListSlice/types';

export interface IMappings {
    totalDependents: number;
    value: string;
    dependents: { relation: string; number: number }[] | null;
    description?: string;
}

export type IFamilyDefinition = Record<string, IMappings>;

export const familyDefinition: IFamilyDefinition = {
    'Self': {
        totalDependents: 0,
        value: 'Self',
        description: 'Self',
        dependents: null
    },
    'Self + Spouse': {
        totalDependents: 1,
        value: 'Self + Spouse',
        description: 'Self & Spouse',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            }
        ]
    },
    'Self + Spouse + 1 Kid': {
        totalDependents: 2,
        value: 'Self + Spouse + 1 Kid',
        description: 'Self, Spouse & 1 Child',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 1
            }
        ]
    },
    'Self + Spouse + 2 Kids': {
        totalDependents: 3,
        value: 'Self + Spouse + 2 Kids',
        description: 'Self, Spouse & 2 Children',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids': {
        totalDependents: 4,
        value: 'Self + Spouse + 3 Kids',
        description: 'Self, Spouse & 3 Children',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            }
        ]
    },
    'Self + Spouse + 4 Kids': {
        totalDependents: 5,
        value: 'Self + Spouse + 4 Kids',
        description: 'Self, Spouse & 4 Children',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            }
        ]
    },
    'Self + Spouse + 2 Kids + Parents': {
        totalDependents: 5,
        value: 'Self + Spouse + 2 Kids + Parents',
        description: 'Self, Spouse, 2 Children & 2 Parents',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            },
            {
                relation: 'parent',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids + Parents': {
        totalDependents: 6,
        value: 'Self + Spouse + 3 Kids + Parents',
        description: 'Self, Spouse, 3 Children & 2 Parents',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            },
            {
                relation: 'parent',
                number: 2
            }
        ]
    },
    'Self + Spouse + 4 Kids + Parents': {
        totalDependents: 7,
        value: 'Self + Spouse + 4 Kids + Parents',
        description: 'Self, Spouse, 4 Children & 2 Parents',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            },
            {
                relation: 'parent',
                number: 2
            }
        ]
    },
    'Self + Spouse + 2 Kids + Parents (including in-laws)': {
        totalDependents: 5,
        value: 'Self + Spouse + 2 Kids + Parents (including in-laws)',
        description: 'Self, Spouse, 2 Children, any 2 of Parents and Parents-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            },
            {
                relation: 'parent/parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids + Parents (including in-laws)': {
        totalDependents: 6,
        value: 'Self + Spouse + 3 Kids + Parents (including in-laws)',
        description: 'Self, Spouse, 3 Children, any 2 of Parents and Parents-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            },
            {
                relation: 'parent/parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 4 Kids + Parents (including in-laws)': {
        totalDependents: 7,
        value: 'Self + Spouse + 4 Kids + Parents (including in-laws)',
        description: 'Self, Spouse, 4 Children, any 2 of Parents and Parents-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            },
            {
                relation: 'parent/parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 2 Kids + Parents OR parent-in-laws': {
        totalDependents: 5,
        value: 'Self + Spouse + 2 Kids + Parents OR parent-in-laws',
        description: 'Self, Spouse, 2 Children, Parents or Parents-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            },
            {
                relation: 'parent|parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids + Parents OR parent-in-laws': {
        totalDependents: 6,
        value: 'Self + Spouse + 3 Kids + Parents OR parent-in-laws',
        description: 'Self, Spouse, 3 Children, Parents or Parents-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            },
            {
                relation: 'parent|parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 4 Kids + Parents OR parent-in-laws': {
        totalDependents: 7,
        value: 'Self + Spouse + 4 Kids + Parents OR parent-in-laws',
        description: 'Self, Spouse, 4 Children, Parents or Parents-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            },
            {
                relation: 'parent|parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 2 Kids + Parents + Parents-in-law': {
        totalDependents: 7,
        value: 'Self + Spouse + 2 Kids + Parents + Parents-in-law',
        description: 'Self, Spouse, 2 Children, Parents & Parents-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 2
            },
            {
                relation: 'parent',
                number: 2
            },
            {
                relation: 'parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 3 Kids + Parents + Parents-in-law': {
        totalDependents: 8,
        value: 'Self + Spouse + 3 Kids + Parents + Parents-in-law',
        description: 'Self, Spouse, 3 Children, Parents & Parents-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 3
            },
            {
                relation: 'parent',
                number: 2
            },
            {
                relation: 'parent-in-law',
                number: 2
            }
        ]
    },
    'Self + Spouse + 4 Kids + Parents + Parents-in-law': {
        totalDependents: 9,
        value: 'Self + Spouse + 4 Kids + Parents + Parents-in-law',
        description: 'Self, Spouse, 4 Children, Parents & Parents-in-laws',
        dependents: [
            {
                relation: 'spouse',
                number: 1
            },
            {
                relation: 'child',
                number: 4
            },
            {
                relation: 'parent',
                number: 2
            },
            {
                relation: 'parent-in-law',
                number: 2
            }
        ]
    },
    'Parents Only': {
        totalDependents: 2,
        value: 'Parents Only',
        description: 'Parents Only',
        dependents: [
            {
                relation: 'parent',
                number: 2
            }
        ]
    },
    'Parents (including in-laws)': {
        totalDependents: 2,
        value: 'Parents (including in-laws)',
        description: 'Any 2 of Parents and Parents-in-laws',
        dependents: [
            {
                relation: 'parent/parent-in-law',
                number: 2
            }
        ]
    },
    'Parents OR parents-in-law': {
        totalDependents: 2,
        value: 'Parents OR parents-in-law',
        description: 'Parents or Parents-in-laws',
        dependents: [
            {
                relation: 'parent|parent-in-law',
                number: 2
            }
        ]
    },
    'Parents + Parents-in-law': {
        totalDependents: 4,
        value: 'Parents + Parents-in-law',
        description: 'Parents & Parents-in-laws',
        dependents: [
            {
                relation: 'parent',
                number: 2
            },
            {
                relation: 'parent-in-law',
                number: 2
            }
        ]
    }
};

export const getDependentMappings = (
    dependents: IDependent[] | IModularFamily[],
    familyStruture: string
): IMappings => {
    const value: IMappings = {
        value: familyStruture,
        dependents: [],
        totalDependents: 0
    };

    const newDependents: { relation: string; number: number }[] = [];

    const isIDependentArray = (dependents: IDependent[] | IModularFamily[]): dependents is IDependent[] => {
        return (dependents as IDependent[])[0]?.relationship !== undefined;
    };

    if (isIDependentArray(dependents)) {
        const totalDependentsExceptSelf = dependents?.filter((dependent) => {
            return dependent.relationship.toLocaleLowerCase() !== 'self';
        });

        totalDependentsExceptSelf.forEach((dependent) => {
            value.totalDependents += 1;
            value.dependents?.push({ relation: dependent.relationship.toLocaleLowerCase(), number: 1 });
        });

        value.dependents?.forEach((item) => {
            const items = value.dependents?.filter((itemAlt) => {
                return item.relation.toLocaleLowerCase() === itemAlt.relation.toLocaleLowerCase();
            });
            const index = newDependents.findIndex((itemAlt) => {
                return itemAlt.relation === item.relation;
            });
            if (index == -1 && items?.length) {
                newDependents.push({ relation: item.relation, number: items?.length });
            }
        });

        value.dependents = newDependents;
    }
    return value;
};

export const getFamilyDefinitionDescription = (familyDef: keyof IFamilyDefinition): string => {
    return familyDefinition[familyDef]?.description || familyDef;
};
