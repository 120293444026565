import React from 'react';
import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { IWarningModal } from './types';
import {
    StyledBulletPoint,
    StyledButtonContainer,
    StyledDescriptionContainer,
    StyledDetailsContainer,
    StyledIconContainer,
    StyledModalContainer
} from './styles';
import { Edit } from '../../../../assets/img/CustomIcons';

const WarningModal: React.FC<IWarningModal> = ({ isVisible, setIsVisible, onConfirm }) => {
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} disablePadding>
            <WithThemeProvider>
                <StyledModalContainer>
                    <StyledIconContainer>
                        <Edit />
                    </StyledIconContainer>

                    <StyledDetailsContainer>
                        <Typography variant="large" weight="semiBold">
                            Re-registering will lead to earlier choices being reset
                        </Typography>
                    </StyledDetailsContainer>

                    <StyledDescriptionContainer>
                        <Typography variant="small">Please note:</Typography>
                        <Typography variant="small">
                            <StyledBulletPoint>
                                This will lead to previous selections including dependants info, being reset and removed
                            </StyledBulletPoint>
                        </Typography>
                        <Typography variant="small">
                            <StyledBulletPoint>
                                Ensure you complete all the steps till summary confimation to save your selections
                            </StyledBulletPoint>
                        </Typography>
                    </StyledDescriptionContainer>

                    <StyledButtonContainer>
                        <LoopButton variant="filled" onClick={onConfirm}>
                            Re-do registration
                        </LoopButton>
                        <LoopButton variant="outline" onClick={() => setIsVisible(false)}>
                            Never mind
                        </LoopButton>
                    </StyledButtonContainer>
                </StyledModalContainer>
            </WithThemeProvider>
        </Modal>
    );
};

export default WarningModal;
