import React from 'react';
import { LoopTag, Typography } from '@loophealth/loop-ui-web-library';
import { StyledTagTextContainer, StyledTagWrapper } from './styles';
import { IPolicyStatusTag } from './types';

const PolicyStatusTag: React.FC<IPolicyStatusTag> = ({ icon, step, status }) => {
    const colorVariant = status === 'PENDING' ? 'error' : 'emerald';
    const bgVariant = status === 'PENDING' ? 'error' : 'default';

    return (
        <StyledTagWrapper $status={status}>
            <LoopTag variant={bgVariant} iconSrc={icon} iconOrder={'left'}>
                <StyledTagTextContainer>
                    <Typography variant="extraSmall" color={colorVariant}>
                        STEP {step}:
                    </Typography>
                    <Typography variant="extraSmall" weight="bold" color={colorVariant}>
                        {status}
                    </Typography>
                </StyledTagTextContainer>
            </LoopTag>
        </StyledTagWrapper>
    );
};

export default PolicyStatusTag;
