import React from 'react';
import { IGenericIconProps } from './types';

const CheckMark: React.FC<IGenericIconProps> = ({ width = 20, height = 20, fill = '#FDD506' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
    >
        <path
            // eslint-disable-next-line max-len
            d="M9.99984 1.66797C5.40817 1.66797 1.6665 5.40964 1.6665 10.0013C1.6665 14.593 5.40817 18.3346 9.99984 18.3346C14.5915 18.3346 18.3332 14.593 18.3332 10.0013C18.3332 5.40964 14.5915 1.66797 9.99984 1.66797ZM13.9832 8.08464L9.25817 12.8096C9.1415 12.9263 8.98317 12.993 8.8165 12.993C8.64984 12.993 8.4915 12.9263 8.37484 12.8096L6.0165 10.4513C5.77484 10.2096 5.77484 9.80964 6.0165 9.56797C6.25817 9.3263 6.65817 9.3263 6.89984 9.56797L8.8165 11.4846L13.0998 7.2013C13.3415 6.95964 13.7415 6.95964 13.9832 7.2013C14.2248 7.44297 14.2248 7.83464 13.9832 8.08464Z"
            fill={fill}
        />
    </svg>
);

export { CheckMark };
