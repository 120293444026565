import { FirebaseRemoteConfig } from '../../../adapters/provider';
import { IDependent, IPolicy } from '../../../redux/slices/PolicyListSlice/types';
import { getAgeFromDOB, getSumInsured, RecommendedConfig } from '../../../utils/common';
import { SlabCheckCompany } from '../../../utils/constants/CompanyConstants';
import { IFamilyComposition, RecommendedSIElement } from '../../../utils/types';

export const validateSLABS = (sumInsuredSlabs: number[], companyId: string): number[] => {
    const companyIDs = FirebaseRemoteConfig.getValue('CompaniesSILimit').asString();
    const companyIDsArray: SlabCheckCompany[] = companyIDs && JSON.parse(companyIDs);
    const companyEntryRemote = companyIDsArray && companyIDsArray?.find((entry) => entry.id === companyId);
    // const companyEntry = SLAB_CHECK_COMPANY_ID.find((entry) => entry.id === companyId);
    if (companyEntryRemote) {
        return sumInsuredSlabs.filter((sumInsured) => sumInsured <= companyEntryRemote.slabs);
    }
    return sumInsuredSlabs;
};

export const familyComposition = (users: IDependent[]): IFamilyComposition => {
    const selfUserCount = users.find((user: { relationship: string }) => user.relationship === 'self') ? 1 : 0;
    const spouseUserCount = users.find((user: { relationship: string }) => user.relationship === 'spouse') ? 1 : 0;
    const childUserCount = users.filter((user: { relationship: string }) => user.relationship === 'child').length;
    const parentUserCount = users.filter((user: { relationship: string }) => user.relationship === 'parent').length;
    const parentInLawUserCount = users.filter((user: { relationship: string }) => user.relationship === 'parent-in-law')
        .length;

    return {
        selfUserCount,
        spouseUserCount,
        childUserCount,
        parentUserCount,
        parentInLawUserCount
    };
};

export const getHighestAgeUser = (users: IDependent[]): number => {
    let maxAge = 0;
    const currentTimestamp = Date.now() / 1000; // convert milliseconds to seconds
    for (const user of users) {
        // calculate age in years
        const age = Math.floor((currentTimestamp - (user?.doB || 0)) / (365.25 * 24 * 60 * 60));
        if (age > maxAge) {
            maxAge = age;
        }
    }
    return maxAge;
};

export const getRecommendedSIDetails = (
    policyType: string,
    highestAge: number,
    defaultConfig: RecommendedConfig | any,
    membersCount: number
): RecommendedSIElement => {
    const data = (defaultConfig?.recommendedSI?.[policyType]).find(
        (item: { AgeRange: number[]; FamilyMembers: number[] }) => {
            return (
                highestAge >= item.AgeRange[0] &&
                highestAge <= item.AgeRange[1] &&
                membersCount >= item.FamilyMembers[0] &&
                membersCount <= item.FamilyMembers[1]
            );
        }
    );
    return data;
};

export const haveKids = (dependents: IDependent[]): boolean => {
    let haveKids = false;

    dependents.forEach((dependent) => {
        if (dependent.relationship.toLowerCase() === 'child') {
            haveKids = true;
        }
    });
    return haveKids;
};

export const getSuggestedMessageForSelectedSI = (
    policyData: IPolicy,
    maxSlabForCompany: SlabCheckCompany | null | undefined,
    slabComposition: 'ESC' | 'ESCP' | 'E',
    getHighestAge: number
): {
    SI: number;
    lowMessage: string;
    highMessage: string;
} => {
    const maxSlab = maxSlabForCompany ? maxSlabForCompany.slabs : 0;
    const self = policyData.dependents.filter((dependent) => dependent.relationship.toLowerCase() === 'self')[0];
    const selfAge = getAgeFromDOB(self?.doB);

    if (slabComposition === 'ESCP') {
        if (getHighestAge >= 60) {
            if (Number(policyData.sumInsured) > 200000 && Number(policyData.sumInsured) <= 500000) {
                return {
                    SI: maxSlab,
                    lowMessage: `Your parents are now senior citizens and buying a policy from the market
                        will be significantly more expensive. Consider topping up to the ${getSumInsured(maxSlab, 0)}`,
                    highMessage: ` is a good amount to cover your entire family`
                };
            } else if (Number(policyData.sumInsured) > 500000) {
                return {
                    SI: 750000,
                    lowMessage: `You’re getting a great deal on top up policy because of the good sum in
                            your base policy. Considering your parents are senior citizens, selecting the 7.5 Lacs
                            cover is highly recommended for you`,
                    highMessage: ` is a good amount to cover your entire family`
                };
            }
        } else {
            if (Number(policyData.sumInsured) > 200000 && Number(policyData.sumInsured) <= 500000) {
                return {
                    SI: maxSlab,
                    lowMessage: `Don't let lower sum insured worry you regarding your parents health.
                            Select a ${getSumInsured(maxSlab, 0)} health cover for your family`,
                    highMessage: ` is a good amount to cover your entire family`
                };
            } else if (Number(policyData.sumInsured) > 500000) {
                return {
                    SI: 500000,
                    lowMessage: `You’re getting a great deal on the top up policy because of the good sum in your
                            base policy. Don't let lower sum insured worry you regarding your parents health`,
                    highMessage: ` is a good amount to cover your entire family`
                };
            }
        }
    } else if (slabComposition === 'ESC') {
        if (!haveKids(policyData.dependents)) {
            if (selfAge >= 18 && selfAge <= 36) {
                return {
                    SI: 750000,
                    lowMessage: `Protect your growing family against any emergencies. Invest in your family’s
                        future by choosing a higher cover amount`,
                    highMessage: ` is a good amount to cover your entire family`
                };
            } else if (selfAge >= 37) {
                return {
                    SI: 500000,
                    lowMessage: `Cost of healthcare is rising rapidly. Protect your
                family and savings against emergencies by investing in a higher sum insured`,
                    highMessage: ` is a good amount to cover your entire family`
                };
            }
        } else {
            if (Number(policyData.sumInsured) >= 200000 && Number(policyData.sumInsured) <= 300000) {
                return {
                    SI: maxSlab,
                    lowMessage: `Give your kids the best healthcare service while also protecting your
                            savings. Upgrade to a higher slab at a pocket friendly offer`,
                    highMessage: ` is a good amount to cover your entire family`
                };
            } else if (Number(policyData.sumInsured) <= 500000) {
                return {
                    SI: 750000,
                    lowMessage: `Give your kids the best healthcare service while also protecting your
                            savings. Upgrade to a higher slab at a pocket friendly offer`,
                    highMessage: ` is a good amount to cover your entire family`
                };
            } else {
                return {
                    SI: 500000,
                    lowMessage: `Cost of healthcare is rising rapidly. Protect your family and savings
                            against emergencies by investing in a higher sum insured`,
                    highMessage: ` is a good amount to cover your entire family`
                };
            }
        }
    } else if (slabComposition === 'E') {
        if (selfAge < 35) {
            if (Number(policyData.sumInsured) > 200000 && Number(policyData.sumInsured) <= 500000) {
                return {
                    SI: 500000,
                    lowMessage: `When your family grows add your spouse and kids at no cost. Financial
                            advisors recommend investing in insurance early for long term savings. Upgrade
                            to a higher amount today!`,
                    highMessage: ', Great Choice'
                };
            } else if (Number(policyData.sumInsured) > 500000) {
                return {
                    SI: 300000,
                    lowMessage: `When your family grows add your spouse and kids at no cost. Financial
                            advisors recommend investing in insurance early for long term savings. Upgrade
                            to a higher amount today!`,
                    highMessage: ', Great Choice'
                };
            }
        } else if (selfAge > 35 && selfAge <= 50) {
            if (Number(policyData.sumInsured) > 200000 && Number(policyData.sumInsured) <= 500000) {
                return {
                    SI: 750000,
                    lowMessage: `Cost of healthcare is rising rapidly. Protect yourself and your savings
                            against emergencies by investing in a higher sum insured`,
                    highMessage: ', Great Choice'
                };
            } else if (Number(policyData.sumInsured) > 500000) {
                return {
                    SI: 500000,
                    lowMessage: `Cost of healthcare is rising rapidly. Protect yourself and your savings
                            against emergencies by investing in a higher sum insured`,
                    highMessage: ', Great Choice'
                };
            }
        } else {
            if (Number(policyData.sumInsured) > 200000 && Number(policyData.sumInsured) <= 500000) {
                return {
                    SI: maxSlab,
                    lowMessage: `A single hospitalisation can impact your retirement savings. Upgrade to a
                            higher sum insured for peace of mind.`,
                    highMessage: ', Great Choice'
                };
            } else if (Number(policyData.sumInsured) > 500000) {
                return {
                    SI: 500000,
                    lowMessage: `A single hospitalisation can impact your retirement savings. Upgrade to a
                            higher sum insured for peace of mind.`,
                    highMessage: ', Great Choice'
                };
            }
        }
    }
    return {
        SI: 0,
        lowMessage: '',
        highMessage: ''
    };
};
