/* eslint-disable */
import { IAddNominee, IEditNominee, NomineeData } from '../components/containers/ManageNomineesComponent/types';
import { IChangePlanDeletionsResponse } from '../components/containers/ModularEnrolmentFooter/types';
import {
    getAppPermissionID as getAppPermissionsID,
    getRolePermissions,
    getUserData as getUsersData
} from '../components/pages/LoginContainer/adapters';
import {
    ICoverageAmount,
    IEnrollUserToPolicy,
    IMemberTopUp,
    IPreValidation,
    ITopUpPolicyPlans
} from '../redux/slices/EnrolmentSlice/types';
import {
    IFetchBenefitsByTypeResponse,
    IFetchModularFamilyResponse,
    IFetchModularSummaryResponse,
    ISaveMemberPayload
} from '../redux/slices/ModularEnrolmentSlice/types';
import { BenefitType, ICompanyData, IDependent, IPolicy } from '../redux/slices/PolicyListSlice/types';
import { getDaysLeft } from '../utils/common';
import { isAllowDBCallsEnabled, isIamAPIEnabled, isPolicyStartDateResolverEnabled } from '../utils/featureFlags';
import { getSecondsFromDate } from '../utils/PolicyServices';

import { FlagsmithFlags, isFlagsmithFeatureEnabled } from '../utils/flagsmith';
import { del, get, post, put } from './provider';
import { NomineeConfig } from './types';
import { sortFamilyMembers } from '../components/containers/ModularAddDependents/utils';

export const LoopEndpoints = {
    DEFAULT_TIMEOUT: 30,
    fetchPolicyWiseFamilyDetails: (userId: string): string => `/userPolicies/allFamilyDetailsWithParental/${userId}`,
    fetchEnrolmentTopUpOptions: (policyId: string): string => `/policyPlan/topUpPolicy/${policyId}`,
    fetchDependents: (userId: string): string => `/user?parentId=${userId}`,
    saveEnrolmentTopUpOptions: (userId: string, topupId: string): string =>
        `/userPolicies/topUpPolicy/${userId}/${topupId}`,
    updateEnrolmentStatus: (userId: string, policyId: string): string =>
        `/userPolicies/enrolmentStatus/${userId}/${policyId}`,
    enrolUserToPolicyFamily: (userId: string, policyId: string): string =>
        `/userPolicies/addUserPolicyForFamily/${userId}/${policyId}`,
    fetchCompanyDetails: (companyId: string): string => `/company/${companyId}`,
    fetchNomineeConfig: (companyId: string, policyId: string): string =>
        `/company/getNomineeConfig/${companyId}/${policyId}`,
    fetchSuperTopupAvailable: (userId: string): string => `/topup/topupAvailabilityAndStatus/${userId}`,
    fetchPolicyPreValidations: (policyId: string): string => `/policyPlan/${policyId}`,
    fetchUserPolicyByPolicyID: (userId: string, policyId: string): string => `/userPolicies/${userId}/${policyId}`,
    fetchSIAndAnnualPremium: (userId: string, policyId: string): string =>
        `/userPolicies/fetchFamilySIAndPremium/${userId}/${policyId}`,
    addExisting: '/registration/enrolment/addExisting',
    enrolmentAdd: '/registration/enrolment/add',
    enrolmentEdit: '/registration/enrolment/edit',
    enrolmentOffboard: `/registration/enrolment/offboard`,
    fetchUserOffboardingDetails: (userId: string): string => `/user/isOffboarded/${userId}`,
    addNominee: '/userPolicies/nominee',
    deleteNominee: '/userPolicies/nominee',
    editNominee: '/userPolicies/nominee',
    fetchNominees: (userId: string, policyId: string) => `/userPolicies/nominee/byUserPolicy/${userId}/${policyId}`,
    checkIfTopUpIsConfigured: (policyId: string) => `/userPolicies/topUpPolicy/isGroupTopUpConfigured/${policyId}`,
    getRolesPermissions: `/roles/getRolePermissions`,
    getAppPermissionID: (value: string) => `/iam/${value}`,
    getUserData: (key: string, value: string, patientType?: string) =>
        `/user/fetchUsers?key=${key}&value=${value}&patientType=${patientType}`,
    fetchPolicyInclusionsExclusions: (policyId: string): string => `/policyPlan/inclusionExclusion/${policyId}`,
    getModularBenefits: (userId: string, policyId: string, type: string, category?: string, benefitId?: string) =>
        `/v1/user/${userId}/policy/${policyId}/benefits?benefitType=${type}${category ? `&subType=${category}` : ''}${
            benefitId ? `&benefitId=${benefitId}` : ''
        }`,
    fetchPolicies: (userId: string): string => `/v1/user/${userId}/policies`,
    fetchFamily: (userId: string, policyId: string): string => `/v1/family/${userId}/${policyId}`,
    updateBenefits: (userId: string, policyId: string) => `/v1/user/${userId}/policy/${policyId}/benefits`,
    addMember: (userId: string, policyId: string): string => `/v1/family/${userId}/${policyId}/add`,
    editMember: (userId: string, policyId: string, editUserId: string): string =>
        `/v1/family/${userId}/${policyId}/user/${editUserId}`,
    deleteMember: (userId: string, policyId: string, deleteUserId: string): string =>
        `/v1/family/${userId}/${policyId}/user/${deleteUserId}`,
    fetchModularSummary: (userId: string, policyId: string): string =>
        `/v1/user/${userId}/policy/${policyId}/benefits/summary`,
    checkoutModular: (userId: string, policyId: string): string =>
        `/v1/user/${userId}/policy/${policyId}/benefits/checkout`,
    validateBenefits: (userId: string, policyId: string) => `/v1/user/${userId}/policy/${policyId}/benefits/validate`
};

export const fetchPolicyWiseFamilyDetails = async (userId: string) => {
    try {
        const isModularFeatureEnabled = isFlagsmithFeatureEnabled(FlagsmithFlags.MODULAR);
        // TODO : Need to remove the hardcoded policyId @iffat
        const result: any = isModularFeatureEnabled
            ? await get(LoopEndpoints.fetchPolicies(userId), true)
            : await get(LoopEndpoints.fetchPolicyWiseFamilyDetails(userId));

        return (result as any)?.data?.policyWiseFamilyDetails?.map((policyData: any) => {
            if (
                policyData?.policyType === 'GMC' &&
                policyData?.enrolmentStatus === 'PENDING' &&
                policyData?.enrolmentDueDate &&
                getDaysLeft(getSecondsFromDate(policyData?.enrolmentDueDate)) === 'OVERDUE'
            ) {
                policyData.enrolmentStatus = 'ENROLED';
            }
            const dependents: IDependent[] = policyData.family.map((dep: any) => {
                return {
                    doB: getSecondsFromDate(dep.dob),
                    gender: dep.gender,
                    // some entries will have Child 1 and Child 2, thats the way ops use to send the data. Need to cleanse it.
                    relationship:
                        dep?.relationship?.toLowerCase() === 'child 1' || dep?.relationship?.toLowerCase() === 'child 2'
                            ? 'Child'
                            : dep?.relationship?.toLowerCase(),
                    userId: dep.userId,
                    firstName: dep.firstName || '',
                    lastName: dep.lastName || '',
                    // we are not getting the dependents mobile number seperately, so currently going to use userId to get the dependents mobile number
                    mobile: dep.mobile
                } as IDependent;
            });
            return {
                ...policyData,
                enrolmentStatus: policyData.enrolmentStatus,
                enrolmentDueDate: policyData?.enrolmentDueDate && getSecondsFromDate(policyData?.enrolmentDueDate),
                familyStruture: policyData.familyDefinition,
                policyName: policyData.insurerName,
                dependents: sortFamilyMembers(dependents),
                availableTopUp: policyData.topUpAvailable
            } as IPolicy;
        });
    } catch (e) {
        throw e;
    }
};

export const fetchDependents = async (userId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchDependents(userId));

        return (result as any).data.users.map((dep: any) => {
            return {
                doB: dep.dob,
                gender: dep.gender,
                // some entries will have Child 1 and Child 2, thats the way ops use to send the data. Need to cleanse it.
                relationship:
                    dep?.relationship?.toLowerCase() === 'child 1' || dep?.relationship?.toLowerCase() === 'child 2'
                        ? 'Child'
                        : dep?.relationship?.toLowerCase(),
                userId: dep.userId,
                firstName: dep.firstName || '',
                lastName: dep.lastName || '',
                // we are not getting the dependents mobile number seperately, so currently going to use userId to get the dependents mobile number
                mobile: dep.mobile
            } as IDependent;
        });
    } catch (e) {
        throw e;
    }
};

export const fetchCompanyDetails = async (companyId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchCompanyDetails(companyId));
        let companyDetail = {
            companyId: (result as any).data.company.id,
            companyName: (result as any).data.company.name
        };
        return companyDetail as ICompanyData;
    } catch (e) {
        throw e;
    }
};

export const fetchSuperTopupAvailableDetails = async (userId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchSuperTopupAvailable(userId));
        return result;
    } catch (e) {
        throw e;
    }
};

export const fetchPolicyPreValidations = async (policyId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchPolicyPreValidations(policyId));
        return Object.keys((result as any).data.policyPlans.preValidation).map((relation) => {
            const dependentValidations = (result as any).data.policyPlans.preValidation[relation];
            return {
                [relation]: dependentValidations
            } as IPreValidation;
        });
    } catch (e) {
        throw e;
    }
};
export const fetchNomineeConfigAPI = async (companyId: string, policyId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchNomineeConfig(companyId, policyId));
        return (result as any).data.isEnabled as NomineeConfig;
    } catch (e) {
        throw e;
    }
};

export const getRolesPermissions = async (roles: string[]) => {
    try {
        if (!isAllowDBCallsEnabled) {
            const result = await post(LoopEndpoints.getRolesPermissions, { roles });
            return result;
        } else {
            const response = await getRolePermissions(roles);
            return response;
        }
    } catch (e) {
        throw e;
    }
};

export const getAppPermissionID = async (value: string) => {
    try {
        if (isIamAPIEnabled) {
            const result = await get(LoopEndpoints.getAppPermissionID(value));
            return result;
        } else {
            const result = await getAppPermissionsID(value);
            return result;
        }
    } catch (e) {
        throw e;
    }
};

interface IEditMemberDetails {
    userId?: string;
    relationship_to_account_holders: string;
    name: string;
    mobile: string;
    email_address: string;
    date_of_birth: string;
    gender: string;
    policy_start_date: string;
    enrolment_due_date: string;
}

interface IMemberDetails {
    employee_id?: string;
    userId?: string;
    relationship_to_account_holders: string;
    name: string;
    policy_start_date?: string;
    enrolment_due_date: string | null;
    slabId: number;
    data_addition: string;
    mobile: string;
    email_address: string;
    date_of_birth: string;
    gender: string;
}

interface IDeleteMemberDetails {
    companyId: string;
    userId: string;
    relationship: string;
    dateOfLeaving: Date;
    policyId: string;
}

export const offboardMemberAPI = async ({
    companyId,
    policyId,
    userId,
    relationship,
    dateOfLeaving
}: IDeleteMemberDetails) => {
    return await post(LoopEndpoints.enrolmentOffboard, {
        companyId,
        membersList: [
            {
                userId,
                relationship,
                dateOfLeaving: dateOfLeaving.toLocaleDateString('en-GB'),
                policies: [policyId]
            }
        ]
    });
};

export const addNomineeAPI = async (nominee: IAddNominee): Promise<NomineeData> => {
    const response = await post(LoopEndpoints.addNominee, {
        nominee
    });
    return { ...(response as any).data.addedNominee, dob: new Date((response as any).data.addedNominee.dob) };
};
export const editNomineeAPI = async (nominee: IEditNominee) => {
    return await put(`${LoopEndpoints.editNominee}`, { nominee });
};
export const deleteNomineeAPI = async (nomineeId: string) => {
    return await del(`${LoopEndpoints.deleteNominee}?nomineeId=${nomineeId}`);
};
export const fetchNomineesAPI = async (userId: string, policyId: string): Promise<NomineeData[]> => {
    const response = await get(LoopEndpoints.fetchNominees(userId, policyId));
    const nomineesRaw = (response as any).data.nominees;
    return nomineesRaw.map(
        (v: any) =>
            ({
                dob: new Date(v.dob),
                firstName: v.name.split(' ')[0],
                lastName: v.name.split(' ')[1],
                gender: v.gender,
                percentage: v.percentage,
                relationship: v.relationship,
                id: v.id
            } as NomineeData)
    );
};

export const addExistingAPI = async (companyId: string, policyId: string, memberDetails: IMemberDetails) => {
    return await post(LoopEndpoints.addExisting, {
        companyId,
        policyId,
        membersList: [memberDetails],
        mode: 'EDIT',
        enableEnrolmentPortal: 'true'
    });
};

export const addMemberAPI = async (companyId: string, policyId: string, memberDetails: IMemberDetails) => {
    return await post(LoopEndpoints.enrolmentAdd, {
        companyId,
        policies: [
            {
                id: policyId,
                slab: memberDetails.slabId,
                enrolmentDueDate: memberDetails.enrolment_due_date,
                policyStartDate: isPolicyStartDateResolverEnabled ? undefined : memberDetails.policy_start_date
            }
        ],
        member: memberDetails
    });
};
export const editMembersAPI = async (companyId: string, memberDetails: IEditMemberDetails) => {
    return await post(LoopEndpoints.enrolmentEdit, {
        companyId,
        member: memberDetails
    });
};

export const fetchEnrolmentTopUpOptions = async (policyId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchEnrolmentTopUpOptions(policyId));
        const options: ITopUpPolicyPlans[] = [];
        (result as any)?.data?.topUpPolicyPlans?.map((val: any) => {
            options.push({ policyId: val.id, topUpPolicySlabs: val.topUpPolicySlabs, topUpName: val.topUpPolicyName });
        });
        return options;
    } catch (e) {
        throw e;
    }
};
const getParallelEnrolmentTopUpOptions = async (topUpDetails: IMemberTopUp[] | null, userId: string, result: any) => {
    await Promise.all(
        topUpDetails?.map(async (topup: IMemberTopUp) => {
            const contents = await post(LoopEndpoints.saveEnrolmentTopUpOptions(userId, topup.id), {
                enrolmentCycle: (result as any)?.data?.usersPolicies?.enrolmentCycle,
                enrolmentStatus: 'PENDING',
                policyStatus: 'ENROLED',
                topUpSlabId: topup.topUpSlabId
            });
        }) ?? []
    );
};
export const saveEnrolmentTopUpOptions = async (
    topUpDetails: IMemberTopUp[] | null,
    userId: string,
    policyId: string
) => {
    try {
        const result = await get(LoopEndpoints.fetchUserPolicyByPolicyID(userId, policyId));
        await getParallelEnrolmentTopUpOptions(topUpDetails, userId, result);
    } catch (e) {
        throw e;
    }
};

export const updateUserPolicyEnrolmentStatus = async (
    userId: string,
    policyId: string,
    enrolmentStatus?: 'ENROLED' | 'PENDING'
) => {
    try {
        await put(LoopEndpoints.updateEnrolmentStatus(userId, policyId), {
            enrolmentStatus: enrolmentStatus || 'ENROLED'
        });
        return enrolmentStatus ? enrolmentStatus : 'Enroled';
    } catch (error) {
        throw error;
    }
};
export const fetchSelfUserPolicy = async (id: string, policyId: string) => {
    const response = await get(LoopEndpoints.fetchUserPolicyByPolicyID(id, policyId));
    return (response as any).data.usersPolicies;
};

export const fetchSIAndAnnualPremium = async (userId: string, policyId: string) => {
    try {
        const result: any = await get(LoopEndpoints.fetchSIAndAnnualPremium(userId, policyId));

        return {
            sumInsured: result?.data?.totalSI,
            annualPremium: result?.data?.totalPremium,
            gstOfAnnualPremium: result?.data?.gstOfAnnualPremium,
            annualPremiumWithoutGST: result?.data?.annualPremiumWithoutGST
        } as ICoverageAmount;
    } catch (e) {
        throw e;
    }
};

export const enrolUserToPolicyFamily = async (req: Record<string, unknown>, userId: string, policyId: string) => {
    try {
        const response: any = await post(LoopEndpoints.enrolUserToPolicyFamily(userId, policyId), req);
        return {
            policyId: response?.data?.policy?.id,
            userId: response?.data?.policy?.userId
        } as IEnrollUserToPolicy;
    } catch (error) {
        throw error;
    }
};

export const fetchUserOffboardingDetails = async (userId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchUserOffboardingDetails(userId));
        return (result as any).data.isUserOffboarded;
    } catch (e) {
        throw e;
    }
};

export const checkIfTopUpIsConfigured = async (policyId: string) => {
    try {
        const result = await get(LoopEndpoints.checkIfTopUpIsConfigured(policyId));
        return (result as any).data;
    } catch (e) {
        throw e;
    }
};

export const getUserData = async (key: string, value: string, patientType?: string) => {
    try {
        if (!isAllowDBCallsEnabled) {
            const result = await get(LoopEndpoints.getUserData(key, value, patientType));
            return result;
        } else {
            const result = await getUsersData(key, value);
            return result;
        }
    } catch (e) {
        throw e;
    }
};

export const fetchPolicyInclusions = async (policyId: string) => {
    try {
        const result = await get(LoopEndpoints.fetchPolicyInclusionsExclusions(policyId));
        return (result as any).data;
    } catch (e) {
        throw e;
    }
};

export const fetchBenefitsForUserByTypeAndCategory = async (
    userId: string,
    policyId: string,
    benefitType: BenefitType,
    category?: string,
    benefitId?: string
): Promise<IFetchBenefitsByTypeResponse> => {
    const result = await get(
        LoopEndpoints.getModularBenefits(userId, policyId, benefitType, category, benefitId),
        true
    );
    return result.data;
};

export const fetchModularSummaryForUser = async (
    userId: string,
    policyId: string
): Promise<IFetchModularSummaryResponse> => {
    const result = await get(LoopEndpoints.fetchModularSummary(userId, policyId), true);
    return result.data;
};

export const fetchAddedFamily = async (userId: string, policyId: string): Promise<IFetchModularFamilyResponse> => {
    const result = await get(LoopEndpoints.fetchFamily(userId, policyId), true);
    return result.data;
};

export const updateBenefitIds = async (
    userId: string,
    policyId: string,
    benefitIds: string[],
    deletions: Record<string, string[]> = {},
    email?: string
): Promise<void> => {
    const response = await post(LoopEndpoints.updateBenefits(userId, policyId), { benefitIds, deletions, email }, true);
    return response.data;
};

export const validateBenefits = async (
    userId: string,
    policyId: string,
    modularPlanId: string
): Promise<IChangePlanDeletionsResponse> => {
    const response = await post(
        LoopEndpoints.validateBenefits(userId, policyId),
        { benefitIds: [modularPlanId] },
        true
    );
    return response.data;
};

export const saveAndCheckoutModular = async (userId: string, policyId: string) => {
    const response = await post(LoopEndpoints.checkoutModular(userId, policyId), {}, true);
    return response.data;
};

export const addMember = async (userId: string, policyId: string, memberDetails: ISaveMemberPayload) => {
    const response = await post(LoopEndpoints.addMember(userId, policyId), { ...memberDetails }, true);
    return response.data;
};

export const editMember = async (
    userId: string,
    policyId: string,
    editUserId: string,
    memberDetails: ISaveMemberPayload
) => {
    const response = await put(LoopEndpoints.editMember(userId, policyId, editUserId), { ...memberDetails }, true);
    return response.data;
};

export const deleteMember = async (userId: string, policyId: string, deleteUserId: string) => {
    const response = await del(LoopEndpoints.deleteMember(userId, policyId, deleteUserId), {}, true);
    return response.data;
};
