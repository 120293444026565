import { IPolicy as ITopupPolicy } from '../../topup-src/redux/slices/PolicyListSlice/types';
import { IPolicy, IPOLICYDATE } from '../../redux/slices/PolicyListSlice/types';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../redux';
import { COMPANY_ID, PARENTAL_POLICY } from '../constants';
import { getFullInsuranceName } from '../common';
import { FirebaseRemoteConfig } from '../../adapters/provider';
import { COMPANY_ID_ESCP } from '../constants/CompanyConstants';
export const createFamilyStructure = (policy: IPolicy, enroledGMCPolicy: IPolicy[]): string => {
    if (enroledGMCPolicy.length && policy.policyType === 'SUPER_TOP_UP') {
        const familyStructure = enroledGMCPolicy;
        return familyStructure[0].familyStruture;
    } else {
        return policy.familyStruture;
    }
};

export const checkParentalPolicy = (policy: IPolicy): boolean => {
    if (policy?.familyStruture?.toLowerCase()?.includes('self')) {
        return false;
    }
    return true;
};

export const checkParentalPolicyTopUp = (policy: ITopupPolicy): boolean => {
    if (policy?.familyStructure?.toLowerCase()?.includes('self')) {
        return false;
    }
    return true;
};

export const checkTopUpAvailable = (): string => {
    const checkSuperTopUp = useSelector((state: ReduxState) => state.enrolment?.superTopUpPolicy.data);
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    if (!currentlySelectedPolicy?.familyStruture?.toLowerCase()?.includes('self')) {
        return checkSuperTopUp?.parentalTopup?.status;
    } else {
        return checkSuperTopUp?.topup?.status;
    }
};

export const getSuperTopUpPolicyCompany = (): IPolicy => {
    const checkSuperTopUp = useSelector((state: ReduxState) => state.enrolment?.superTopUpPolicy.data);
    const companyId = useSelector((state: ReduxState) => state.user.userData.data?.employer);
    const companyIDs = FirebaseRemoteConfig.getValue('SupertopUpParentalWhitelisting').asString();
    const companyIDsArray = companyIDs && JSON.parse(companyIDs);
    if (
        typeof companyId != 'undefined' && companyIDsArray.length
            ? companyIDsArray.includes(companyId)
            : COMPANY_ID.includes(companyId)
    ) {
        return checkSuperTopUp?.parentalTopup?.policyFamilyDetail;
    } else {
        return checkSuperTopUp?.topup?.policyFamilyDetail;
    }
};

export const getEnrolledPolicyCompany = (policyList: IPolicy[]): any => {
    const companyId = useSelector((state: ReduxState) => state.user.userData.data?.employer);
    const companyIDs = FirebaseRemoteConfig.getValue('SupertopUpParentalWhitelisting').asString();
    const companyIDsArray = companyIDs && JSON.parse(companyIDs);
    if (
        typeof companyId != 'undefined' && companyIDsArray.length
            ? companyIDsArray.includes(companyId)
            : COMPANY_ID.includes(companyId)
    ) {
        return policyList
            ? policyList.filter(
                (policy: IPolicy) =>
                    policy.policyType === 'GMC' &&
                      policy.enrolmentStatus === 'ENROLED' &&
                      !policy.familyStruture?.toLowerCase()?.includes('self')
            )
            : [];
    } else {
        return policyList
            ? policyList.filter(
                (policy: IPolicy) =>
                    policy.policyType === 'GMC' &&
                      policy.enrolmentStatus === 'ENROLED' &&
                      policy.familyStruture?.toLowerCase()?.includes('self')
            )
            : [];
    }
};

export const getStartingPremiumCompany = (): number => {
    const checkSuperTopUp = useSelector((state: ReduxState) => state.enrolment?.superTopUpPolicy.data);
    const companyId = useSelector((state: ReduxState) => state.user.userData.data?.employer);
    const companyIDs = FirebaseRemoteConfig.getValue('SupertopUpParentalWhitelisting').asString();
    const companyIDsArray = companyIDs && JSON.parse(companyIDs);
    if (
        typeof companyId != 'undefined' && companyIDsArray.length
            ? companyIDsArray.includes(companyId)
            : COMPANY_ID.includes(companyId)
    ) {
        return checkSuperTopUp?.parentalTopup?.minAnnualPremium ?? 0;
    } else {
        return checkSuperTopUp?.topup?.minAnnualPremium ?? 0;
    }
};

export const getPolicyTitle = (category: string, familyStructure: string): string => {
    if (category === 'SUPER_TOP_UP') {
        return 'Family Top-Up';
    } else {
        if (PARENTAL_POLICY.includes(familyStructure)) {
            return getFullInsuranceName('PARENTAL');
        } else {
            return getFullInsuranceName(category);
        }
    }
};

export const checkTopUpAvailableCompany = (): string => {
    const checkSuperTopUp = useSelector((state: ReduxState) => state.enrolment?.superTopUpPolicy.data);
    const companyId = useSelector((state: ReduxState) => state.user.userData.data?.employer);
    const companyIDs = FirebaseRemoteConfig.getValue('SupertopUpParentalWhitelisting').asString();
    const companyIDsArray = companyIDs && JSON.parse(companyIDs);
    if (
        typeof companyId != 'undefined' && companyIDsArray.length
            ? companyIDsArray.includes(companyId)
            : COMPANY_ID.includes(companyId)
    ) {
        return checkSuperTopUp?.parentalTopup?.status;
    } else {
        return checkSuperTopUp?.topup?.status;
    }
};

export const getGMCPolicyCompany = (policyList: IPolicy[]): IPolicy | undefined | null => {
    const companyId = useSelector((state: ReduxState) => state.user.userData.data?.employer);
    const companyIDs = FirebaseRemoteConfig.getValue('SupertopUpParentalWhitelisting').asString();
    const companyIDsArray = companyIDs && JSON.parse(companyIDs);
    if (
        typeof companyId != 'undefined' && companyIDsArray.length
            ? companyIDsArray.includes(companyId)
            : COMPANY_ID.includes(companyId)
    ) {
        return policyList
            ? policyList.find(
                (policy: IPolicy) =>
                    policy.policyType === 'GMC' && !policy.familyStruture?.toLowerCase()?.includes('self')
            )
            : null;
    } else {
        return policyList
            ? policyList.find(
                (policy: IPolicy) =>
                    policy.policyType === 'GMC' && policy.familyStruture?.toLowerCase()?.includes('self')
            )
            : null;
    }
};

export const checkForShowSuperTopupCardCondition = (policyList: IPolicy[], superTopUpAvailable: boolean): boolean => {
    const companyId = useSelector((state: ReduxState) => state.user.userData.data?.employer);
    const companyIDs = FirebaseRemoteConfig.getValue('SupertopUpParentalWhitelisting').asString();
    const companyIDsArray = companyIDs && JSON.parse(companyIDs);
    if (
        typeof companyId != 'undefined' && companyIDsArray.length
            ? companyIDsArray.includes(companyId)
            : COMPANY_ID.includes(companyId)
    ) {
        const gmc = policyList
            ? policyList.filter(
                (policy: IPolicy) =>
                    policy.policyType === 'GMC' &&
                      policy.enrolmentStatus === 'ENROLED' &&
                      !policy.familyStruture.toLowerCase().includes('self')
            )
            : [];
        return gmc.length > 0 && superTopUpAvailable;
    } else {
        let superTopUp;
        if (COMPANY_ID_ESCP.includes(companyId)) {
            superTopUp = policyList
                ? policyList.filter(
                    (policy: IPolicy) =>
                        policy.policyType === 'SUPER_TOP_UP' &&
                          policy.enrolmentStatus === 'ENROLED' &&
                          getPolicyStructureType(policy.familyStruture) === 'E'
                )
                : [];
        } else {
            superTopUp = policyList
                ? policyList.filter(
                    (policy: IPolicy) => policy.policyType === 'SUPER_TOP_UP' && policy.enrolmentStatus === 'ENROLED'
                )
                : [];
        }

        const enrolledGMC = policyList
            ? policyList.filter(
                (policy: IPolicy) => policy.policyType === 'GMC' && policy.enrolmentStatus === 'ENROLED'
            )
            : [];
        return superTopUp.length == 0 && enrolledGMC.length > 0 && superTopUpAvailable;
    }
};

const getPolicyStructureType = (familyStructure: string): string => {
    const lowerCaseFamilyStructure = familyStructure.toLowerCase();
    const hasSelf = lowerCaseFamilyStructure.includes('self');
    const hasParents = lowerCaseFamilyStructure.includes('parents');
    const hasParentInLaws = lowerCaseFamilyStructure.includes('parent-in-laws');

    if (hasSelf && (hasParents || hasParentInLaws)) {
        return 'ESCP';
    } else if (!hasSelf) {
        return '2P';
    } else if (!hasParents && !hasParentInLaws) {
        return 'E';
    } else {
        return 'E'; // Default case
    }
};

export const showPolicyMsgDashboard = (policiesLength: number): string | null => {
    if (policiesLength === 0) {
        return 'There are no policies for the logged-in user.';
    } else {
        return null;
    }
};

export const formatAmount = (amount: number | string | undefined): string | undefined => {
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getSecondsFromDate = (date: string | IPOLICYDATE): number => {
    if (typeof date == 'string') return new Date(date).getTime() / 1000;
    return date?._seconds;
};
