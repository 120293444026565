import styled from 'styled-components';

export const StyledContainer = styled.div(
    (p) => `
    max-width: 46%;
    min-width: 418px;
    height: 160px;
    padding: 20px 15px;
    border-radius: 12px;
    margin: 15px 0px;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${p.theme.colors.mutedTransparent};
    border: 2px dashed ${p.theme.colors.seafoamGray};
    @media (max-width: 768px) {
        max-width: 100%;
        min-width: 0px;
        height: 48px;
        margin: 0px;
    }
`
);

export const StyledText = styled.div(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    text-transform: capitalize;
    color: ${p.theme.colors.loopEmerald};
    margin-left: 5px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 20px;
    @media (max-width: 768px) {
        font-size: ${p.theme.fontSizes.label};
        line-height: 18px;
    }
`
);

export const StyledImage = styled.img`
    height: 18px;
    width: 18px;
`;

export const StyledButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
