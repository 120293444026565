import React from 'react';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import { IWelcomeCard } from './types';
import {
    StyledContainer,
    StyledBackground,
    StyledLogo,
    StyledDetails,
    StyledName,
    StyledLogoContainer,
    StyledPendingDetails,
    StyledButtonConatiner,
    StyledRegistrationButton,
    StyledCompleteRegistration,
    StyledDivider
} from './styles';
import { Clipboard, DashboardBackground, LoopGreenLogo } from '../../../../assets/img';
import { useNextPolicy } from '../../../pages/SPAContainer/pages/DashboardPage/hooks/useNextPolicy';
import { redirect } from '../../../../utils/common';
import { REDIRECT_URL } from '../../../../utils/constants/RedirectConstants';

const DashboardHeader: React.FunctionComponent<IWelcomeCard> = ({ userName, total, remaining, onSignout }) => {
    const { navigateNext } = useNextPolicy();
    const title =
        remaining === 0
            ? 'Congratulations!'
            : total - remaining === 0
                ? 'Kickstart your Loop Journey'
                : 'Welcome back!';

    const subtitle =
        remaining === 0
            ? 'Your registration is complete! Enjoy seamless healthcare support and connect with doctors anytime.'
            : `Complete your registration to activate your benefits, including instant claim assistance and expert 
            medical advice on demand.`;

    const displayButton = total - remaining !== 0;
    const buttonTitle = remaining === 0 ? 'Get the Loop App' : 'Complete Registration';

    const onDownloadApp = () => redirect(REDIRECT_URL.LOOP_APP);

    const onButtonClick = () => {
        remaining === 0 ? onDownloadApp() : navigateNext();
    };

    return (
        <StyledContainer>
            <StyledBackground src={DashboardBackground} alt="bg" />
            <StyledLogoContainer>
                <StyledLogo src={LoopGreenLogo} alt={'Loop Health Logo'} />
            </StyledLogoContainer>
            <StyledDetails>
                <Typography variant="large" color="tertiary">
                    <StyledName>Hello {userName},</StyledName>
                </Typography>
                <Typography variant="titleLarge" weight="bold" color="tertiary">
                    {title}
                </Typography>
                <Typography variant="medium" color="tertiary">
                    {subtitle}
                </Typography>
                <StyledRegistrationButton>
                    {displayButton && (
                        <>
                            <StyledCompleteRegistration>
                                <LoopButton variant="filled" onClick={onButtonClick}>
                                    {buttonTitle}
                                </LoopButton>
                            </StyledCompleteRegistration>
                            <StyledDivider />
                        </>
                    )}
                    <StyledPendingDetails>
                        {remaining === 0 ? (
                            <Typography variant="small" color="teamTurquoise">
                                Download the Loop app now to access your benefits on the go.
                            </Typography>
                        ) : (
                            <>
                                <img src={Clipboard} />

                                <Typography variant="medium" color="tertiary">
                                    {remaining} out of {total} Policy Registration Pending
                                </Typography>
                            </>
                        )}
                    </StyledPendingDetails>
                </StyledRegistrationButton>
            </StyledDetails>
            <StyledButtonConatiner>
                <LoopButton variant="outline" onClick={onSignout} textColor="tertiary">
                    Sign Out
                </LoopButton>
            </StyledButtonConatiner>
        </StyledContainer>
    );
};

export default DashboardHeader;
