import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchBenefitsForUserByTypeAndCategory,
    updateBenefitIds,
    fetchAddedFamily,
    fetchModularSummaryForUser
} from '../../../adapters';
import { IModularEnrolmentSummary } from './types';
import { BenefitType } from '../PolicyListSlice/types';
import { sortFamilyMembers } from '../../../components/containers/ModularAddDependents/utils';

const MODULAR_ENROLMENT_ACTION_TYPES = {
    FETCH_MODULAR_PLANS: 'modularEnrolmentSlice/fetch-modular-plans',
    FETCH_MODULAR_SUMMARY: 'modularEnrolmentSlice/fetch-modular-summary',
    FETCH_MODULAR_ALA_CARTE_BENEFITS: 'modularEnrolmentSlice/fetch-modular-ala-carte-benefits',
    UPDATE_MODULAR_ALA_CARTE_BENEFITS: 'modularEnrolmentSlice/update-modular-ala-carte-benefits',
    FETCH_MODULAR_FAMILY: 'modularEnrolmentSlice/fetch-modular-family',
    ADD_MEMBER: 'modularEnrolmentSlice/add-member',
    EDIT_MEMBER: 'modularEnrolmentSlice/edit-member',
    DELETE_MEMBER: 'modularEnrolmentSlice/delete-member'
};

export const fetchModularPlans = createAsyncThunk(
    MODULAR_ENROLMENT_ACTION_TYPES.FETCH_MODULAR_PLANS,
    async ({ userId, policyId }: { userId: string; policyId: string }) => {
        const response = await fetchBenefitsForUserByTypeAndCategory(userId, policyId, BenefitType.MODULAR);
        const plans = response?.benefits?.modular || [];
        const selectedPlanId = plans.find((p) => p.isSelected)?.id || plans.find((p) => p.isDefault)?.id;
        return { plans, selectedPlanId };
    }
);

export const fetchModularSummary = createAsyncThunk(
    MODULAR_ENROLMENT_ACTION_TYPES.FETCH_MODULAR_SUMMARY,
    async ({ userId, policyId }: { userId: string; policyId: string }) => {
        const response = await fetchModularSummaryForUser(userId, policyId);
        const summary: IModularEnrolmentSummary = {
            modular: response?.modular?.[0],
            alacarte: response?.alacarte || [],
            priceSummary: response.priceSummary ?? {}
        };
        return summary;
    }
);

export const fetchModularAlaCarteBenefits = createAsyncThunk(
    MODULAR_ENROLMENT_ACTION_TYPES.FETCH_MODULAR_ALA_CARTE_BENEFITS,
    async ({ userId, policyId, benefitId }: { userId: string; policyId: string; benefitId?: string }) => {
        const response = await fetchBenefitsForUserByTypeAndCategory(
            userId,
            policyId,
            BenefitType.ALA_CARTE,
            undefined,
            benefitId
        );
        const benefits = response?.benefits?.alacarte || [];
        const personalEmail = response?.email || '';
        const selectedBenefits = benefits?.flatMap((p) => {
            if (p.isSelected && p?.subCategories?.length === 0) {
                return [p.id];
            } else if (p?.subCategories?.length > 0) {
                return p?.subCategories?.filter((sub) => sub?.isSelected)?.map((sub) => sub?.id);
            }
            return [];
        });
        return { benefits, selectedBenefits, personalEmail };
    }
);

export const fetchModularFamily = createAsyncThunk(
    MODULAR_ENROLMENT_ACTION_TYPES.FETCH_MODULAR_FAMILY,
    async ({ userId, policyId }: { userId: string; policyId: string }) => {
        const response = await fetchAddedFamily(userId, policyId);
        return sortFamilyMembers(response?.family || []);
    }
);

export const updateBenefits = createAsyncThunk(
    MODULAR_ENROLMENT_ACTION_TYPES.UPDATE_MODULAR_ALA_CARTE_BENEFITS,
    async ({ userId, policyId, benefitIds }: { userId: string; policyId: string; benefitIds: string[] }) => {
        const response = await updateBenefitIds(userId, policyId, benefitIds);
        return response;
    }
);
