import React from 'react';
import { Modal, Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledBenefitRow,
    StyledBenefitsContainer,
    StyledBody,
    StyledClose,
    StyledHeader,
    StyledIconContainer,
    StyledModalContainer,
    StyledTitle
} from './styles';
import { IFtuxModal } from './types';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { BalanceGraphic } from '../../../assets/img';
import { Cart, Close, Secure } from '../../../assets/img/CustomIcons';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { selectModularWalletBalance } from '../../../redux/slices/ModularEnrolmentSlice/selectors';
import { formatAmount } from '../../../utils/common';

const ModularFtuxModal: React.FC<IFtuxModal> = ({ isVisible, setIsVisible, onClose }) => {
    const walletAmount = useSelector(selectModularWalletBalance);
    const companyName = useSelector((state: ReduxState) => state.policyList?.companyDetails?.data?.companyName);

    const handleClose = () => {
        setIsVisible(false);
        onClose();
    };

    return (
        <Modal isVisible={isVisible} setIsVisible={handleClose} disablePadding={true} isModalClosable={false}>
            <WithThemeProvider>
                <StyledModalContainer>
                    <StyledHeader>
                        <img src={BalanceGraphic} />
                        <StyledClose onClick={handleClose}>
                            <Close />
                        </StyledClose>
                    </StyledHeader>

                    <StyledBody>
                        <StyledTitle>
                            <Typography variant="large" weight="medium">
                                {companyName} has given you a wallet balance of
                            </Typography>
                            <Typography variant="title1" weight="medium">
                                {walletAmount && `${formatAmount(walletAmount)}💰`}
                            </Typography>
                        </StyledTitle>

                        <StyledBenefitsContainer>
                            <Typography variant="small" weight="semiBold">
                                Use it for:
                            </Typography>

                            <StyledBenefitRow>
                                <StyledIconContainer>
                                    <Secure />
                                </StyledIconContainer>
                                <Typography variant="small">Your perfect insurance plan</Typography>
                            </StyledBenefitRow>

                            <StyledBenefitRow>
                                <StyledIconContainer>
                                    <Cart />
                                </StyledIconContainer>
                                <Typography variant="small">
                                    Add-ons such as Pet Care, Cult Membership and more
                                </Typography>
                            </StyledBenefitRow>
                        </StyledBenefitsContainer>

                        <Typography variant="small" color="secondary">
                            Any amount exceeding this will be deducted from your salary
                        </Typography>
                    </StyledBody>
                </StyledModalContainer>
            </WithThemeProvider>
        </Modal>
    );
};

export default ModularFtuxModal;
