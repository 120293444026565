import React, { useState } from 'react';
import { PersonImg, InsuranceIcon, AmountIcon, InfoDefaultIcon } from '../../../assets/img';
import theme from '../../../theme';
import { capitalizeFirstLetter, getAgeFromDOB, getSegmentPageName, getSumInsured } from '../../../utils/common';
import { ImageView } from '../../atoms/ImageView';
import { TextView } from '../../atoms/TextView';
import {
    StyledTopUpContainer,
    Container,
    StyledCoverWrapper,
    StyledNameContainer,
    StyledSIContainer,
    StyledInternalTopContainer,
    StyledDetailsWrapper,
    StyledAPContainerSpan,
    StyledLoaderWrapper,
    StyledSup,
    StyledSub,
    StyledTaxContainer,
    StyledSITabsWrapper,
    ButtonContainer,
    StyledChatSpan,
    StyledContainer,
    StyledButtonContainer,
    StyledButtonWrapper,
    StyledInformationItemTooltipIcon,
    StyledDottedDivider,
    StyledHeaderTextWrapper
} from './styles';
import { ITopupCustomization } from './types';
import { useLocation } from 'react-router-dom';
import { Loader } from '../../atoms';
import { ReduxState } from '../../../redux';
import { useSelector } from 'react-redux';
import {
    familyComposition,
    getHighestAgeUser,
    getRecommendedSIDetails,
    getSuggestedMessageForSelectedSI,
    validateSLABS
} from './utils';
import { LoopButton, Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import { openFreshChat } from '../../../utils/Freshchat';
import { RecommendedSIElement } from '../../../utils/types';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { topUpDefaultConfig } from '../../../utils/data';
import Chip from '../../atoms/Chip';
import MemberIdentity from '../MemberIdentity';
import InsuranceIdentityTopUp from '../../atoms/InsuranceIdentityTopUp';
import TopupCallback from '../TopupCallback';
import { FirebaseRemoteConfig } from '../../../topup-src/adapters/provider';
import { SlabCheckCompany } from '../../../utils/constants/CompanyConstants';

const TopupCustomization: React.FunctionComponent<ITopupCustomization> = ({
    policyData,
    selectedSI,
    setSelectedSI,
    setTotalSI,
    familyStructure,
    sumInsuredSlabs,
    memberTopUpPremium,
    premiumLoading,
    defaultConfiguration
}) => {
    const [message, setMessage] = useState({
        SI: 0,
        lowMessage: '',
        highMessage: ''
    });
    const companyId = useSelector((state: ReduxState) => state.TopUp_user?.userData?.data?.employer);
    const [topupSumInsuredSlabs, setTopupSumInsuredSlabs] = React.useState(sumInsuredSlabs);
    const location = useLocation();
    const [callbackIsVisible, setCallbackIsVisible] = React.useState(false);
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const topUpAmount: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const allPolicyData: any = useSelector((state: ReduxState) => state.TopUp_policyData?.topupPolicyDetails?.data);
    const trackClick = useSegment('click');
    const { parentUserCount, parentInLawUserCount, spouseUserCount, childUserCount } = familyComposition(
        policyData?.dependents
    );
    const getHighestAge = getHighestAgeUser(policyData?.dependents);
    const companyIDs = FirebaseRemoteConfig.getValue('CompaniesSILimit').asString();

    const companyIDsArray: SlabCheckCompany[] = companyIDs && JSON.parse(companyIDs);
    const hideTopUpSuggestionMessage = Array.isArray(companyIDsArray)
        ? !!companyIDsArray.find((company: SlabCheckCompany) => company?.id?.toLowerCase() === companyId?.toLowerCase())
        : false;
    const maxSlabForCompany = Array.isArray(companyIDsArray)
        ? companyIDsArray.find((company: SlabCheckCompany) => company?.id?.toLowerCase() === companyId?.toLowerCase())
        : null;

    let slabComposition: 'ESC' | 'ESCP' | 'E' = 'E';
    const showTag = (slab: number) => {
        return hideTopUpSuggestionMessage ? slab === message.SI : getSIDetails?.sumInsured === slab ? true : false;
    };

    if (spouseUserCount || childUserCount) {
        slabComposition = 'ESC';
    }
    if (parentUserCount || parentInLawUserCount) {
        slabComposition = 'ESCP';
    }
    const getSIDetails: RecommendedSIElement = getRecommendedSIDetails(
        slabComposition,
        getHighestAge,
        defaultConfiguration ?? topUpDefaultConfig,
        policyData?.dependents.length
    );

    const handleSISelectChange = (sumInsured: any) => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.SELECT_SUM_INSURED;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                sum_insured:
                    (segmentAction.properties?.sum_insured as (sumInsured: number) => number)(sumInsured) || '',
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                covered_members:
                    (segmentAction.properties?.covered_members as (coveredMembers: string) => string)(
                        capitalizeFirstLetter(familyStructure)
                    ) || ''
            }
        });
        setSelectedSI(sumInsured as any);
        setTotalSI(sumInsured + policyData.sumInsured);
    };

    const handleCallExpertClick = () => {
        setCallbackIsVisible(true);
        const segmentAction = SEGMENT_ACTIONS.CLICK.CALL_AN_EXPERT;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                cta_id: (segmentAction.properties?.cta_id as (cta: number) => number)(1)
            }
        });
    };

    const handleChatCTAClick = () => {
        openFreshChat();
        const segmentAction = SEGMENT_ACTIONS.CLICK.CHAT_WITH_US;
        trackClick({
            name: segmentAction.name,
            properties: {
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || ''
            }
        });
    };

    React.useEffect(() => {
        setMessage(getSuggestedMessageForSelectedSI(policyData, maxSlabForCompany, slabComposition, getHighestAge));
    }, [selectedSI]);

    React.useEffect(() => {
        const topupSumInsuredSlabs = validateSLABS(sumInsuredSlabs, companyId);
        setTopupSumInsuredSlabs(topupSumInsuredSlabs);
    }, [sumInsuredSlabs]);

    return (
        <Container>
            <StyledTopUpContainer>
                <StyledHeaderTextWrapper>
                    <Typography variant="title2" color="emerald">
                        Remember to choose an amount that
                        <Typography variant="title2" color="consultViolet">
                            {' covers all your needs'}
                        </Typography>
                    </Typography>
                </StyledHeaderTextWrapper>
                <StyledCoverWrapper>
                    <ImageView
                        width="30px"
                        containerWidth="38px"
                        containerMinHeight="38px"
                        backgroundColor={theme.colors.planRed}
                        src={InsuranceIcon}
                        radius="19px"
                    />
                    <Typography variant="medium" color="secondary">
                        Top up sum insured
                    </Typography>
                    <Tooltip
                        beak="top-center"
                        text={'Coverage of your top-up. This amount will activate once your basic coverage gets over.'}
                        type="informative"
                    >
                        <StyledInformationItemTooltipIcon src={InfoDefaultIcon} />
                    </Tooltip>
                </StyledCoverWrapper>
                <StyledDottedDivider />
                <StyledSIContainer>
                    <StyledSITabsWrapper>
                        {topupSumInsuredSlabs?.map((slab: number, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    <Chip
                                        color={
                                            (selectedSI as string | number) === slab
                                                ? theme.colors.loopEmerald
                                                : theme.colors.beyondGrey
                                        }
                                        label={getSumInsured(slab, 0)}
                                        backgroundColor={
                                            (selectedSI as string | number) === slab
                                                ? theme.colors.platinum
                                                : theme.colors.white
                                        }
                                        borderRadius="16px"
                                        showTag={showTag(slab)}
                                        onClick={() => handleSISelectChange(slab)}
                                        variant="outlined"
                                        padding={'14px 36px'}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </StyledSITabsWrapper>
                    {selectedSI && !hideTopUpSuggestionMessage ? (
                        <>
                            <StyledDottedDivider />
                            <TextView fontSize="16px" fontWeight="400" color={theme.colors.beyondGrey}>
                                {parseInt(selectedSI) < getSIDetails?.sumInsured ? (
                                    <TextView fontSize="16px" fontWeight="400" color={theme.colors.planRed}>
                                        {'Too Low! '}
                                    </TextView>
                                ) : (
                                    <TextView fontSize="16px" fontWeight="400" color={theme.colors.loopEmerald}>
                                        {'Perfect! '}
                                    </TextView>
                                )}
                                {parseInt(selectedSI) < getSIDetails?.sumInsured
                                    ? getSIDetails?.lowMsg
                                    : `${getSumInsured(selectedSI, 0) + getSIDetails?.highMsg}`}
                            </TextView>
                        </>
                    ) : null}
                    {selectedSI && hideTopUpSuggestionMessage ? (
                        <>
                            <StyledDottedDivider />
                            <TextView fontSize="16px" fontWeight="400" color={theme.colors.beyondGrey}>
                                {parseInt(selectedSI) < message?.SI ? (
                                    <TextView fontSize="16px" fontWeight="400" color={theme.colors.planRed}>
                                        {'Too Low! '}
                                    </TextView>
                                ) : (
                                    <TextView fontSize="16px" fontWeight="400" color={theme.colors.loopEmerald}>
                                        {'Perfect! '}
                                    </TextView>
                                )}
                                {parseInt(selectedSI) < message?.SI
                                    ? message.lowMessage
                                    : `${getSumInsured(selectedSI, 0) + message?.highMessage}`}
                            </TextView>
                        </>
                    ) : null}
                </StyledSIContainer>
                <StyledInternalTopContainer $borderRadius={'16px 16px 0px 0px'}>
                    <InsuranceIdentityTopUp
                        policyCategory={'Insurer'}
                        policyName={allPolicyData?.policyName ?? ''}
                        policyImage={allPolicyData?.policyLogo ?? ''}
                        textColor={'white'}
                    />
                    <StyledDetailsWrapper>
                        <TextView fontSize="14px" margin="5px 0px 0px 0px" fontWeight="400">
                            Total Annual Premium
                        </TextView>

                        {premiumLoading ? (
                            <StyledLoaderWrapper>
                                <Loader inverted={true} />
                            </StyledLoaderWrapper>
                        ) : (
                            <StyledAPContainerSpan>
                                {topUpAmount?.loading ? (
                                    <img src={AmountIcon} />
                                ) : (
                                    <>
                                        {memberTopUpPremium > 0 ? <StyledSup>₹</StyledSup> : null}
                                        {memberTopUpPremium > 0 ? (
                                            memberTopUpPremium?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        ) : (
                                            <img src={AmountIcon} />
                                        )}
                                        {memberTopUpPremium > 0 ? <StyledSub>/year</StyledSub> : null}
                                    </>
                                )}
                            </StyledAPContainerSpan>
                        )}
                        {!premiumLoading ? (
                            <TextView fontSize="14px" margin="0px 0px 5px 0px" fontWeight="400">
                                EMI option available
                            </TextView>
                        ) : null}
                    </StyledDetailsWrapper>
                </StyledInternalTopContainer>
                <StyledTaxContainer>
                    <TextView margin="0px 0px 0px 16px" fontSize="14px" color={theme.colors.loopEmerald}>
                        You can{' '}
                        <b>
                            save upto ₹
                            {topUpAmount?.digitPremiumWithoutGST ? (
                                <>
                                    {topUpAmount?.digitPremiumWithoutGST >= 25000 ? (
                                        <>25,000</>
                                    ) : (
                                        topUpAmount?.digitPremiumWithoutGST
                                            ?.toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    )}
                                </>
                            ) : (
                                <>25,000</>
                            )}
                        </b>{' '}
                        as tax benefit on this annual premium.
                    </TextView>
                </StyledTaxContainer>
                <StyledContainer>
                    <ImageView
                        width="24px"
                        containerWidth="36px"
                        containerMinHeight="36px"
                        backgroundColor={theme.colors.coverUpGreen}
                        src={PersonImg}
                        radius="22.5px"
                    />
                    <Typography variant="medium" color="emerald">
                        Covered members
                    </Typography>

                    <Tooltip
                        beak="top-center"
                        text={'List of members who are covered in your super top-up policy.'}
                        type="informative"
                    >
                        <StyledInformationItemTooltipIcon src={InfoDefaultIcon} />
                    </Tooltip>
                </StyledContainer>
                <StyledDottedDivider />
                <StyledNameContainer>
                    {policyData.dependents.map((dep, index: number) => {
                        return (
                            <MemberIdentity
                                key={index}
                                firstName={dep.firstName}
                                lastName={dep.lastName}
                                relationship={dep.relationship}
                                index={index}
                                age={getAgeFromDOB(dep?.doB)}
                            />
                        );
                    })}
                </StyledNameContainer>
                <StyledDottedDivider />
                <Typography variant="title2" color="emerald">
                    Need help finding the
                    <Typography variant="title2" color="planRed">
                        {' right plan '}
                    </Typography>
                    for your family?
                </Typography>
                <ButtonContainer>
                    <StyledButtonWrapper>
                        <LoopButton variant="filled" onClick={() => handleCallExpertClick()}>
                            Call an expert
                        </LoopButton>
                    </StyledButtonWrapper>

                    <StyledButtonContainer>
                        <LoopButton variant="outline" onClick={() => handleChatCTAClick()}>
                            Chat with us →
                        </LoopButton>
                    </StyledButtonContainer>

                    <StyledChatSpan onClick={() => handleChatCTAClick()}>
                        <Typography variant="large" color="emerald">
                            Chat with us →
                        </Typography>
                    </StyledChatSpan>
                </ButtonContainer>
                <TopupCallback visible={callbackIsVisible} setVisible={setCallbackIsVisible} />
            </StyledTopUpContainer>
        </Container>
    );
};

export default TopupCustomization;
