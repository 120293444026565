export const REDIRECT_URL = {
    STORE: 'https://www.consult.loophealth.com/app',
    APP_STORE: 'https://apps.apple.com/in/app/loop-health/id1495161232',
    PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.invoqhealth.loophealth.patient',
    TOP_UP: 'https://topup.loophealth.com/',
    LOOP_APP: 'https://app.loophealth.com/link',
    TERMS_CONDITIONS:
        // eslint-disable-next-line max-len
        'https://docs.google.com/presentation/d/e/2PACX-1vRMn0P6Mqt344QOMvaXGqEhDGK4kwHuReGZpX3SpQ-TnnsgGUu1ayh9aWukmt0C_gEbwRz4LSEa3K2z/pub?start=false&loop=false&delayms=3000&slide=id.g2186cb51bd1_0_65'
    // eslint-disable-next-line max-len
    // 'https://storage.googleapis.com/loophealthpatient.appspot.com/Insurance/ICICI%20Adhoc%20Document%20T%26C%201.pdf'
};
