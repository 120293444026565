import { IBenefits } from '../../../../redux/slices/PolicyListSlice/types';

interface IModularReturn {
    getSelectedAlacarte: () => string[];
    getSILabel: () => string;
    getSI: () => ISIData;
}

interface ISIData {
    planName?: string;
    sumInsured: string;
}

interface IModularProps {
    benefits?: IBenefits;
    sumInsured: string;
    isEnrolmentComplete: boolean;
    isModular?: boolean;
}

export const useModular = ({ benefits, sumInsured, isEnrolmentComplete, isModular }: IModularProps): IModularReturn => {
    const getSelectedAlacarte = (): string[] => {
        const selectedAlacarteNames = benefits?.alacarte?.map((benefit) => benefit.name);
        return selectedAlacarteNames || [];
    };

    const getSILabel = (): string => {
        if (isModular && isEnrolmentComplete) return 'Selected Plan';
        else if (isModular && !isEnrolmentComplete) return 'Sum Insured Starts From';
        return 'Sum Insured';
    };

    const getSI = (): ISIData => {
        const selectedPlan = benefits?.modular?.[0];
        if (selectedPlan) {
            return {
                planName: isEnrolmentComplete ? selectedPlan.name : '',
                sumInsured: selectedPlan?.sumInsured?.toString()
            };
        }
        return { sumInsured };
    };
    return { getSelectedAlacarte, getSILabel, getSI };
};
