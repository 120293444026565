import React from 'react';
import { IPageStepper } from './types';
import {
    StyledPageStepper,
    StyledStepperStep,
    StyledStepperTextContainer,
    StyledStepperTextDot,
    StyledStepsContainer
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';

const PageStepper: React.FC<IPageStepper> = ({ totalSteps, currentStep, text }) => {
    return (
        <StyledPageStepper>
            <StyledStepsContainer>
                {Array.from({ length: totalSteps }).map((_, index) => (
                    <StyledStepperStep key={index} $completed={currentStep > index} $current={currentStep === index} />
                ))}
            </StyledStepsContainer>
            <StyledStepperTextContainer>
                <Typography variant="small" weight="semiBold">
                    Step {currentStep + 1} of {totalSteps}
                </Typography>
                {text && (
                    <>
                        <StyledStepperTextDot />
                        <Typography variant="small">{text}</Typography>
                    </>
                )}
            </StyledStepperTextContainer>
        </StyledPageStepper>
    );
};

export default PageStepper;
