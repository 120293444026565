import React from 'react';
import { IGenericIconProps } from './types';

const AppStore: React.FC<IGenericIconProps> = ({ width = 18, height = 20, fill = '#7F8785' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
    >
        <path
            // eslint-disable-next-line max-len
            d="M8.9245 5.04221C9.99791 5.04221 10.9396 4.62305 11.7494 3.78471C12.5593 2.94637 12.9643 1.97153 12.9643 0.860192C12.9643 0.738135 12.9546 0.564686 12.9354 0.339844C12.7939 0.359116 12.6879 0.375176 12.6172 0.388024C11.6273 0.529353 10.7564 1.01437 10.0043 1.84305C9.25231 2.67175 8.8763 3.55827 8.8763 4.50261C8.8763 4.6118 8.89236 4.79169 8.9245 5.04221ZM12.7714 19.7757C13.5363 19.7757 14.3816 19.2521 15.3072 18.205C16.2328 17.1579 16.9398 15.9277 17.4283 14.5144C15.6093 13.5765 14.6997 12.2307 14.6997 10.4769C14.6997 9.01225 15.4357 7.75958 16.9077 6.71889C15.8857 5.4405 14.5358 4.80131 12.8582 4.80131C12.1512 4.80131 11.5052 4.9073 10.9203 5.1193L10.5539 5.2542L10.0622 5.44693C9.7408 5.56898 9.44835 5.63003 9.18482 5.63003C8.97913 5.63003 8.70915 5.55935 8.37494 5.41802L7.9989 5.26384L7.64219 5.1193C7.12153 4.90088 6.56233 4.79167 5.96457 4.79167C4.36408 4.79167 3.07855 5.33129 2.10797 6.41053C1.13738 7.48975 0.6521 8.91268 0.6521 10.6793C0.6521 13.1654 1.42984 15.462 2.98534 17.569C4.06519 19.0401 5.05184 19.7757 5.94527 19.7757C6.32452 19.7757 6.70053 19.7018 7.07333 19.554L7.54576 19.3613L7.9218 19.2264C8.44885 19.0401 8.93414 18.947 9.37764 18.947C9.84686 18.947 10.3868 19.0658 10.9974 19.3035L11.2963 19.4191C11.9198 19.6568 12.4115 19.7757 12.7714 19.7757Z"
            fill={fill}
        />
    </svg>
);

export default AppStore;
