import React from 'react';
import ModularEnrolmentHeader from '../ModularEnrolmentHeader';
import { StyledAddDependentsContainer, StyledContainer, StyledLoaderContainer } from './styles';
import { IAction, useAddDependents } from './hooks/useAddDependents';
import { IModularFamily } from '../../../redux/slices/ModularEnrolmentSlice/types';
import DisplayDependents from './DisplayDependents';
import ManageModularMemberModal from '../ManageModularMember';
import { Loader } from '@loophealth/loop-ui-web-library';
import DeleteModularMemberModal from '../DeleteModularMember';

const ModularAddDependents: React.FC = () => {
    const {
        relations,
        getMembersToDisplay,
        currentMapping,
        targetMapping,
        getTitle,
        setSelectedParentOption,
        addMemberModal,
        setAddMemberModal,
        formValues,
        formErrors,
        onAddCardClick,
        handleFormChange,
        relationshipValues,
        currentAction,
        editMemberModal,
        setEditMemberModal,
        onEditCardClick,
        isLoading,
        onSaveDetails,
        crudLoading,
        setDeleteMemberModal,
        deleteMemberModal,
        onDeleteClick,
        onDeleteMember
    } = useAddDependents();

    return (
        <StyledContainer>
            <ModularEnrolmentHeader
                title={'Who do you want to cover?'}
                subtitle={'Add your family members to the policy for comprehensive coverage'}
            />
            {isLoading ? (
                <StyledLoaderContainer>
                    <Loader />
                </StyledLoaderContainer>
            ) : (
                <>
                    <StyledAddDependentsContainer>
                        {Array.from(Array(relations.length ?? 0), (_element, index) => {
                            if (relations.length === 1 || relations[index] !== 'Self' || index !== 0) {
                                return (
                                    <DisplayDependents
                                        key={index}
                                        title={getTitle(index)}
                                        relation={relations[index]}
                                        membersToDisplay={getMembersToDisplay(relations[index])}
                                        currentMapping={currentMapping}
                                        targetMapping={targetMapping}
                                        onAddMemberClick={() => onAddCardClick(relations[index])}
                                        onMemberEditClick={(dep: IModularFamily) => onEditCardClick(dep)}
                                        onMemberDeleteClick={(id: string) => onDeleteClick(id, relations[index])}
                                        setSelectedParentOption={setSelectedParentOption}
                                    />
                                );
                            }
                        })}
                    </StyledAddDependentsContainer>
                </>
            )}
            <ManageModularMemberModal
                isVisible={currentAction === IAction.ADD ? addMemberModal : editMemberModal}
                setIsVisible={currentAction === IAction.ADD ? setAddMemberModal : setEditMemberModal}
                formValues={formValues}
                formErrors={formErrors}
                relationshipValues={relationshipValues}
                isLoading={crudLoading}
                onSubmit={onSaveDetails}
                handleFormChange={(e, type) => handleFormChange(e, type)}
                currentAction={currentAction}
            />
            <DeleteModularMemberModal
                setIsVisible={setDeleteMemberModal}
                isVisible={deleteMemberModal}
                confirmDelete={onDeleteMember}
                loading={crudLoading}
            />
        </StyledContainer>
    );
};

export default ModularAddDependents;
