import React from 'react';
import ThresholdContainer from '../../../../containers/ThresholdContainer';
import { StyledThresholdPage } from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import {
    InsuranceTypeConstants,
    INTERNAL_ROUTES
} from '../../../../../utils/constants';
import { IPolicy } from '../../../../../redux/slices/PolicyListSlice/types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import {
    filterDummyPolicies,
    filterOutStepathonPolicies,
    getNextPolicyToEnroll,
    sortPolicies
} from '../DashboardPage/utils';
import { ThresholdData } from '../../../../../utils/constants/ModularConstants';
import { setCurrentlySelectedPolicy } from '../../../../../redux/slices/EnrolmentSlice';

const ThresholdPage: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const rawPolicyList: IPolicy[] =
    useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const policyList: IPolicy[] = filterOutStepathonPolicies(rawPolicyList);
    const sortPolicyList = sortPolicies(policyList);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, realPolicyList] = filterDummyPolicies(sortPolicyList);
    const nextPolicy = getNextPolicyToEnroll(realPolicyList);

    const handleBack = () => {
        history.push(INTERNAL_ROUTES.dashboard);
    };

    const handleNext = () => {
        dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: nextPolicy }));
        history.push(INTERNAL_ROUTES.enrolment, { previousUrl: location.pathname });
    };

    return (
        <StyledThresholdPage>
            <ThresholdContainer
                handleBack={handleBack}
                handleNext={handleNext}
                policyImage={nextPolicy?.policyImage}
                policyName={nextPolicy?.policyName}
                policyCategory={InsuranceTypeConstants[nextPolicy?.policyType || '']}
                {...(nextPolicy?.policyType === 'GTL'
                    ? ThresholdData.GTL
                    : ThresholdData.GPA)}
            />
        </StyledThresholdPage>
    );
};

export default ThresholdPage;
