import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../redux';
import { IPolicy } from '../../../../../../redux/slices/PolicyListSlice/types';
import { filterDummyPolicies, filterOutStepathonPolicies, getNextPolicyToEnroll, sortPolicies } from '../utils';
import { InsuranceTypeConstants, INTERNAL_ROUTES } from '../../../../../../utils/constants';
import useEnrolmentNavigation from './useEnrolmentNavigation';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationState } from '../../../../../../utils/types';
import { INextPolicy } from '../types';

export const useNextPolicy = (): INextPolicy => {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const rawPolicyList = useSelector((state: ReduxState) => state.policyList.policyList);
    const rawPolicyListData: IPolicy[] = rawPolicyList?.data || [];
    const policyList: IPolicy[] = filterOutStepathonPolicies(rawPolicyListData);
    const sortPolicyList = sortPolicies(policyList);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, realPolicyList] = filterDummyPolicies(sortPolicyList);
    const nextPolicy = getNextPolicyToEnroll(realPolicyList);
    const pendingPolicies = realPolicyList
        .filter((policy) => policy.enrolmentStatus?.toLowerCase() === 'pending')
        ?.map((policy) => InsuranceTypeConstants[policy?.policyType]);
    const redirectToEnrolment = useEnrolmentNavigation();
    const isPendingPolicyEmpty =
        !rawPolicyList.loading && rawPolicyListData?.length > 0 && pendingPolicies?.length === 0;
    const completedPolicy = realPolicyList?.find((policy) => policy.policyId === location?.state?.policyId);

    const navigateNext = () => {
        if (nextPolicy?.policyType !== 'GMC') {
            history.push(INTERNAL_ROUTES.threshold);
        } else {
            redirectToEnrolment(nextPolicy);
        }
    };

    return { navigateNext, isPendingPolicyEmpty, completedPolicy, pendingPolicies, rawPolicyList };
};
