import React from 'react';
import { StyledConfirmationPage } from './styles';
import ModularConfirmation from '../../../../containers/RegistrationConfirmation';

const ConfirmationPage: React.FC = () => {
    return (
        <StyledConfirmationPage>
            <ModularConfirmation />
        </StyledConfirmationPage>
    );
};

export default ConfirmationPage;
