import React from 'react';
import { IPolicyBenefitsModal } from './types';
import { CardIcon, Modal, Typography } from '@loophealth/loop-ui-web-library';
import {
    StyledBenefitsContainer,
    StyledBenefitsDetails,
    StyledBenefitsDetailsContainer,
    StyledBenefitsIconContainer,
    StyledBenefitsTitle,
    StyledBodyContainer,
    StyledDetailsRow,
    StyledHeaderContainer,
    StyledModalContainer,
    StyledPlan
} from './styles';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { formatAmount } from '../../../utils/common';
import { CheckIcon, Star } from '../../../assets/img';
import { CheckMark } from '../../../assets/img/CustomIcons';
import theme from '../../../theme';
import { IModuleProperty } from '../../../redux/slices/PolicyListSlice/types';
import { getSortedBasePlanProperties } from '../ModularPlansContainer/utils';

interface IDisplayBenefits {
    title: string;
    icon: string;
    pointerBg: string;
    details: IModuleProperty[];
}

const DisplayBenefits: React.FC<IDisplayBenefits> = ({ title, icon, pointerBg, details }) => {
    return (
        <StyledBenefitsContainer>
            <StyledBenefitsTitle>
                <StyledBenefitsIconContainer>
                    <img src={icon} />
                </StyledBenefitsIconContainer>
                <Typography variant="large" weight="medium">
                    {title}
                </Typography>
            </StyledBenefitsTitle>
            {details.map((detail, index) => (
                <StyledBenefitsDetailsContainer key={index}>
                    <CheckMark fill={pointerBg} />
                    <StyledBenefitsDetails>
                        <Typography variant="extraSmall">{detail.name}</Typography>
                        <Typography variant="small" weight="medium">
                            {detail.value}
                        </Typography>
                    </StyledBenefitsDetails>
                </StyledBenefitsDetailsContainer>
            ))}
        </StyledBenefitsContainer>
    );
};

const PolicyBenefitsModal: React.FC<IPolicyBenefitsModal> = ({ setIsVisible, isVisible, plan }) => {
    const sortedProperties = getSortedBasePlanProperties(plan, true);

    const keyBenefits = sortedProperties?.slice(0, 2);
    const benefitSummary = sortedProperties?.slice(2);

    return plan ? (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} disablePadding>
            <StyledModalContainer>
                <WithThemeProvider>
                    <StyledHeaderContainer>
                        <CardIcon imageUrl={plan.logo} size="58px" />
                        <StyledPlan>
                            <Typography variant="title2" weight="medium">
                                {plan.name}
                            </Typography>
                            <Typography variant="extraSmall">{plan.description}</Typography>
                        </StyledPlan>
                    </StyledHeaderContainer>
                    <StyledBodyContainer>
                        <StyledDetailsRow>
                            <Typography variant="extraSmall">You get Sum Insured of</Typography>
                            <Typography variant="large" weight="medium" color="emerald">
                                {formatAmount(plan.sumInsured)}
                            </Typography>
                        </StyledDetailsRow>
                        <StyledDetailsRow>
                            <Typography variant="extraSmall">Family Structure</Typography>
                            <Typography variant="small" weight="medium">
                                {plan.familyDefinition}
                            </Typography>
                        </StyledDetailsRow>

                        <DisplayBenefits
                            title="Key Benefits"
                            icon={Star}
                            pointerBg={theme.colors.coverYellow}
                            details={keyBenefits}
                        />

                        <DisplayBenefits
                            title="Benefits Summary"
                            icon={CheckIcon}
                            pointerBg={theme.colors.teamTurquoise}
                            details={benefitSummary}
                        />
                    </StyledBodyContainer>
                </WithThemeProvider>
            </StyledModalContainer>
        </Modal>
    ) : null;
};

export default PolicyBenefitsModal;
