import styled from 'styled-components';

export const StyledCardContainer = styled.div`
  margin: auto;
  max-width: 60vw;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 16px;
  display: flex;
  padding: 36px;
  gap: 32px;
`;

export const StyledPolicyInfoContainer = styled.div`
  display: flex;
  padding: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.colors.mutedTransparent};
`;

export const StyledPolicyDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;


export const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 36px;
`;

export const StyledFlexContainer = styled.div<{
  gap: string;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}>(
    (p) => `
  display:flex;
  flex-direction:${p.flexDirection || 'row'};
  gap:${p.gap}
`
);
