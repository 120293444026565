import styled from 'styled-components';

export const StyledOnboardingScreenFtuxWrapper = styled.div`
    display: flex;
    height: 100%;
    padding: 0 48px;
    display: flex;
    align-items: center;
    gap: 100px;
    flex-wrap: wrap-reverse;
`;

export const StyledOnboardingScreenFtuxItem = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    min-height: 350px;
    min-width: 300px;
`;

export const StyledOnboardingScreenImage = styled.img`
    width: 100%;
    height: 100%;
    flex: 1;
`;

export const StyledOnboardingScreenItemHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const StyledOnboardingScreenItemHeaderBadgeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledOnboardingScreenItemColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledOnboardingScreenItemHeaderBadge = styled.div`
    border-radius: 50px;
    padding: 4px 12px;
    background-color: ${(p) => p.theme.colors.mutedTransparent};
    width: fit-content;
`;

export const StyledOnboardingScreenCTA = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
`;
