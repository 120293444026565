import { Avatar, Dialog, Modal, Shadow, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { DropdownIcon, ExitErrorIcon, LoopLogo } from '../../../assets/img';
import { RootState } from '../../../redux/reducers';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
    StyledChevronIcon,
    StyledContainer,
    StyledHeaderProfile,
    StyledLogo,
    StyledProfileDropdownContainer,
    StyledProfileDropdownItem,
    StyledProfileDropdownItems
} from './styles';
import { IHeader } from './types';

const Header: React.FunctionComponent<IHeader> = ({ signout, redirectToMain }) => {
    const user = useSelector((state: RootState) => state.user.userData.data);
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = React.useState(false);
    const [signoutModalVisible, setSignoutModalVisible] = React.useState(false);

    const toggleProfileIcon = () => setIsProfileDropdownOpen(!isProfileDropdownOpen);
    const onBackToDashboardClick = () => {
        redirectToMain();
        toggleProfileIcon();
    };
    const onSignoutClick = () => {
        setSignoutModalVisible(true);
        toggleProfileIcon();
    };
    return (
        <StyledContainer>
            <StyledLogo src={LoopLogo} alt={'Loop Health Logo'} onClick={redirectToMain} />
            <StyledHeaderProfile>
                <Avatar users={[{ name: user?.firstName || '', imageURL: '' }]} />
                <StyledChevronIcon src={DropdownIcon} $flip={isProfileDropdownOpen} onClick={toggleProfileIcon} />
                {isProfileDropdownOpen && (
                    <StyledProfileDropdownContainer>
                        <Shadow variant="bottom">
                            <WithThemeProvider>
                                <StyledProfileDropdownItems>
                                    <StyledProfileDropdownItem onClick={onBackToDashboardClick}>
                                        <Typography variant="small">Back to Dashboard</Typography>
                                    </StyledProfileDropdownItem>
                                    <StyledProfileDropdownItem onClick={onSignoutClick}>
                                        <Typography variant="small">Sign Out</Typography>
                                    </StyledProfileDropdownItem>
                                </StyledProfileDropdownItems>
                            </WithThemeProvider>
                        </Shadow>
                    </StyledProfileDropdownContainer>
                )}
                <Modal isVisible={signoutModalVisible} setIsVisible={setSignoutModalVisible}>
                    <Dialog
                        variant="vertical"
                        icon={ExitErrorIcon}
                        description=""
                        title="Are you sure you want to sign out?"
                        primaryButtonText="Sign Out"
                        onPrimaryButtonClicked={signout}
                        onSecondaryButtonClicked={() => setSignoutModalVisible(false)}
                        secondaryButtonText="Never mind"
                        buttonVariant="error"
                    />
                </Modal>
            </StyledHeaderProfile>
        </StyledContainer>
    );
};

export default Header;
