import React from 'react';
import { Typography } from '@loophealth/loop-ui-web-library';
import { IOnboardingScreenStepItem } from './types';
import { FtuxGraphicOne, FtuxGraphicThree, FtuxGraphicTwo } from '../../../assets/img';

export const OnboardingScreenFtuxSteps: IOnboardingScreenStepItem[] = [
    {
        title: (
            <Typography variant="titleLarge" weight="bold">
                <Typography variant="titleLarge" weight="bold" color="emerald">
                    Unlimited
                </Typography>
                &nbsp;healthcare from&nbsp;
                <Typography variant="titleLarge" weight="bold" color="emerald">
                    top 10%
                </Typography>
                &nbsp;doctors
            </Typography>
        ),
        subtitle: 'Health benefits designed to keep you and your family out of the hospital.',
        nextButtonText: 'Next',
        showBadge: true,
        image: FtuxGraphicOne
    },
    {
        title: (
            <Typography variant="titleLarge" weight="bold">
                <Typography variant="titleLarge" weight="bold" color="emerald">
                    Claims experts
                </Typography>
                &nbsp;that work 24x7&nbsp;
                <Typography variant="titleLarge" weight="bold" color="emerald">
                    to support
                </Typography>
                &nbsp;you
            </Typography>
        ),
        subtitle: 'From filing to discharge, get end-to-end claims support from a dedicated team.',
        nextButtonText: 'Next',
        image: FtuxGraphicTwo,
        backButton: true
    },
    {
        title: (
            <Typography variant="titleLarge" weight="bold">
                <Typography variant="titleLarge" weight="bold" color="emerald">
                    Easy registration
                </Typography>
                &nbsp;to start your family’s coverage
            </Typography>
        ),
        subtitle:
            // eslint-disable-next-line max-len
            'Spend less than 5 minutes to enroll you and your family on Loop to enjoy health benefits and stress-free claims',
        nextButtonText: 'Get Started',
        image: FtuxGraphicThree,
        backButton: true
    }
];
