import { Modal, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
    StyledCostPremiumIcon,
    StyledCostPremiumIconWrapper,
    StyledCostPremiumItem,
    StyledCostPremiumModal,
    StyledCostPremiumModalContent,
    StyledCostPremiumModalFooter,
    StyledCostPremiumModalPremium,
    StyledCostPremiumText
} from './styles';
import { ICostPremiumModal } from './types';
import { formatAmount } from '../../../utils/common';
import {
    StyledModularSummaryColumn,
    StyledModularSummaryColumnRight,
    StyledModularSummaryHorizontalDottedDivider,
    StyledModularSummaryPlanWallet
} from '../ModularEnrolmentSummary/styles';
import { WalletIcon } from '../../../assets/img';

const CostPremiumModal: React.FC<ICostPremiumModal> = ({
    setIsVisible,
    isVisible,
    modular,
    alacarte,
    priceSummary,
    title,
    taxExcluded
}) => {
    const toUseWallet = priceSummary?.walletDeducted ? priceSummary.walletDeducted > 0 : false;
    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} title={title} disablePadding>
            <WithThemeProvider>
                <StyledCostPremiumModal>
                    <StyledCostPremiumModalContent>
                        {modular ? (
                            <StyledCostPremiumItem>
                                <StyledCostPremiumIconWrapper>
                                    <StyledCostPremiumIcon src={modular.logo} />
                                    <StyledCostPremiumText>
                                        <Typography variant="small" weight="semiBold">
                                            {modular.name}
                                        </Typography>
                                    </StyledCostPremiumText>
                                </StyledCostPremiumIconWrapper>
                                <Typography variant="small" weight="semiBold">
                                    {formatAmount(modular.price)}
                                </Typography>
                            </StyledCostPremiumItem>
                        ) : null}
                        {alacarte.map((plan) => (
                            <StyledCostPremiumItem key={plan.id}>
                                <StyledCostPremiumIconWrapper>
                                    <StyledCostPremiumIcon src={plan.logo} />
                                    <StyledCostPremiumText>
                                        <Typography variant="small" weight="semiBold">
                                            {plan.name}
                                        </Typography>
                                        {plan.subCategories.length ? (
                                            <Typography variant="small" color="secondary">
                                                {plan.subCategories[0].name}
                                            </Typography>
                                        ) : null}
                                    </StyledCostPremiumText>
                                </StyledCostPremiumIconWrapper>
                                <Typography variant="small" weight="semiBold">
                                    {formatAmount(plan.price)}
                                </Typography>
                            </StyledCostPremiumItem>
                        ))}
                    </StyledCostPremiumModalContent>
                    <StyledModularSummaryHorizontalDottedDivider />
                    {priceSummary && (
                        <StyledCostPremiumModalFooter>
                            <StyledCostPremiumModalPremium>
                                <StyledCostPremiumItem>
                                    <Typography variant="small" weight="medium">
                                        Total Premium Amount
                                    </Typography>
                                    <Typography variant="small" weight="medium">
                                        {formatAmount(priceSummary.totalPremium)}
                                    </Typography>
                                </StyledCostPremiumItem>
                                {!taxExcluded && (
                                    <StyledCostPremiumItem>
                                        <Typography variant="small" weight="medium">
                                            GST
                                        </Typography>
                                        <Typography variant="small" weight="medium">
                                            {formatAmount(priceSummary.gst)}
                                        </Typography>
                                    </StyledCostPremiumItem>
                                )}
                                {toUseWallet && (
                                    <StyledCostPremiumItem>
                                        <StyledModularSummaryPlanWallet>
                                            <Typography variant="small" weight="medium">
                                                Wallet Balance Used
                                            </Typography>
                                            <img src={WalletIcon} alt="wallet" />
                                        </StyledModularSummaryPlanWallet>
                                        <Typography variant="small" weight="medium" color="error">
                                            - {formatAmount(priceSummary.walletDeducted)}
                                        </Typography>
                                    </StyledCostPremiumItem>
                                )}
                            </StyledCostPremiumModalPremium>
                            <StyledModularSummaryHorizontalDottedDivider />
                            <StyledCostPremiumItem>
                                <StyledModularSummaryColumn>
                                    <Typography variant="large" weight="semiBold">
                                        To Pay
                                    </Typography>
                                    <Typography variant="small" color="secondary">
                                        {taxExcluded ? '(excluding tax)' : '(including tax)'}
                                    </Typography>
                                </StyledModularSummaryColumn>
                                <StyledModularSummaryColumnRight>
                                    <Typography variant="large" weight="semiBold">
                                        {formatAmount(priceSummary.salaryDeducted)}
                                    </Typography>
                                    {priceSummary.salaryDeducted ? (
                                        <Typography variant="small" color="secondary">
                                            Amount will be deducted from your salary
                                        </Typography>
                                    ) : null}
                                </StyledModularSummaryColumnRight>
                            </StyledCostPremiumItem>
                        </StyledCostPremiumModalFooter>
                    )}
                </StyledCostPremiumModal>
            </WithThemeProvider>
        </Modal>
    );
};

export default CostPremiumModal;
