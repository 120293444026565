import React from 'react';
import { IGenericIconProps } from './types';

const Edit: React.FC<IGenericIconProps> = ({ width = 43, height = 42, strokeColor = '#FFF', strokeWidth = '2' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 43 42" fill="none">
        <path
            d="M19.75 37.1465L38.125 37.1465M4.875 37.1465L13.625 37.1465"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.1773 6.82267L10.4397 21.5603C9.66594 22.3341 9.1533 23.3302 8.97341 24.4095L7.90278 30.8333C7.70537 32.0178 8.73223 33.0446 9.91667 32.8472L16.2155 31.7974C17.3718 31.6047 18.4303 31.0305 19.2225 30.1664L33.4556 14.6393C35.5087 12.3996 35.2377 8.89018 32.8652 6.99219L32.1693 6.43543C30.0804 4.76428 27.0689 4.93107 25.1773 6.82267Z"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Edit;
