import React from 'react';
import { GroupIcon, InsuranceIcon, LoopCalendarIcon, QuotesImg, SelectedBenefits } from '../../../assets/img';
import PolicyInfoDetails from '../PolicyInfoDetails';
import theme from '../../../theme';
import { StyledBenefits, StyledContentWrapper, StyledBenefitsWrapper, StyledSIValue } from './styles';
import { Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import { formatAmount } from '../../../utils/common';

interface IPolicyDetails {
    sumInsured: string;
    isTopUpPolicy: boolean;
    annualPremium: string;
    policyEndDate?: string;
    enrolmentDueDate: string;
    dependentsInsured: string;
    selectedBenefits: string[];
    SILabel: string;
    planName?: string;
}

export const Benefits: React.FC<{ benefits: string[] }> = ({ benefits }) => {
    const displayedBenefits = benefits.slice(0, 2).join(', ');
    return (
        <StyledBenefitsWrapper>
            <Typography variant="small" weight="medium">
                {displayedBenefits}
            </Typography>
            {benefits.length > 2 && (
                <Tooltip type="informative" beak="bottom-left" text={benefits.slice(2).join(', ')}>
                    <StyledBenefits>
                        <Typography variant="small" weight="bold" color="emerald">
                            +{benefits.length - 2} More
                        </Typography>
                    </StyledBenefits>
                </Tooltip>
            )}
        </StyledBenefitsWrapper>
    );
};

const SIValue: React.FC<{ planName?: string; sumInsured: string }> = ({ planName, sumInsured }) => {
    return (
        <StyledSIValue>
            {planName && (
                <Typography variant="small" weight="semiBold">
                    {planName}
                </Typography>
            )}
            <Typography variant="small" weight="medium">
                {planName ? formatAmount(sumInsured) : sumInsured}
            </Typography>
        </StyledSIValue>
    );
};

const PolicyDetails: React.FC<IPolicyDetails> = ({
    sumInsured,
    isTopUpPolicy,
    policyEndDate,
    annualPremium,
    enrolmentDueDate,
    dependentsInsured,
    selectedBenefits,
    SILabel,
    planName
}) => {
    return (
        <StyledContentWrapper $extendTemplate={selectedBenefits.length > 0}>
            <PolicyInfoDetails
                icon={QuotesImg}
                label={SILabel}
                value={<SIValue planName={planName} sumInsured={sumInsured} />}
                bgColor={theme.colors.mutedTransparent}
                itemName="sumInsured"
            />
            {isTopUpPolicy ? (
                <>
                    <PolicyInfoDetails
                        icon={LoopCalendarIcon}
                        label="Valid Till"
                        value={policyEndDate ?? ''}
                        bgColor={theme.colors.mutedTransparent}
                        itemName="enrolmentDate"
                    />
                    <PolicyInfoDetails
                        icon={InsuranceIcon}
                        label="Annual Premium Paid"
                        value={annualPremium}
                        bgColor={theme.colors.mutedTransparent}
                        itemName=""
                    />
                </>
            ) : (
                <>
                    <PolicyInfoDetails
                        icon={LoopCalendarIcon}
                        label="Registration Deadline"
                        value={enrolmentDueDate}
                        bgColor={theme.colors.planRed}
                        textColor="error"
                        itemName="enrolmentDate"
                    />
                    <PolicyInfoDetails
                        icon={GroupIcon}
                        label="Eligible Members"
                        value={dependentsInsured}
                        bgColor={theme.colors.mutedTransparent}
                        itemName="coveredMembers"
                    />
                </>
            )}
            {selectedBenefits.length > 0 && (
                <PolicyInfoDetails
                    icon={SelectedBenefits}
                    label="Selected Benefits"
                    value={<Benefits benefits={selectedBenefits} />}
                    bgColor={theme.colors.mutedTransparent}
                    itemName="selectedBenefits"
                />
            )}
        </StyledContentWrapper>
    );
};

export default PolicyDetails;
