import styled from 'styled-components';

export const StyledCardContainer = styled.div`
    gap: 24px;
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
`;

export const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

export const StyledRowBetween = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`;

export const StyledButton = styled.div`
    display: flex;
    padding: 8px 16px;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

export const StyledBenefitCard = styled.div<{ selected: boolean }>(
    (p) => `
  background: ${p.selected ? p.theme.colors.mutedTransparent : p.theme.colors.white};
  border: 1px solid ${p.selected ? p.theme.colors.loopEmerald : p.theme.colors.seafoamGray};
  display: flex;
  padding: 20px;
  gap: 20px;
  min-height: 180px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
`
);

export const StyledLockInCard = styled.div`
    display: flex;
    flex: 1;
`;

export const StyledHeader = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
`;

export const StyledHeaderText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const StyledImageContainer = styled.img(
    (p) => `
    width: ${p.width ? p.width : '100%'};
    height: ${p.height ? p.height : '100%'};
`
);

export const StyledDetails = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
`;

export const StyledDropDown = styled.div`
    min-width: 220px;
    padding-left: 24px;
`;

export const StyledDropDownContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    border-left: 1px solid ${(p) => p.theme.colors.seafoamGray};
    min-height: 48px;
`;

export const StyledRowPremiumPrice = styled.div<{ selected: boolean }>(
    (p) => `
  display: flex;
  flex-direction: ${p.selected ? 'column' : 'row'};
  align-items: ${p.selected ? 'flex-start' : 'center'};
  gap: ${p.selected ? '4px' : '8px'} ;
`
);

export const StyledBenefitParentContainer = styled.div`
    flex-direction: column;
    display: flex;
    gap: 36px;
    padding: 36px 72px;
`;

export const StyledEmailInfo = styled.div`
    display: flex;
    align-items: flex-start;
    background-color: ${(p) => p.theme.colors.white};
    padding: 10px 24px;
    border-radius: 8px;
    gap: 8px;
`;

export const StyledEmailInfoIcon = styled.img`
    width: 22px;
`;

export const StyledEmailEditIcon = styled.img`
    width: 18px;
    position: relative;
    margin-left: 8px;
    top: 2px;
    cursor: pointer;
`;

export const StyledBenefitDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    & > button {
      width: fit-content;
    }
`;
