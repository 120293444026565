import React, { useEffect } from 'react';
import { IConfirmationModal } from './types';
import { LoopButton, Modal, Typography } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { PeopleHiveFiveGraphic } from '../../../assets/img';
import {
    StyledConfirmationModal,
    StyledConfirmationModalBody,
    StyledConfirmationModalContent,
    StyledConfirmationModalFooter
} from './styles';
import theme from '../../../theme';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const MemberConfirmationModal: React.FC<IConfirmationModal> = ({
    isVisible,
    setIsVisible,
    onSubmit,
    addedMembers,
    totalMembers
}) => {
    const trackClick = useSegment('click');
    const trackPage = useSegment('page');

    const handleAddMore = () => {
        trackClick(SEGMENT_ACTIONS.CLICK.ADD_MORE_DEPENDENTS);
        setIsVisible(false);
    };

    useEffect(() => {
        if (isVisible) {
            trackPage(SEGMENT_ACTIONS.PAGE.ADD_MORE_DEPENDENTS_MODAL);
        }
    }, [isVisible]);

    return (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} disablePadding>
            <WithThemeProvider>
                <StyledConfirmationModal>
                    <img src={PeopleHiveFiveGraphic} alt="People Hive Five Graphic" />
                    <StyledConfirmationModalBody>
                        <Typography variant="title2" weight="medium">
                            Added Everyone?
                        </Typography>
                        <StyledConfirmationModalContent>
                            <Typography variant="medium" weight="medium">
                                {`You've added ${addedMembers} eligible family members, 
                                you can add up to ${totalMembers} members.`}
                            </Typography>
                            <Typography variant="small" color="secondary">
                                {`Proceed if you're done adding dependents`}
                            </Typography>
                        </StyledConfirmationModalContent>
                    </StyledConfirmationModalBody>
                    <StyledConfirmationModalFooter>
                        <LoopButton
                            variant="outline"
                            onClick={handleAddMore}
                            borderColor={theme.colors.loopEmerald}
                            textColor="emerald"
                        >
                            Add More Dependents
                        </LoopButton>
                        <LoopButton variant="filled" onClick={onSubmit}>
                            Yes, Proceed
                        </LoopButton>
                    </StyledConfirmationModalFooter>
                </StyledConfirmationModal>
            </WithThemeProvider>
        </Modal>
    );
};

export default MemberConfirmationModal;
