import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledContentContainer = styled.div`
  padding: 24px 24px 96px 24px;
  max-height: 500px;
  overflow-y: auto;
`;

export const StyledIframeContainer = styled.div`
  position: relative;
`;

export const StyledIframe = styled.iframe`
  min-width: 700px;
  border: none;
`;

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid;
  border-color: ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledModalHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  flex: 1;
`;

export const StyledDescriptionContainer = styled.div<{ isCard?: boolean }>(
    (p) => `
  margin-top: 16px;
  background-color: ${p.isCard ? p.theme.colors.surface0 : 'transparent'};
  padding: ${p.isCard ? '12px 16px' : '0'};
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 720px;
`
);

export const StyledList = styled.ul`
  margin: 0;
  list-style-type: none;
  padding-inline-start: 20px;
  li:before {
    content: " - ";
  }
`;

export const StyledTitle = styled.div`
  text-decoration-line: underline;
  text-decoration-color: ${(p) => p.theme.colors.beyondGrey};
  color: ${(p) => p.theme.colors.beyondGrey};
  text-underline-offset: 2px;
  font-weight: 500;
  font-size: 12px;
`;

export const StyledListItem = styled.li`
  margin-bottom: 8px;
`;

export const StyledChip = styled.div`
  margin-right: 64px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
  background-color: ${(p) => p.theme.colors.platinum};
`;

export const StyledFooter = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 0;
  width: 100%;
  padding: 24px;
  gap: 8px;
  border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
  background-color: ${(p) => p.theme.colors.white};
`;

export const StyledEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40vw;
`;

export const StyledEmailContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 32px;
`;

export const StyledEmailInput = styled.div`
  display: flex;
  align-self: start;
  gap: 8px;
  width: 100%;
  flex-direction: column;
`;

export const StyledEmailFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  border-top: 1px solid ${(p) => p.theme.colors.platinum};
`;

export const StyledEmailTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
`;

export const StyledButton = styled.div`
  display: flex;
  padding: 16px 24px;
`;

export const StyledOpenIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 16px;
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.seafoamGray};
  padding: 4px;
  border-radius: 8px;
  width: 20px;
  aspect-ratio: 1;
  cursor: pointer;
`;
