import React from 'react';
import { IGenericIconProps } from './types';
import theme from '../../../theme';

const InfoIcon: React.FC<IGenericIconProps> = ({
    width = 24,
    height = 24,
    strokeWidth = '1.2',
    strokeColor = theme.colors.primary,
    fill = 'none'
}) => (
    <svg width={width} height={height} viewBox="0 0 16 16" fill={fill}>
        <circle cx="8.00004" cy="7.99967" r="6.06667" stroke={strokeColor} strokeWidth={strokeWidth} />
        <path
            d="M8 7.00033C8.00003 9.00033 8.00003 9.66699 8.00003 11.667M8.00003 5.00033V4.66699"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
        />
    </svg>
);

export default InfoIcon;
