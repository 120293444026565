import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 36px 72px;
`;

export const StyledAddDependentsContainer = styled.div`
    margin-top: 36px;
`;

export const EnrollMembersContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    width: 100%;
    flex-wrap: wrap;
    gap: 24px;
    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
        flex-direction: column;
        gap: 12px;
        background-color: ${(p) => p.theme.colors.white};
        padding: 24px 20px;
        margin: 0px;
    }
`;

export const StyledWarning = styled.div`
    border-radius: 10px;
    max-width: 860px;
    margin: 8px 0px;
    @media (max-width: 768px) {
        margin: 0px 20px;
    }
`;

export const StyledSpacing = styled.div`
    width: fit-content;
    margin: 20px 0px 0px 0px;
    @media (max-width: 768px) {
        margin: 0px 20px 12px 20px;
    }
`;

export const StyledLoaderContainer = styled.div`
    margin-top: 42px;
`;
