import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { FirebaseAuth } from '../../../adapters/provider';
import { logoutUser as resetUserData } from '../LoginContainer/redux/slices/UserSlice';
import { INTERNAL_ROUTES, MAIN_ROUTES } from '../../../utils/constants';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../utils/hooks/useSegment';
import { Header } from '../../atoms';
import InternalRouter from './router';
import { resetState as resetPolicyData } from '../../../redux/slices/PolicyListSlice';
import { ReduxState } from '../../../redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchUserOffboardingDetails } from '../../../adapters';
import { resetState as resetEnrolmentData } from '../../../redux/slices/EnrolmentSlice';
import { Toast } from '@loophealth/loop-ui-web-library';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { resetModularState } from '../../../redux/slices/ModularEnrolmentSlice';
toast.configure();
export const StyledContainer = styled.div<{ $padded: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${(p) => p.theme.colors.background};
    padding-top: ${(p) => (p.$padded ? '60px' : '0')};
`;

const SPAContainer: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const trackClick = useSegment('click');
    const trackLogout = useSegment('identify');
    const user = useSelector((state: ReduxState) => state.user?.userData?.data || '');
    let isUserOffboarded: boolean;
    const shouldDisplayHeader = ![INTERNAL_ROUTES.dashboard, MAIN_ROUTES.app, INTERNAL_ROUTES.home].includes(
        location.pathname
    );

    React.useEffect(() => {
        if (user.userId) {
            const userId = user.userId;
            const offBoardingDetails = fetchUserOffboardingDetails(userId.toString());
            offBoardingDetails
                .then((value) => {
                    isUserOffboarded = value;
                    if (isUserOffboarded) {
                        notifyOffboarded();
                        logout();
                    }
                })
                .catch((err) => {
                    notifySomeError(err.toString());
                });
        }
    }, [user]);

    const logout = () => {
        trackClick(SEGMENT_ACTIONS.CLICK.NAVIGATION_HEADER_SIGNOUT);
        FirebaseAuth.signOut();
        trackLogout({
            name: SEGMENT_ACTIONS.IDENTIFY.USER_LOGOUT.name
        });
        dispatch(resetEnrolmentData());
        dispatch(resetUserData());
        dispatch(resetPolicyData());
        dispatch(resetModularState());
        history.push(MAIN_ROUTES.login);
    };

    const notifyOffboarded = () => {
        toast.error('User is off-boarded !', { autoClose: 10000 });
    };

    const notifySomeError = (errorMessage: string) => {
        toast.error(errorMessage, { autoClose: 10000 });
    };

    const redirectToDashboard = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.NAVIGATION_HEADER_LOGO;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe,
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status
            }
        });
        history.push(INTERNAL_ROUTES.dashboard, { previousUrl: location.pathname });
    };

    return (
        <StyledContainer $padded={shouldDisplayHeader}>
            {shouldDisplayHeader && <Header signout={logout} redirectToMain={redirectToDashboard} />}
            <Toast.ToastContainer
                maxWidth="800px"
                expires={4000}
                closeOnClick={true}
                variant="dark"
                position="top-center"
                showCloseIcon={true}
            >
                <WithThemeProvider>
                    <InternalRouter />
                </WithThemeProvider>
            </Toast.ToastContainer>
        </StyledContainer>
    );
};

export default SPAContainer;
