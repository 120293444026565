import styled from 'styled-components';

export const StyledModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 860px;
    max-height: 75vh;
`;

export const StyledHeaderContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledPlan = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0px;
`;

export const StyledDetailsRow = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledBenefitsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    background: ${(p) => p.theme.colors.surface0};
`;

export const StyledBenefitsTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StyledBenefitsIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    paddig: 4px;
    border-radius: 50%;
    background: ${(p) => p.theme.colors.coverYellow};
`;

export const StyledBenefitsDetailsContainer = styled.div`
    display: flex;
    gap: 12px;
    margin-left: 8px;
`;

export const StyledBenefitsDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
