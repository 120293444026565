import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { parseResponse } from '../../../../../../utils/common';
import { fetchPolicyInclusions } from '../../../../../../adapters';
import { IPolicyInclusions } from '../types';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../redux';
interface FetchPolicyInclusionsReturn {
    detailsLoading: boolean;
    InclusionData: IPolicyInclusions | null;
}

const useFetchPolicyInclusions = (policyId: string): FetchPolicyInclusionsReturn => {
    const [detailsLoading, toggleLoading] = useState<boolean>(false);
    const [InclusionData, setInclusionData] = useState<IPolicyInclusions | null>(null);
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId || '');

    useEffect(() => {
        let isMounted = true;

        const fetchPolicyDetails = async () => {
            if (policyId && userId) {
                toggleLoading(true);

                let error: Error | null = null;
                let result = null;

                [error, result] = await parseResponse(fetchPolicyInclusions(policyId));

                if (isMounted) {
                    toggleLoading(false);
                    if (error) {
                        toast.error(error.message || 'Something went wrong!');
                        console.error(error);
                        return;
                    }
                    const policyTermsData = result;
                    setInclusionData(policyTermsData);
                }
            }
        };

        fetchPolicyDetails();

        return () => {
            isMounted = false;
        };
    }, [policyId, userId]);

    const memoizedInclusionData = useMemo(() => InclusionData, [InclusionData]);

    return { detailsLoading, InclusionData: memoizedInclusionData };
};

export default useFetchPolicyInclusions;
