import { Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { CloseWhiteIcon, WalletIcon } from '../../../assets/img';
import { InfoIcon } from '../../../assets/img/CustomIcons';
import { ReduxState } from '../../../redux';
import {
    selectModularCurrentStep,
    selectModularWalletBalance
} from '../../../redux/slices/ModularEnrolmentSlice/selectors';
import { ModularEnrolmentSteps } from '../../../redux/slices/ModularEnrolmentSlice/types';
import theme from '../../../theme';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { formatAmount } from '../../../utils/common';
import {
    StyledCloseIcon,
    StyledModularEnrolmentHeader,
    StyledModularEnrolmentHeaderWrapper,
    StyledModularHeaderFlexDown,
    StyledModularHeaderFlexDownCTA,
    StyledModularWalletWrapper,
    StyledWalletIcon
} from './styles';
import { IModularEnrolmentHeader } from './types';

const ModularEnrolmentHeader: React.FC<IModularEnrolmentHeader> = ({
    title,
    subtitle,
    freedBalance,
    onFreedBalanceClose
}) => {
    const walletAmount = useSelector(selectModularWalletBalance);
    const companyName = useSelector((state: ReduxState) => state.policyList.companyDetails.data?.companyName);
    const currentStep = useSelector(selectModularCurrentStep);

    const showWallet = !!walletAmount && currentStep !== ModularEnrolmentSteps.SUMMARY;

    return freedBalance ? (
        <StyledModularHeaderFlexDown>
            <StyledModularHeaderFlexDownCTA>
                <Typography variant="medium" weight="semiBold" color="tertiary">
                    📢&nbsp;&nbsp;You now have {formatAmount(freedBalance)} left to spend from your wallet
                </Typography>
                <StyledCloseIcon src={CloseWhiteIcon} onClick={onFreedBalanceClose} />
            </StyledModularHeaderFlexDownCTA>
            <Typography variant="medium" color="tertiary">
                You can use this remaining balance to add more benefits to your insurance in the next steps
            </Typography>
        </StyledModularHeaderFlexDown>
    ) : (
        <StyledModularEnrolmentHeaderWrapper>
            <StyledModularEnrolmentHeader>
                <Typography variant="title2" weight="bold">
                    {title}
                </Typography>
                <Typography variant="small">{subtitle}</Typography>
            </StyledModularEnrolmentHeader>
            {showWallet ? (
                <Tooltip
                    beak="top-right"
                    text={
                        <>
                            {companyName} has given you a fixed wallet of <b>{formatAmount(walletAmount)}</b> to
                            customise your insurance. Any spend beyond this will be deducted from your salary.
                        </>
                    }
                    type="informative"
                >
                    <WithThemeProvider>
                        <StyledModularWalletWrapper>
                            <StyledWalletIcon src={WalletIcon} />
                            <Typography variant="small">Wallet</Typography>
                            <Typography variant="medium" weight="semiBold" color="emerald">
                                {formatAmount(walletAmount)}
                            </Typography>
                            <InfoIcon width="16" height="16" strokeColor={theme.colors.loopEmerald} />
                        </StyledModularWalletWrapper>
                    </WithThemeProvider>
                </Tooltip>
            ) : null}
        </StyledModularEnrolmentHeaderWrapper>
    );
};

export default ModularEnrolmentHeader;
