import React from 'react';
import { StyledContainer, StyledContentWrapper } from './styles';
import { IPolicyInfoCard } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';
import ProfileIcon from '../../atoms/ProfileIcon';

const PolicyInfoDetails: React.FunctionComponent<IPolicyInfoCard> = ({
    label,
    icon,
    value,
    bgColor,
    textColor = 'primary',
    itemName
}) => {
    return (
        <StyledContainer $item={itemName ?? ''}>
            <ProfileIcon icon={icon} bgColor={bgColor} />

            <StyledContentWrapper>
                <Typography variant="extraSmall" color='secondary'>{label}</Typography>
                <Typography variant="small" weight="medium" color={textColor}>
                    {value}
                </Typography>
            </StyledContentWrapper>
        </StyledContainer>
    );
};

export default PolicyInfoDetails;
