import React from 'react';
import {
    StyledChip,
    StyledContainer,
    StyledContentContainer,
    StyledFooter,
    StyledIframe,
    StyledIframeContainer,
    StyledModalHeader,
    StyledModalHeaderTitle,
    StyledOpenIcon
} from './styles';
import { CardIcon, Modal, Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import { IBenefitDetailsModal } from './type';
import { ImageView } from '../../atoms/ImageView';
import { LockIn, OpenInNewIcon } from '../../../assets/img';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { StyledRow } from '../AlaCarteBenefits/styles';
import { formatAmount } from '../../../utils/common';
import BenefitSubCategoryDetails from './BenefitSubCategoryDetails';

const BenefitDetailsModal: React.FunctionComponent<IBenefitDetailsModal> = ({ isVisible, setIsVisible, benefit }) => {
    const openInNewWindow = () => {
        window.open(benefit?.descriptionUrl, '_blank');
    };
    return benefit ? (
        <Modal isVisible={isVisible} setIsVisible={setIsVisible} disablePadding={true}>
            <WithThemeProvider>
                <StyledContainer>
                    <StyledModalHeader>
                        <StyledModalHeaderTitle>
                            <CardIcon imageUrl={benefit.logo} />
                            <Typography variant="large" weight="bold">
                                {benefit.name}
                            </Typography>
                        </StyledModalHeaderTitle>
                        {benefit.lockIn && (
                            <StyledChip>
                                <Tooltip
                                    type="informative"
                                    beak="top-right"
                                    text={
                                        'This refers to a specific timeframe during which a policyholder ' +
                                        'cannot make significant changes to their coverage.'
                                    }
                                >
                                    <StyledRow>
                                        <ImageView src={LockIn} height="20px" width="20px" />
                                        <Typography variant={'extraSmall'} weight={'semiBold'}>
                                            {benefit.lockIn}
                                        </Typography>
                                    </StyledRow>
                                </Tooltip>
                            </StyledChip>
                        )}
                    </StyledModalHeader>
                    {benefit.descriptionUrl ? (
                        <StyledIframeContainer>
                            <StyledIframe
                                title="benefit-pdf"
                                width="100%"
                                height="600"
                                src={`${benefit.descriptionUrl}#toolbar=0&navpanes=0&view=fitH`}
                            />
                            <StyledOpenIcon src={OpenInNewIcon} onClick={openInNewWindow} />
                        </StyledIframeContainer>
                    ) : (
                        <>
                            <StyledContentContainer>
                                {benefit?.subCategories.length > 0 ? (
                                    <>
                                        <Typography variant="large" weight="semiBold">
                                            {'Available Plan Options:'}
                                        </Typography>
                                        {benefit?.subCategories?.map((item, index) => (
                                            <BenefitSubCategoryDetails isCard benefit={item} key={index} />
                                        ))}
                                    </>
                                ) : (
                                    <BenefitSubCategoryDetails benefit={benefit} />
                                )}
                            </StyledContentContainer>
                            <StyledFooter>
                                <Typography variant="small" weight="regular">
                                    {'Premium Starts From'}
                                </Typography>
                                <Typography variant="title2" weight="medium">
                                    {formatAmount(benefit.price)}
                                    <Typography variant={'extraSmall'} weight="medium">
                                        {'/yr'}
                                    </Typography>
                                </Typography>
                            </StyledFooter>
                        </>
                    )}
                </StyledContainer>
            </WithThemeProvider>
        </Modal>
    ) : null;
};

export default BenefitDetailsModal;
