import React from 'react';
import { StyledDescriptionContainer, StyledTitle } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { formatAmount } from '../../../utils/common';
import { IBenefitCategoryProps } from './type';

const BenefitSubCategoryDetails: React.FunctionComponent<IBenefitCategoryProps> = ({ benefit, isCard }) => {
    return (
        <StyledDescriptionContainer isCard={isCard}>
            <Typography variant="extraSmall" weight="semiBold">
                {benefit.name}
                {!!benefit.sumInsured && (
                    <Typography variant="extraSmall">
                        {` for ${formatAmount(benefit.sumInsured)} of Cover`}
                    </Typography>
                )}
            </Typography>
            <Typography variant="extraSmall">{benefit.description}</Typography>
            {benefit.familyDefinition && (
                <Typography variant="extraSmall">
                    {'Benefit Covers: '}
                    <Typography variant="extraSmall" weight="semiBold">
                        {benefit.familyDefinition}
                    </Typography>
                </Typography>
            )}
            {benefit.providerName && (
                <Typography variant="extraSmall">
                    {'Provider: '}
                    <Typography variant="extraSmall" weight="semiBold">
                        {benefit.providerName}
                    </Typography>
                </Typography>
            )}
            {benefit.offerings && (
                <>
                    <StyledTitle>{'Benefits Summary:'}</StyledTitle>
                    <Typography variant="extraSmall">{benefit.offerings}</Typography>
                </>
            )}
            {benefit.termsAndConditions && (
                <>
                    <StyledTitle>{'Terms and Conditions:'}</StyledTitle>
                    <Typography variant="extraSmall">
                        {benefit.termsAndConditions}
                    </Typography>
                </>
            )}
            {benefit.price && (
                <>
                    <StyledTitle>{'Premium'}</StyledTitle>
                    <Typography variant="medium">
                        {formatAmount(benefit.price)}
                        <Typography variant="extraSmall">{'/yr'}</Typography>
                    </Typography>
                </>
            )}
        </StyledDescriptionContainer>
    );
};

export default BenefitSubCategoryDetails;
