import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
    StyledNoPolicyText,
    StyledContainer,
    StyledInsuranceContainer,
    StyledEnrolmentCardsContainer,
    StyleAlertWarning
} from './styles';
import { ReduxState } from '../../../../../redux';
import {
    resetState as resetEnrolmentData,
    setCurrentlySelectedPolicy
} from '../../../../../redux/slices/EnrolmentSlice';
import { IPolicy } from '../../../../../redux/slices/PolicyListSlice/types';
import {
    insuranceCardDateFormat,
    getDaysLeft,
    getPolicyCardTrackObject,
    getSumInsured,
    getAvailableTopup,
    checkIfTopUpAvailable,
    formatAmount
} from '../../../../../utils/common';
import { ErrorContainer, Loader } from '../../../../atoms';
import { INTERNAL_ROUTES, InsuranceTypeConstants, MAIN_ROUTES } from '../../../../../utils/constants';
import useSegment from '../../../../../utils/hooks/useSegment';
import { fetchUserPolicyList, resetState as resetPolicyData } from '../../../../../redux/slices/PolicyListSlice';
import { fetchUserDependents } from '../../../../../redux/slices/PolicyListSlice/thunks';
import { resetOrderState } from '../../../../../topup-src/redux/slices/PolicyListSlice';
import {
    checkForShowSuperTopupCardCondition,
    checkTopUpAvailableCompany,
    getEnrolledPolicyCompany,
    getGMCPolicyCompany,
    getSuperTopUpPolicyCompany,
    showPolicyMsgDashboard
} from '../../../../../utils/PolicyServices';
import { BellIcon } from '../../../../../assets/img';
import { EnrolMembersCard } from '../../../../containers';
import {
    calculateAPAmount,
    filterDummyPolicies,
    filterOutStepathonPolicies,
    getFamilyDefinition,
    getNextPolicyToEnroll,
    showEnrolmentWarning,
    sortPolicies
} from './utils';
import { Alerts, Typography } from '@loophealth/loop-ui-web-library';
import { EnrolmentDateAlert } from './EnrolmentDateAlert';
import { LocationState } from '../../../../../topup-src/utils/types';
import EnrolSuccessModal from '../../../../containers/EnrolSuccessModal';
import DummyPolicySection from '../../../../containers/DummyPolicySection';
import { isDummyStepathonPolicyHidden } from '../../../../../utils/featureFlags';
import OnboardingScreenFtux from '../../../../containers/OnboardingScreenFtux';
import { selectFTUShownUserIds } from '../../../../../redux/slices/ApplicationStateSlice/selectors';
import { addFTUShownUserIds } from '../../../../../redux/slices/ApplicationStateSlice';
import DashboardHeader from '../../../../containers/Dashboard/DashboardHeader';
import { FirebaseAuth } from '../../../../../adapters/provider';
import { SEGMENT_ACTIONS } from '../../../../../utils/constants/SegmentActionConstants';
import { logoutUser as resetUserData } from '../../../LoginContainer/redux/slices/UserSlice';
import { resetModularState } from '../../../../../redux/slices/ModularEnrolmentSlice';
import useEnrolmentNavigation from './hooks/useEnrolmentNavigation';
import WarningModal from '../../../../containers/Dashboard/WarningModal';

const DashboardPage: React.FunctionComponent = () => {
    const trackClick = useSegment('click');
    const trackLogout = useSegment('identify');
    const history = useHistory();
    const location = useLocation<LocationState>();
    const dispatch = useDispatch();
    // const toast = Toast.useToast();
    const isLoading = useSelector((state: ReduxState) => state.policyList.policyList.loading);
    const user = useSelector((state: ReduxState) => state.user?.userData?.data);
    const error = useSelector((state: ReduxState) => state.policyList.policyList.error);
    const rawPolicyList: IPolicy[] = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const policyList: IPolicy[] = filterOutStepathonPolicies(rawPolicyList);
    const sortPolicyList = sortPolicies(policyList);
    const [dummyPolicyList, realPolicyList] = filterDummyPolicies(sortPolicyList);
    const nextPolicy = getNextPolicyToEnroll(realPolicyList);
    const enrolledDonePolicyList = realPolicyList.filter(
        (policy) => policy.enrolmentStatus?.toLowerCase() === 'enroled'
    );
    const policiesLeft = realPolicyList.length - enrolledDonePolicyList.length;
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId);
    const checkSuperTopUpAvailable = checkTopUpAvailableCompany();
    const superTopUpPolicy = getSuperTopUpPolicyCompany();
    const getGMCPolicy = getGMCPolicyCompany(policyList);
    const getEnrolledGMCPolicies = getEnrolledPolicyCompany(policyList);
    const superTopUpAvailable = checkIfTopUpAvailable(checkSuperTopUpAvailable, getGMCPolicy);
    const checkShowSuperTopUpCard = checkForShowSuperTopupCardCondition(policyList, superTopUpAvailable);
    const redirectToEnrolment = useEnrolmentNavigation();
    const [warningModal, setWarningModal] = React.useState(false);
    const [policyToReregister, setPolicyToReregister] = React.useState<IPolicy | null>(null);

    const isSuperTopupPurchased = !!policyList?.find(
        (pol) => pol.policyType === 'SUPER_TOP_UP' && pol.enrolmentStatus === 'ENROLED'
    );

    React.useEffect(() => {
        if (userId) {
            dispatch(fetchUserPolicyList({ userId }));
            dispatch(fetchUserDependents({ userId }));
            dispatch(resetOrderState());
        }
    }, [userId]);

    const handleCardClick = (policy: IPolicy) => {
        trackClick(getPolicyCardTrackObject(policy));
        if (policy?.policyType === 'SUPER_TOP_UP') {
            // TODO: Enrolment Status to be handled in backend. handling null for now
            if (checkSuperTopUpAvailable === 'NOT_SELECTED' || policy?.enrolmentStatus != 'ENROLED') {
                history.push(INTERNAL_ROUTES.topup, { previousUrl: location.pathname });
            }
            if (getEnrolledGMCPolicies.length) {
                const enroledPolicy = { ...{}, ...getEnrolledGMCPolicies[0] };
                enroledPolicy.enrolmentStatus = 'PENDING';
                dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: enroledPolicy }));
            }
            return;
        }
        if (!(getDaysLeft(policy.enrolmentDueDate) === 'OVERDUE')) {
            if (policy.policySecondaryType === 'MODULAR' && policy.enrolmentStatus === 'ENROLED') {
                setPolicyToReregister(policy);
                setWarningModal(true);
            } else redirectToEnrolment(policy);
        }
    };

    const onReregistrationConfirm = () => policyToReregister && redirectToEnrolment(policyToReregister);

    const totalSteps =
        realPolicyList?.length +
        1 +
        (checkShowSuperTopUpCard && superTopUpPolicy?.enrolmentStatus === 'ENROLED' ? 1 : 0);

    React.useEffect(() => {
        const policyTypeExists = location.state && location?.state?.policyType !== undefined;
        const policyName = InsuranceTypeConstants[location?.state?.policyType?.toUpperCase().trim() ?? 'GMC'];
        if (policyTypeExists && !isLoading) {
            setEnrolSuccessModal(true);
            window.plotline('track', 'CONFIRMATION', { policy: policyName });
            // toast?.success(`${policiesLeft + 1} more steps to go `, `Successfully registered in ${policyName}`);
            history.replace({
                ...location,
                state: {}
            });
        }
    }, [location, isLoading]);

    const [enrolSuccessModal, setEnrolSuccessModal] = React.useState<boolean>(false);

    const FtuShownUserIds = useSelector(selectFTUShownUserIds);
    const isFtuxShown = FtuShownUserIds.includes(userId);

    const handleFtuxClose = () => {
        dispatch(addFTUShownUserIds(userId));
    };

    const logout = () => {
        trackClick(SEGMENT_ACTIONS.CLICK.NAVIGATION_HEADER_SIGNOUT);
        FirebaseAuth.signOut();
        trackLogout({
            name: SEGMENT_ACTIONS.IDENTIFY.USER_LOGOUT.name
        });
        dispatch(resetEnrolmentData());
        dispatch(resetUserData());
        dispatch(resetPolicyData());
        dispatch(resetModularState());
        history.push(MAIN_ROUTES.login);
    };

    return !isFtuxShown ? (
        <OnboardingScreenFtux onClose={handleFtuxClose} />
    ) : (
        <>
            <DashboardHeader
                userName={`${user.firstName} ${user.lastName}`}
                total={realPolicyList.length}
                remaining={policiesLeft}
                onSignout={logout}
            />
            <StyledContainer>
                {isLoading ? (
                    <Loader />
                ) : error?.message.length ? (
                    <ErrorContainer message={error?.message || ''} />
                ) : (
                    <>
                        {isDummyStepathonPolicyHidden && dummyPolicyList.length > 0 && (
                            <DummyPolicySection
                                policyList={dummyPolicyList}
                                handleCardClick={handleCardClick}
                                isSuperTopupPurchased={isSuperTopupPurchased}
                                superTopUpPolicy={superTopUpPolicy}
                                checkShowSuperTopUpCard={checkShowSuperTopUpCard}
                                getEnrolledGMCPolicies={getEnrolledGMCPolicies}
                                checkSuperTopUpAvailable={checkSuperTopUpAvailable}
                                totalSteps={totalSteps}
                            />
                        )}

                        <Typography variant="title2" weight="medium">
                            Your insurance policies
                        </Typography>

                        {showEnrolmentWarning(realPolicyList) ? (
                            <StyleAlertWarning>
                                <Alerts.StatusAlert text={<EnrolmentDateAlert />} variant="over" iconSrc={BellIcon} />
                            </StyleAlertWarning>
                        ) : null}

                        <StyledEnrolmentCardsContainer>
                            {realPolicyList?.map((policy, index) => {
                                const isModular = policy.policySecondaryType === 'MODULAR';
                                return (
                                    <StyledInsuranceContainer key={`EnrolPending-${index}`}>
                                        <EnrolMembersCard
                                            policyId={policy.policyId}
                                            onClick={() => handleCardClick(policy)}
                                            insuranceCategory={policy?.policyType?.toUpperCase()}
                                            insuranceName={policy.policyName}
                                            policyImage={policy.policyImage}
                                            isEnrolPending={!(getDaysLeft(policy.enrolmentDueDate) === 'OVERDUE')}
                                            sumInsured={formatAmount(
                                                isModular && policy.minSumInsured
                                                    ? policy.minSumInsured
                                                    : policy.sumInsured
                                            )}
                                            dependentsInsured={getFamilyDefinition(policy)}
                                            enrolmentDueDate={
                                                policy?.enrolmentDueDate
                                                    ? insuranceCardDateFormat(policy.enrolmentDueDate)
                                                    : 'N/A'
                                            }
                                            policyEndDate={
                                                policy?.policyEndDate
                                                    ? insuranceCardDateFormat(policy?.policyEndDate?._seconds)
                                                    : 'N/A'
                                            }
                                            annualPremium={calculateAPAmount(
                                                superTopUpPolicy?.annualPremium ?? 0,
                                                superTopUpPolicy?.digitPremiumWithoutGST ?? 0,
                                                superTopUpPolicy?.gst ?? 0
                                            )}
                                            daysLeft={getDaysLeft(policy.enrolmentDueDate)}
                                            dependents={policy.dependents}
                                            availableTopUp={getAvailableTopup(policy.availableTopUp)}
                                            topUpAdded={getSumInsured(policy.topUpAdded, '')}
                                            superTopupStatus={checkSuperTopUpAvailable}
                                            SI={policy.sumInsured}
                                            enrolmentStatus={policy?.enrolmentStatus as string}
                                            currentStep={index + 1}
                                            addTopUpClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                                            isSuperTopupPurchased={isSuperTopupPurchased}
                                            isModular={isModular}
                                            benefits={policy.benefits}
                                            premiumToBeDeducted={formatAmount(
                                                policy?.benefits?.priceSummary?.salaryDeducted || 0
                                            )}
                                            familyDefinition={policy.benefits?.modular?.[0]?.familyDefinition}
                                        />
                                    </StyledInsuranceContainer>
                                );
                            })}
                            {checkShowSuperTopUpCard && superTopUpPolicy?.enrolmentStatus === 'ENROLED' ? (
                                <StyledInsuranceContainer key={`EnrolPending-${4}`}>
                                    <EnrolMembersCard
                                        policyId={superTopUpPolicy?.policyId}
                                        onClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                                        insuranceCategory={superTopUpPolicy?.policyType?.toUpperCase() ?? ''}
                                        insuranceName={superTopUpPolicy?.policyName ?? ''}
                                        policyImage={superTopUpPolicy?.policyImage ?? ''}
                                        isEnrolPending={
                                            !(getDaysLeft(superTopUpPolicy?.enrolmentDueDate ?? 0) === 'OVERDUE')
                                        }
                                        sumInsured={formatAmount(superTopUpPolicy?.sumInsured)}
                                        dependentsInsured={''}
                                        enrolmentDueDate={
                                            getEnrolledGMCPolicies[0]?.enrolmentDueDate
                                                ? insuranceCardDateFormat(getEnrolledGMCPolicies[0].enrolmentDueDate)
                                                : 'N/A'
                                        }
                                        policyEndDate={
                                            superTopUpPolicy?.policyEndDate
                                                ? insuranceCardDateFormat(
                                                    superTopUpPolicy?.policyEndDate?._seconds ?? 0
                                                )
                                                : 'N/A'
                                        }
                                        annualPremium={getEnrolledGMCPolicies[0]?.annualPremium}
                                        daysLeft={getDaysLeft(getEnrolledGMCPolicies[0]?.enrolmentDueDate ?? 0)}
                                        dependents={getEnrolledGMCPolicies[0]?.dependents ?? []}
                                        availableTopUp={getAvailableTopup(
                                            getEnrolledGMCPolicies[0]?.availableTopUp ?? 0
                                        )}
                                        topUpAdded={getSumInsured(getEnrolledGMCPolicies[0]?.topUpAdded ?? 0, '')}
                                        superTopupStatus={checkSuperTopUpAvailable}
                                        enrolmentStatus={superTopUpPolicy?.enrolmentStatus ?? 'PENDING'}
                                        currentStep={totalSteps - 1}
                                        insurerName={superTopUpPolicy.insurerName}
                                        addTopUpClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                                        isModular={superTopUpPolicy.policySecondaryType === 'MODULAR'}
                                    />
                                </StyledInsuranceContainer>
                            ) : null}
                        </StyledEnrolmentCardsContainer>

                        <StyledNoPolicyText>{showPolicyMsgDashboard(realPolicyList.length)}</StyledNoPolicyText>
                    </>
                )}
                <EnrolSuccessModal
                    isVisible={enrolSuccessModal}
                    setIsVisible={setEnrolSuccessModal}
                    enrolPolicy={nextPolicy}
                    policiesLeft={policiesLeft}
                />
                <WarningModal
                    isVisible={warningModal}
                    setIsVisible={setWarningModal}
                    onConfirm={onReregistrationConfirm}
                />
            </StyledContainer>
        </>
    );
};

export default DashboardPage;
