import { Alerts, CardIcon, LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowRightEmerald, EditIconGreen, PersonIcon, WalletIcon } from '../../../assets/img';
import { setModularCurrentStep } from '../../../redux/slices/ModularEnrolmentSlice';
import {
    selectModularFamily,
    selectModularPolicyId,
    selectModularSummary
} from '../../../redux/slices/ModularEnrolmentSlice/selectors';
import { fetchModularFamily, fetchModularSummary } from '../../../redux/slices/ModularEnrolmentSlice/thunks';
import { ModularEnrolmentSteps } from '../../../redux/slices/ModularEnrolmentSlice/types';
import { formatAmount } from '../../../utils/common';
import { Loader } from '../../atoms';
import CoveredMembersModal from '../CoveredMembersModal';
import ModularEnrolmentHeader from '../ModularEnrolmentHeader';
import { StyledModularHorizontalDivider } from '../ModularPlansContainer/styles';
import PolicyBenefitsModal from '../PolicyBenefitsModal';
import {
    StyledModularEnrolmentSummary,
    StyledModularEnrolmentSummaryCard,
    StyledModularEnrolmentSummaryCardContent,
    StyledModularSummaryColumn,
    StyledModularSummaryColumnRight,
    StyledModularSummaryHorizontalDivider,
    StyledModularSummaryHorizontalDottedDivider,
    StyledModularSummaryPlanCTA,
    StyledModularSummaryPlanCTASeperator,
    StyledModularSummaryPlanCTAWrapper,
    StyledModularSummaryPlanItem,
    StyledModularSummaryPlanItemInfo,
    StyledModularSummaryPlanText,
    StyledModularSummaryPlanWallet,
    StyledModularSummaryPremiumWrapper,
    StyledSelectedPlanHeader
} from './styles';
import { ReduxState } from '../../../redux';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';

const ModularEnrolmentSummary: React.FC = () => {
    const dispatch = useDispatch();
    const trackClick = useSegment('click');
    const trackPage = useSegment('page');
    const userId = useSelector((state: ReduxState) => state.user.userData.data?.userId || '');
    const policyId = useSelector(selectModularPolicyId);
    const summaryData = useSelector(selectModularSummary);
    const family = useSelector(selectModularFamily);
    const isFamilyLoading = family.loading;

    const [viewPlanDetails, setViewPlanDetails] = React.useState(false);
    const [viewCoveredMembers, setViewCoveredMembers] = React.useState(false);

    const summary = summaryData.data;
    const isLoading = summaryData.loading;
    const errorMessage = summaryData.error?.message;
    const toUseWallet = summary?.priceSummary.walletDeducted ? summary.priceSummary.walletDeducted > 0 : false;

    const setCurrentStep = (step: ModularEnrolmentSteps) => {
        dispatch(setModularCurrentStep(step));
    };
    const moveToBasePlanScreen = () => {
        setCurrentStep(ModularEnrolmentSteps.BASE_PLAN);
    };
    const moveToBenefitsScreen = () => {
        setCurrentStep(ModularEnrolmentSteps.HEALTHCARE_BENEFITS);
    };

    const handleViewCoveredMembers = () => {
        const clickAction = SEGMENT_ACTIONS.CLICK.VIEW_COVERED_MEMBERS;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                policy_id: policyId,
                plan_name: summary?.modular?.name
            }
        });
        dispatch(fetchModularFamily({ userId, policyId }));
        setViewCoveredMembers(true);
    };

    const handleViewDetails=()=>{
        const clickAction = SEGMENT_ACTIONS.CLICK.VIEW_DETAILS;
        trackClick({
            name: clickAction.name,
            properties: {
                ...clickAction.properties,
                policy_id: policyId,
                plan_name: summary?.modular?.name
            }
        });
        setViewPlanDetails(true);
    };


    useEffect(() => {
        dispatch(fetchModularSummary({ userId, policyId }));
        trackPage(SEGMENT_ACTIONS.PAGE.PURCHASE_SUMMARY_PAGE);
    }, []);

    return (
        <StyledModularEnrolmentSummary>
            <ModularEnrolmentHeader title="Purchase summary" subtitle="Review and confirm your plan selections" />
            <StyledModularEnrolmentSummaryCard>
                {isLoading ? (
                    <Loader />
                ) : errorMessage ? (
                    <Alerts.StatusAlert
                        variant="over"
                        text={
                            <Typography variant="medium" color="error">
                                {errorMessage}
                            </Typography>
                        }
                        alignItems="center"
                    />
                ) : summary ? (
                    <StyledModularEnrolmentSummaryCardContent>
                        <StyledSelectedPlanHeader>
                            <Typography variant="small" color="secondary">
                                Selected Base Policy
                            </Typography>
                            <LoopButton
                                variant="text"
                                size="small"
                                onClick={moveToBasePlanScreen}
                                iconSrc={EditIconGreen}
                            >
                                Change Plan
                            </LoopButton>
                        </StyledSelectedPlanHeader>
                        <StyledModularSummaryPlanItem>
                            <StyledModularSummaryPlanItemInfo>
                                <CardIcon imageUrl={summary.modular.logo} />
                                <StyledModularSummaryPlanCTAWrapper>
                                    <StyledModularSummaryPlanText>
                                        <Typography variant="small" weight="medium">
                                            {summary.modular.name}
                                        </Typography>
                                        <Typography variant="small" color="secondary">
                                            Sum insured {formatAmount(summary.modular.sumInsured)}
                                        </Typography>
                                    </StyledModularSummaryPlanText>
                                    <StyledModularSummaryPlanCTA>
                                        <LoopButton
                                            variant="text"
                                            size="small"
                                            onClick={handleViewCoveredMembers}
                                            iconSrc={PersonIcon}
                                        >
                                            View Covered Members
                                        </LoopButton>
                                        <StyledModularSummaryPlanCTASeperator />
                                        <LoopButton
                                            variant="text"
                                            size="small"
                                            onClick={handleViewDetails}
                                            iconSrc={ArrowRightEmerald}
                                            iconOrder="right"
                                        >
                                            View Details
                                        </LoopButton>
                                    </StyledModularSummaryPlanCTA>
                                </StyledModularSummaryPlanCTAWrapper>
                            </StyledModularSummaryPlanItemInfo>
                            <Typography variant="medium" weight="semiBold">
                                {formatAmount(summary.modular.price)}
                            </Typography>
                        </StyledModularSummaryPlanItem>
                        {summary.alacarte.length ? (
                            <>
                                <StyledModularSummaryHorizontalDivider />
                                <StyledSelectedPlanHeader>
                                    <Typography variant="small" color="secondary">
                                        Selected Benefits
                                    </Typography>
                                    <LoopButton
                                        variant="text"
                                        size="small"
                                        onClick={moveToBenefitsScreen}
                                        iconSrc={EditIconGreen}
                                    >
                                        Change Benefits Selection
                                    </LoopButton>
                                </StyledSelectedPlanHeader>

                                {summary.alacarte.map((m) => (
                                    <StyledModularSummaryPlanItem key={m.id}>
                                        <StyledModularSummaryPlanItemInfo>
                                            <CardIcon imageUrl={m.logo} />
                                            <StyledModularSummaryColumn>
                                                <Typography variant="small" weight="medium">
                                                    {m.name}
                                                </Typography>
                                                {m.subCategories.length ? (
                                                    <Typography variant="small" color="secondary">
                                                        {m.subCategories[0].name}
                                                    </Typography>
                                                ) : null}
                                            </StyledModularSummaryColumn>
                                        </StyledModularSummaryPlanItemInfo>
                                        <Typography variant="medium" weight="semiBold">
                                            {formatAmount(m.price)}
                                        </Typography>
                                    </StyledModularSummaryPlanItem>
                                ))}
                            </>
                        ) : null}

                        <StyledModularSummaryHorizontalDottedDivider />
                        <StyledModularSummaryPremiumWrapper>
                            <StyledModularSummaryPlanItem>
                                <Typography variant="small" weight="medium">
                                    Total Premium Amount
                                </Typography>
                                <Typography variant="medium" weight="semiBold">
                                    {formatAmount(summary.priceSummary.totalPremium)}
                                </Typography>
                            </StyledModularSummaryPlanItem>
                            <StyledModularSummaryPlanItem>
                                <Typography variant="small" weight="medium">
                                    GST
                                </Typography>
                                <Typography variant="medium" weight="semiBold">
                                    {formatAmount(summary.priceSummary.gst)}
                                </Typography>
                            </StyledModularSummaryPlanItem>
                            {toUseWallet && (
                                <StyledModularSummaryPlanItem>
                                    <StyledModularSummaryPlanWallet>
                                        <Typography variant="small" weight="medium">
                                            Wallet Balance Used
                                        </Typography>
                                        <img src={WalletIcon} alt="wallet" />
                                    </StyledModularSummaryPlanWallet>
                                    <Typography variant="medium" weight="semiBold" color="error">
                                        - {formatAmount(summary.priceSummary.walletDeducted)}
                                    </Typography>
                                </StyledModularSummaryPlanItem>
                            )}
                        </StyledModularSummaryPremiumWrapper>
                        <StyledModularHorizontalDivider />
                        <StyledModularSummaryPlanItem>
                            <StyledModularSummaryColumn>
                                <Typography variant="title2" weight="medium">
                                    To Pay
                                </Typography>
                                <Typography variant="small" color="secondary">
                                    (including tax)
                                </Typography>
                            </StyledModularSummaryColumn>
                            <StyledModularSummaryColumnRight>
                                <Typography variant="title2" weight="medium">
                                    {formatAmount(summary.priceSummary.salaryDeducted)}
                                </Typography>
                                {summary.priceSummary.salaryDeducted ? (
                                    <Typography variant="small" color="secondary">
                                        Amount will be deducted from your salary
                                    </Typography>
                                ) : null}
                            </StyledModularSummaryColumnRight>
                        </StyledModularSummaryPlanItem>
                        <PolicyBenefitsModal
                            isVisible={viewPlanDetails}
                            setIsVisible={setViewPlanDetails}
                            plan={summary.modular}
                        />
                        <CoveredMembersModal
                            isVisible={viewCoveredMembers}
                            setIsVisible={setViewCoveredMembers}
                            members={family.data || []}
                            title="Covered Members"
                            subtitle="Members covered in base policy"
                            isLoading={isFamilyLoading}
                        />
                    </StyledModularEnrolmentSummaryCardContent>
                ) : null}
            </StyledModularEnrolmentSummaryCard>
        </StyledModularEnrolmentSummary>
    );
};

export default ModularEnrolmentSummary;
