import {
    CardDecorGraphicFour,
    CardDecorGraphicOne,
    CardDecorGraphicThree,
    CardDecorGraphicTwo,
    ThresholdGPA,
    ThresholdGTL
} from '../../assets/img';

export const StepperStepTitles = ['Choose Base Plan', 'Add Family Members', 'Choose Add-Ons', 'Confirm Your Purchase'];

export const ThresholdData = {
    GTL: {
        title: 'Safeguard your family’s future with the term life policy',
        image: ThresholdGTL
    },
    GPA: {
        title: 'Stay covered against unforeseen accidents with the personal accident policy',
        image: ThresholdGPA
    }
};

export const BasePlanCardIllustrations = [
    CardDecorGraphicOne,
    CardDecorGraphicTwo,
    CardDecorGraphicThree,
    CardDecorGraphicFour
];

export const FamilyMemberSortingPreference = {
    'self': 0,
    'spouse': 1,
    'child': 2,
    'parent': 3,
    'parent-in-law': 4
};
